import { config } from "../constants/constant";
import axiosInstance from "../constants/globalaxiosinterceptor";
export const dropdownAPI = config.apiUrl + "/api/Dropdown";

export async function GetUserDetailsOffices(){
    return await axiosInstance.get(dropdownAPI+'/alloffices_userdetails', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetTeamDetailsOffices(){
    return await axiosInstance.get(dropdownAPI+'/alloffices_teamdetails', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetCapacityMappingOffices(){
    return await axiosInstance.get(dropdownAPI+'/capacitymappingsoffices', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllTeamTypes(){
    return await axiosInstance.get(dropdownAPI+'/allteamtypes', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllWorkFlows(){
    return await axiosInstance.get(dropdownAPI+'/allworkflows', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllSupportTypes(){
    return await axiosInstance.get(dropdownAPI+'/allsupportypes', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllRoles(){
    return await axiosInstance.get(dropdownAPI+'/allroles', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllTeams(){
    return await axiosInstance.get(dropdownAPI+'/allteams', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllCapacityStatues(){
    return await axiosInstance.get(dropdownAPI+'/allcapacitystatus', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllEventTypes(){
    return await axiosInstance.get(dropdownAPI+'/alleventtype', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllEventSubTypes(){
    return await axiosInstance.get(dropdownAPI+'/alleventsubtype', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllCountries(){
    return await axiosInstance.get(dropdownAPI+'/allcountries', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllScenarios(){
    return await axiosInstance.get(dropdownAPI+'/allscenarios', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllResponseEvents(){
    return await axiosInstance.get(dropdownAPI+'/responseevents', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllDeploymentTypes(){
    return await axiosInstance.get(dropdownAPI+'/deploymenttypes', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllRequestedTeams(){
    return await axiosInstance.get(dropdownAPI+'/requestedteams', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllRequestStatus(){
    return await axiosInstance.get(dropdownAPI+'/deploymentrequeststatus', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllPositionStatuses(){
    return await axiosInstance.get(dropdownAPI+'/positionstatuses', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

let dropdownServiceConfig = {
    headers: {
        'Content-Type': 'application/json',
    }
}

export async function GetAllPositions(isAdmin){
    dropdownServiceConfig.headers.isAdmin = isAdmin;
    return await axiosInstance.get(dropdownAPI+'/positions', {headers:dropdownServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllPositionLevel(){
    return await axiosInstance.get(dropdownAPI+'/positionslevel', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllPositionTypes(){
    return await axiosInstance.get(dropdownAPI+'/positionstypes', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllResponsibilities(){
    return await axiosInstance.get(dropdownAPI+'/responsibilities', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetPostedPostionsDropdownValues() {
    return await axiosInstance.get(dropdownAPI+'/postedPositionsDropdownQuestion', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllDeploymentRequests() {
    return await axiosInstance.get(dropdownAPI+'/alldeploymentrequests', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetSbpPartnerSupportStatus(){
    return await axiosInstance.get(dropdownAPI+'/sbppartnersstatuses', {headers:config.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}