const GroupCheckboxList = ({ filteredGroups, selectedUserRoles, setSelectedUserRoles }) => {
    const handleGroupSelectAll = (isChecked, groupRoles) => {
        const roleIds = groupRoles.map((role) => role.roleId);
        if (isChecked) {
            setSelectedUserRoles([...new Set([...selectedUserRoles, ...roleIds])]);
        } else {
            setSelectedUserRoles(selectedUserRoles.filter((id) => !roleIds.includes(id)));
        }
    };

    const handleRoleChange = (roleId, isChecked) => {
        if (isChecked) {
            setSelectedUserRoles([...selectedUserRoles, roleId]);
        } else {
            setSelectedUserRoles(selectedUserRoles.filter((id) => id !== roleId));
        }
    };

    return (
        <>
            {filteredGroups.map((group) => {
                const allSelectedInGroup = group.roles.every((role) =>
                    selectedUserRoles.includes(role.roleId)
                );

                return (
                    <div key={group.groupName} className="form-outline">
                        <fieldset>
                            {/* Group Header with Select All */}
                            <div className="group-header">
                                <h6>{group.groupName}  <span style={{fontWeight:'normal'}}><input
                                        className="me-2 md-2"
                                        type="checkbox"
                                        id={`select-all-${group.groupName}`}
                                        checked={allSelectedInGroup}
                                        onChange={(e) =>
                                            handleGroupSelectAll(e.target.checked, group.roles)
                                        }
                                    />
                                    <label htmlFor={`select-all-${group.groupName}`}>
                                        Select All in {group.groupName}
                                    </label></span>
                                </h6>                                    
                                <hr />
                            </div>

                            {/* Individual Role Checkboxes */}
                            {group.roles.map((role) => (
                                <div className="checkbox-item" key={role.roleId}>
                                    <input
                                        className="me-2 md-2"
                                        type="checkbox"
                                        id={`checkbox-${role.roleId}`}
                                        value={role.roleId}
                                        checked={selectedUserRoles.includes(role.roleId)}
                                        onChange={(e) =>
                                            handleRoleChange(role.roleId, e.target.checked)
                                        }
                                    />
                                    <label
                                        className="me-2"
                                        htmlFor={`checkbox-${role.roleId}`}
                                    >
                                        {role.roleName}
                                    </label>
                                </div>
                            ))}
                        </fieldset>
                    </div>
                );
            })}
        </>
    );
};

export default GroupCheckboxList;
