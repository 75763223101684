import React, { useState, useEffect  } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { GetRequestDetailsById, GetAllHistories, GetAllRemarks, AddRemarkDetails,DownloadUploadedDocument, UpdateCCUsersDetails, updatePositionActualFromToDates, deleteCeilingRecord } from '../../services/deploymentrequestServices.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GetAllPositionStatuses, GetAllResponseEvents, GetPostedPostionsDropdownValues } from '../../services/dropdownServices.js';
import { ReviewESFMRequest, ApproveESFMRequest, PostRequestOnDeploymentRoster, GetDeployeeDetailsByEmail, PostDeployeeUserDetails, UpdateWBSAndCieling, submitFinalReport } from '../../services/deploymentrequestServices.js';
import { GetAllExpensesPositions, GetAllExpensesWbs, GetAllExpensesType, saveExpenseRecord, AllExpensessById, deleteExpenseRecord, submitExpenseRecord, approveExpenseRecord, revertExpensesRecord } from '../../services/deploymentrequestServices.js';
import { useModal } from '../modalPopup/modelcontext.js';
import { getToken, userRoles } from '../../services/loginService.js';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetAllWBSForDropdown } from '../../services/adminServices.js';
import html2pdf from 'html2pdf.js';

const ViewDeploymentRequest = () => {
    const {id} = useParams();
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
    const navigate = useNavigate();
    const [isESFM, setIsESFM] = useState(false);
    const [sections, setSections] = useState([createEmptySection()]);
    const [requestCode,setRequestCode] = useState('');
    const [requestStatus, setRequestStatus] = useState(null);
    const [deploymentRequestId, setDeploymentRequestId] = useState(0);
    const [selectedResponseEvent, setSelectedResponseEvent] = useState('');
    const [ccUsersMail, setCcUsersMail] = useState(null);
    const [isCcMailEditing, setIsCcMailEditing] = useState(false);
    const [deploymentType, setDeploymentType] = useState(null);
    const [supportingTeam, setSupportingTeam] = useState('');
    const [requestCost, setRequestCost] = useState(null);
    const [allPositionStatus, setAllPositionStatus] = useState([]);
    const [responseEvents, setResponseEvents] = useState([]);
    const [approveButtonEnable, setApproveButtonEnable] = useState(false);
    const { showModal } = useModal();
    const [errors, setErrors] = useState({
        selectedResponseEvent: '',
        // Initialize errors for dynamic sections
        0: {
            plannedFromDate: '',
            plannedToDate: '',
            approvedStatus: ''
        }
    });
    const [show, setShow] = useState(false);
    const [showRemarkModal, setShowRemarkModal] = useState(false);
    const [remarkPopupText, setRemarkPopupText] = useState('');
    const [remarkError, setRemarkError] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [reason, setReason] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);
    const [historyData, setHistoryData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [showRemarks, setShowRemarks] = useState(false);
    const [showDeatils, setShowDetails] = useState(true);
    const [showExpenses, setShowExpenses] = useState(false);
    const [showFinalReport, setShowFinalReport] = useState(false);
    const [allPostedPositionForDR, setAllPostedPositionForDR] = useState([]);
    const [postDeploymentRemainingFields, setPostDeploymentRemainingFields] = useState(false);
    const [editableSections, setEditableSections] = useState([]);
    const [ccUserEmailIdError, setCcUserEmailIdError] = useState('');
    const [allWbs, setAllWbs] = useState([]);
    const [hideSection, setHideSection] = useState(false);  
    const [isActualDatesEditing, setIsActualDatesEditing] = useState(false);

    const toggleIcon = (index) => {
        setSections(prevSections =>
            prevSections.map((section, i) =>
                i === index ? { ...section, isCollapsed: !section.isCollapsed } : section
            )
        );
    };

    const fetchRequestDetailsById = async (id) => {
        try {
            const response = await GetRequestDetailsById(id); 
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.error('Error while fetching requests details:', error);
            return [];
        }
    };

    const fetchResponseEvents = async() => {
        try{
            GetAllResponseEvents().then((response) =>{
                if(response.status === 200){
                    setResponseEvents(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching response event dropdownlist details:', error);
        }
    };

    const fetchPostedPostionsDropdownValues = async() => {
        try{
            GetPostedPostionsDropdownValues().then((response) =>{
                if(response.status === 200){
                    setAllPostedPositionForDR(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching posted positions values:', error);
        }
    };

    const fetchAllWbs = async () => {
        try{
            GetAllWBSForDropdown().then((response) =>{
                if(response.status === 200){
                    setAllWbs(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching wbs dropdownlist details:', error);
        }
    };

    const fetchPositionStatuses = () => {
        try{
            GetAllPositionStatuses().then((response) => {
                if(response.status === 200){
                    setAllPositionStatus(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positionStatus detials:', error);
        }
    }

    const fetchHistoryData = async (deploymentRequestId) => {
        try{
            GetAllHistories(deploymentRequestId).then((response) =>{
                if(response.status === 200){
                    setHistoryData(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching History details:', error);
        }
    };
  
    const fetchRemarksData = async (deploymentRequestId) => {
        try{
            GetAllRemarks(deploymentRequestId).then((response) =>{
                if(response.status === 200){
                    setRemarksData(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching remarks details:', error);
        }
    };
    
    const fetchExpensesPositions = async (id) => {
        try{
            GetAllExpensesPositions(id).then((response) =>{
                if(response.status === 200){
                    setAllExpensesPositions(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching expense positions:', error);
        }
    };

    const fetchExpensesWbs = async (id) => {
        try{
            GetAllExpensesWbs(id).then((response) =>{
                if(response.status === 200){
                    setAllExpensesWbs(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching Expense Wbs:', error);
        }
    };

    const fetchExpensesType = async () => {
        try{
            GetAllExpensesType().then((response) =>{
                if(response.status === 200){
                    setAllExpensesType(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching wbs dropdownlist details:', error);
        }
    };

    const fetchAllExpensess = async (id) => {
        try{
            AllExpensessById(id).then((response) =>{
                if(response.status === 200){
                    const fetchedData = response.data.map(expense => ({
                        fundingExpenseId: expense.fundingExpenseId,
                        position: expense.positionDescription,
                        positionId: expense.positionId, 
                        expenseType: expense.expenseType,
                        expenseTypeId: expense.expenseTypeId, 
                        wbs: expense.wbs,
                        wbsId: expense.wbsId, 
                        description: expense.description,
                        amount: parseFloat(expense.amount),
                        status: expense.expenseStatus
                    }));
                    setExpensesData(fetchedData);
                    const allDraft = fetchedData.every(expense => expense.status === 'Draft');
                    const allSubmitted = fetchedData.every(expense => expense.status === 'Submitted');
                    const allApproved = fetchedData.every(expense => expense.status === 'Approved');
                    if (allDraft) setExpenseRecordStatus('Draft');
                    else if (allSubmitted) setExpenseRecordStatus('Submitted');
                    else if (allApproved) setExpenseRecordStatus('Approved');
                    else setExpenseRecordStatus('Mixed');
                }
            });
        }catch(error){
            console.error('Error while fetching Expensess:', error);
        }
    };

    useEffect(() => { 
        fetchResponseEvents();
        fetchPositionStatuses();
        fetchAllWbs();
        fetchExpensesType();
        fetchPostedPostionsDropdownValues();
        if (id !== undefined) {
            fetchDeploymentRequestData(parseInt(id));  
            fetchExpensesPositions(parseInt(id));
            fetchExpensesWbs(parseInt(id)); 
            fetchAllExpensess(parseInt(id));
        }          
    }, [id]); 

    useEffect(() => {
        sections.forEach(section => {
            if (section.postedPositionOnDeploymentRoster) {
                setPostDeploymentRemainingFields(parseInt(section.postedPositionOnDeploymentRoster) === yesValue);
            }
        });
        checkAllPositionsApproved();
    }, [sections]);

    const checkAllPositionsApproved = () => {
        if (sections.length === 0) {
            setApproveButtonEnable(false);
            return;
        } else if(sections.length === 1) {
            const anyApproved = sections.every(section => parseInt(section.approvedStatus) === approveStatusId);
            setApproveButtonEnable(anyApproved);
        } else {
            const anyApproved = sections.some(section => parseInt(section.approvedStatus) === approveStatusId || parseInt(section.approvedStatus) === rejectStatusId);
            setApproveButtonEnable(anyApproved);
        }        
    };

    const fetchDeploymentRequestData = async (id) => {
        try {
              const data = await fetchRequestDetailsById(id);
                setDeploymentRequestId(data.deploymentRequestId);
                setSelectedResponseEvent(data.responseId);
                setRequestCode(data.requestCode);
                setDeploymentType(data.deploymentTypeName);
                setSupportingTeam(data.requestedTeam);
                setRequestCost(data.estimatedCost);
                setRequestStatus(data.status);
                setCcUsersMail(data.ccUserMailIds);
                setRows(data.wbsAndCielings);
                setReportAchievements(data.achievements);
                setReportChallenges(data.challenges);
                setReportRecommendations(data.recommendations);
                const mappedSections = mapSectionsFromData(data.requestedPositions);
                setSections(mappedSections);
                setIsESFM(data.deploymentTypeName==='ESFM' ? true : false);
        } catch (error) {
            console.error('Error while fetching deployment request data:', error);
        }
    };

    const mapSectionsFromData = (sectionsData) => {
        return sectionsData.map(section => ({
            deploymentRequestId:section.deploymentRequestId,
            deploymentRequestPositionId: section.deploymentRequestPositionId,
            selectedPosition: section.positionHeader,
            dutyStation: section.dutyStation,
            postionHeader : section.positionHeader,
            selectedPositionLevel: section.selectedPositionLevel,
            fromDate: section.targetFromDate === '01/01/1970' ? null : section.targetFromDate,
            toDate: section.targetToDate === '01/01/1970' ? null : section.targetToDate,
            selectedPositionType: section.selectedPositionType,
            supervisorName: section.supervisorName,
            supervisorPosition: section.supervisorPositionTitle,
            supervisorEmail: section.supervisorEmail,
            termsofReference: section.torText,
            torDocumentPath: section.torDocumentPath, 
            requestJustification: section.requestJustification,
            responsibilitiesText: section.responsibilitiesText, 
            estimatedCost: section.estimatedCost,
            supportIdentifyProfile: section.supportIdentifyProfile,
            uploadedDocumentName : section.uploadedDocumentName,
            torDocumentName: section.torDocumentName,
            plannedFromDate: section.plannedFromDate === '01/01/1970' ? null : section.plannedFromDate,
            plannedToDate:section.plannedToDate === '01/01/1970' ? null : section.plannedToDate,
            approvedStatus:section.positionStatusId,
            capacityMappingId:section.capacityMappingId,
            positionStatus: section.positionStatus,
            postedPositionOnDeploymentRoster: section.postedPositionOnDeploymentRoster,
            opportunityReferenceNumber: section.positionReferenceNumber,
            postedDate: section.postedDate,
            postedPositionOnDeploymentRosterView: section.postedPositionOnDeploymentRosterText,
            deployeeFName: section.firstName,
            deployeeLName: section.lastName,
            deployeeEmailId: section.deployeeEmail,
            actualStartDate: section.actualStartDate === '01/01/1970' ? null : section.actualStartDate,
            actualEndDate:section.actualEndDate === '01/01/1970' ? null : section.actualEndDate,
        }));
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const defaultPendingValue = allPostedPositionForDR.find(item => item.postedPositionDescription === 'Pending')?.postedPositionId;
    const yesValue = allPostedPositionForDR.find(item => item.postedPositionDescription === 'Yes')?.postedPositionId;
    function createEmptySection(defaultPendingValue = '') {
        return {
              deploymentRequestId: '',
              deploymentRequestPositionId: '',
              selectedPosition: '',
              postionHeader : '',
              dutyStation: '',
              selectedPositionLevel: '',
              fromDate: null,
              toDate: null,
              selectedPositionType:'',
              supervisorName: '',
              supervisorPosition: '',
              supervisorEmail: '',
              termsofReference: '',
              requestJustification: '',
              responsibilitiesText: '',
              estimatedCost: '',
              supportIdentifyProfile: '',
              uploadedDocumentName: '',
              torDocumentPath: '',
              torDocumentName: '',
              plannedFromDate: '',
              plannedToDate: '',
              approvedStatus: '',
              capacityMappingId: '',
              positionStatus: '',
              postedPositionOnDeploymentRoster: defaultPendingValue,
              opportunityReferenceNumber: '',
              postedPositionOnDeploymentRosterView: '',
              postedDate: '',
              deployeeFName: '',
              deployeeLName: '',
              deployeeEmailId:'',
              actualStartDate: null,
              actualEndDate: null
        };
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        setSelectedResponseEvent(value);
    };

    const approveStatusId = allPositionStatus.find(item => item.statusDescription === 'Approved')?.positionStatusId;
    const rejectStatusId = allPositionStatus.find(item => item.statusDescription === 'Rejected')?.positionStatusId;
    const pendingStatusId = allPositionStatus.find(item => item.statusDescription === 'Pending')?.positionStatusId;
    const resubmitStatusId = allPositionStatus.find(item => item.statusDescription === 'Requested')?.positionStatusId;
    const postedPositionOnDrYes = allPostedPositionForDR.find(item => item.postedPositionDescription === 'Yes')?.postedPositionId;
    const postedPositionOnDrNotApplicable = allPostedPositionForDR.find(item => item.postedPositionDescription === 'Not Applicable')?.postedPositionId;

    const handleChange = (index, field, value) => {
        setSections((prevSections) => 
            prevSections.map((section, idx) => {
                if (idx === index) {
                    // Clear first and last name if the email field is being changed
                    if (field === 'deployeeEmailId') {
                        return {
                            ...section,
                            deployeeEmailId: value,
                            deployeeFName: '',  
                            deployeeLName: ''   
                        };
                    }
                    
                    // Other field-specific logic
                    if (field === 'postedPositionOnDeploymentRoster') {
                        if (parseInt(value) !== yesValue) {
                            resetErrors(index);
                            return {
                                ...section,
                                [field]: value,
                                opportunityReferenceNumber: '',
                                postedDate: null,
                                deployeeEmailId: '',
                                deployeeFName: '',
                                deployeeLName: ''
                            };
                        } else {
                            return {
                                ...section,
                                [field]: value,
                            };
                        }
                    }
    
                    // Date validation logic
                    if (field === 'plannedFromDate' || field === 'plannedToDate') {
                        const updatedSection = { ...section, [field]: value };
                        const plannedFromDate = updatedSection.plannedFromDate;
                        const plannedToDate = updatedSection.plannedToDate;
    
                        // If both dates are present, validate them
                        if (plannedFromDate && plannedToDate) {
                            if (new Date(plannedToDate) < new Date(plannedFromDate)) {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    [index]: {
                                        ...prevErrors[index],
                                        plannedToDate: 'Approved To Date should not be earlier than Approved From Date.',
                                    },
                                }));
                            } else {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    [index]: {
                                        ...prevErrors[index],
                                        plannedToDate: '',
                                    },
                                }));
                            }
                        }  
                        return updatedSection;
                    }
    
                    // Default case: update the field value
                    return { ...section, [field]: value };
                }
                return section;
            })
        );
    
        if (field === 'postedPositionOnDeploymentRoster') {
            setPostDeploymentRemainingFields(parseInt(value) === yesValue);
        }
        checkAllPositionsApproved();
    };    

    const handleBlur = async (index, field, value) => {
        if (field === 'deployeeEmailId' && value) {
            try {
                const result = await GetDeployeeDetailsByEmail(value);
                if (result.data) {
                    setSections((prevSections) => {
                        const updatedSections = [...prevSections];
                        updatedSections[index] = {
                            ...updatedSections[index],
                            deployeeFName: result.data.deployeeFirstName || '',
                            deployeeLName: result.data.deployeeLastName || '',
                        };
                        return updatedSections;
                    });
                } else {
                    console.error('Failed to fetch deployee details');
                }
            } catch (error) {
                console.error('Error fetching deployee details:', error);
            }
        }
    };

    const resetErrors = (index) => {
        const newErrors = { ...errors };
        if (newErrors[index]) {
            // Remove specific errors related to opportunityReferenceNumber and postedDate
            delete newErrors[index].opportunityReferenceNumber;
        }
        setErrors(newErrors);
    };

    const validateField = (field, value) => {
        switch (field) {
            case 'selectedResponseEvent':
                return value ? '' : 'Response Event is required';
            case 'plannedFromDate':
                return value ? '' : 'Approved From Date is required';
            case 'plannedToDate':
                return value ? '' : 'Approved To Date is required';
            case 'approvedStatus':
                return value ? '' : 'Approved Status is required';
            default:
                return '';
        }
    };

    const validatePostRequestonDeploymentRoster = (field, value, section) => {
        switch (field) {
            case 'selectedResponseEvent':
                return value ? '' : 'Response Event is required';
            case 'postedPositionOnDeploymentRoster':
                return value ? '' : 'Have you posted the position on Deployment Roster? Required';
            case 'opportunityReferenceNumber':
                return parseInt(section?.postedPositionOnDeploymentRoster) === 1 ? value ? '' : 'Opportunity Reference Number is required' : '';
            default:
                return '';
        }
    };
    
    const validateForUpdateDeployeeInformation = (field, value) => {
        switch (field) {
            case 'selectedResponseEvent':
                return value ? '' : 'Response Event is required';
            case 'deployeeEmailId':
                return value ? '' : 'Deployee EmailId is required';
            case 'deployeeFName':
                return value ? '' : 'Deployee FirstName is required';
            case 'deployeeLName':
                return value ? '' : 'Deployee LastName is required';
            default:
                return '';
        }
    };

    const handleValidation = (formName, sectionIndex = null) => {
        let valid = true;
        let newErrors = {};
    
        // Validate top-level fields
        const fields = {
            selectedResponseEvent
        };
    
        Object.keys(fields).forEach(field => {
            const error = validateField(field, fields[field]);
            if (error) {
                valid = false;
                newErrors[field] = error;
            }
        });
    
        if (isESFM) {
            if (sectionIndex !== null) {
                // Validate a specific section
                const section = sections[sectionIndex];
                const sectionErrors = {};
                Object.keys(section).forEach(field => {
                    let error = '';
                    if (formName === 'ForPostDeploymentRequest') {
                        error = validatePostRequestonDeploymentRoster(field, section[field], section);
                    } else if (formName === 'ForUpdateDeployeeInformation') {
                        error = validateForUpdateDeployeeInformation(field, section[field]);
                    }
                    if (error) {
                        valid = false;
                        sectionErrors[field] = error;
                    }
                });
                if (Object.keys(sectionErrors).length > 0) {
                    newErrors[sectionIndex] = sectionErrors;
                }
            } else {
                // Validate all sections (default behavior)
                sections.forEach((section, index) => {
                    const sectionErrors = {};
                    Object.keys(section).forEach(field => {
                        let error = '';
                        if (formName === '') {
                            error = validateField(field, section[field]);
                        }
                        if (error) {
                            valid = false;
                            if (!newErrors[index]) newErrors[index] = {};
                            newErrors[index][field] = error;
                        }
                    });
                    if (Object.keys(sectionErrors).length > 0) {
                        newErrors[index] = sectionErrors;
                    }
                });
            }
        }
    
        setErrors(newErrors);
        return valid;
    };    

    function createFormDataRequest(buttonText) {
        const formData = new FormData();
        formData.append('deploymentRequestId', deploymentRequestId);
        formData.append('responseId', parseInt(selectedResponseEvent, 10));
        formData.append('buttonText', buttonText);
        formData.append('remarks', reason);
        formData.append('deploymentTypeText', deploymentType);
        formData.append('requestedTeamName',supportingTeam);
        formData.append('requestCode', requestCode);

        sections.forEach((section, index) => {
            formData.append(`requestedPositions[${index}].deploymentRequestId`, parseInt(section?.deploymentRequestId ?? 0));
            formData.append(`requestedPositions[${index}].postionHeader`, section?.postionHeader);
            formData.append(`requestedPositions[${index}].dutyStation`, section?.dutyStation);
            formData.append(`requestedPositions[${index}].deploymentRequestPositionId`, parseInt(section?.deploymentRequestPositionId ?? 0));
            formData.append(`requestedPositions[${index}].capacityMappingId`, parseInt(section?.capacityMappingId ?? 0));
            formData.append(`requestedPositions[${index}].positionStatusId`,buttonText==='Reject' ? rejectStatusId : buttonText ==='Revert' ? pendingStatusId : buttonText ==='Resubmit'? resubmitStatusId : parseInt(section?.approvedStatus ?? 0));
            formData.append(`requestedPositions[${index}].plannedFromDate`, section?.plannedFromDate ? new Date(section.plannedFromDate).toISOString() : null);
            formData.append(`requestedPositions[${index}].plannedToDate`, section?.plannedToDate ? new Date(section.plannedToDate).toISOString() : null);
        });
        return formData;
    };

    function postPositionToDeploymentRoster() {
        const formData = new FormData();
        formData.append('deploymentRequestId', deploymentRequestId);
        formData.append('deploymentTypeText', deploymentType);
        formData.append('requestedTeamName',supportingTeam);
        formData.append('requestCode', requestCode);

        sections.forEach((section, index) => {
            formData.append(`requestedPositions[${index}].deploymentRequestId`, parseInt(section?.deploymentRequestId ?? 0));
            formData.append(`requestedPositions[${index}].postionHeader`, section?.postionHeader);
            formData.append(`requestedPositions[${index}].dutyStation`, section?.dutyStation);
            formData.append(`requestedPositions[${index}].deploymentRequestPositionId`, parseInt(section?.deploymentRequestPositionId ?? 0));
            formData.append(`requestedPositions[${index}].postedPositionOnDeploymentRoster`, parseInt(section?.postedPositionOnDeploymentRoster ?? 0));
            formData.append(`requestedPositions[${index}].opportunityReferenceNumber`, section?.opportunityReferenceNumber);
            formData.append(`requestedPositions[${index}].positionType`,section?.selectedPositionType);
            formData.append(`requestedPositions[${index}].approvedStartDate`, section?.plannedFromDate);
            formData.append(`requestedPositions[${index}].approvedEndDate`, section?.plannedToDate);
        });
        return formData;
    };

    function postDeployeeInformation() {
        const formData = new FormData();
        formData.append('deploymentRequestId', deploymentRequestId);
        formData.append('deploymentTypeText', deploymentType);
        formData.append('requestedTeamName',supportingTeam);
        formData.append('requestCode', requestCode);

        sections.forEach((section, index) => {
            formData.append(`requestedPositions[${index}].deploymentRequestId`, parseInt(section?.deploymentRequestId ?? 0));
            formData.append(`requestedPositions[${index}].postionHeader`, section?.postionHeader);
            formData.append(`requestedPositions[${index}].dutyStation`, section?.dutyStation);
            formData.append(`requestedPositions[${index}].deploymentRequestPositionId`, parseInt(section?.deploymentRequestPositionId ?? 0));
            formData.append(`requestedPositions[${index}].postedPositionOnDeploymentRoster`, parseInt(section?.postedPositionOnDeploymentRoster ?? 0));
            formData.append(`requestedPositions[${index}].opportunityReferenceNumber`, section?.opportunityReferenceNumber);
            formData.append(`requestedPositions[${index}].positionType`,section?.selectedPositionType);
            formData.append(`requestedPositions[${index}].approvedStartDate`, section?.plannedFromDate ? new Date(section.plannedFromDate).toISOString() : null);
            formData.append(`requestedPositions[${index}].approvedEndDate`, section?.plannedToDate ? new Date(section.plannedToDate).toISOString() : null);
            formData.append(`requestedPositions[${index}].deployeeEmailId`,section?.deployeeEmailId);
        });
        return formData;
    };

    const handleClose = () => {
        setShow(false);
        setReason('');
    };

    const openModal = (title, body, btnText) => {
        setModalTitle(title);
        setModalBody(body);
        setButtonText(btnText === 'Post Request on Deployment Roster' ? 'Save' : btnText === 'Update Deployee Information' ? 'Update' : btnText);
        setShow(true);
    };

    const handleModalConfirm = async () => {
        try {
            if(isESFM){
                if(reason==='' && (buttonText !== 'Approve' && buttonText !== "Submit for Approval" && buttonText !== "Save" && buttonText !== "Update")){
                    showModal('failure','Reason is required, Please provide a reason');
                    handleClose();
                    return;
                }
                if(buttonText === 'Submit for Approval'){
                    const reviewFormData = createFormDataRequest(buttonText);
                    ReviewESFMRequest(reviewFormData).then((res) => {
                        handleClose(); 
                        if (res.data.success) {
                            showModal('success', res.data.message);
                            fetchDeploymentRequestData(parseInt(deploymentRequestId));
                        } else {
                            showModal('failure', res.data.message);
                        }
                    });
                }
                else if(buttonText === 'Save'){
                    const postRequestData = postPositionToDeploymentRoster();
                    PostRequestOnDeploymentRoster(postRequestData).then((res) => {
                        handleClose(); 
                        if (res.data.success) {
                            if(res.data.message === 'The deployment roster opportunity posting and deployee information has been updated.'){
                                setHideSection(true);
                                showModal('success', res.data.message);
                                fetchDeploymentRequestData(parseInt(deploymentRequestId));
                            }else{
                                setHideSection(false);
                                showModal('success', res.data.message);
                                fetchDeploymentRequestData(parseInt(deploymentRequestId));
                            }
                        } else {
                            setHideSection(false);
                            showModal('failure', res.data.message);
                        }
                    });
                } else if(buttonText === 'Approve' || buttonText === 'Partial Approve' || buttonText === 'Reject' || buttonText === 'Resubmit' || buttonText === 'Revert') {
                    const formData = createFormDataRequest(buttonText);
                    ApproveESFMRequest(formData).then((res) => {
                        handleClose(); 
                        if (res.data.success) {
                            showModal('success', res.data.message);
                            fetchDeploymentRequestData(parseInt(deploymentRequestId));
                        } else {
                            showModal('failure', res.data.message);
                        }
                    });
                } else if (buttonText === 'Update'){
                    const deployeeInfoData = postDeployeeInformation();
                    PostDeployeeUserDetails(deployeeInfoData).then((res) => {
                        handleClose(); 
                        if (res.data.success) {
                            showModal('success', res.data.message);
                            fetchDeploymentRequestData(parseInt(deploymentRequestId));
                        } else {
                            showModal('failure', res.data.message);
                        }
                    });
                } else if (buttonText === 'Revert Expenses'){
                    handleClose();
                    await revertExpenseRecord();
                }
            }            
        } catch (error) {
            handleClose(); 
            showModal('failure', 'Request '+ buttonText +' failed with error ' + error);
        }
    };

    const handleSubmitForApprove = () =>{
        if (!handleValidation('')) {
            handleClose(); 
            return;
        }
        const allApprovedOrRejected = sections.every(section => parseInt(section.approvedStatus) === approveStatusId 
                || parseInt(section.approvedStatus) === rejectStatusId);
        if (!allApprovedOrRejected) {
        showModal('failure','All positions approval status must be either approved or rejected.');
        return;
        }
        openModal(
        'Confirm Submit for Approval',
        <>
        Are you sure you want to submit this Request for Approval?.
        <span style={{ color: 'red' }}>*</span>
        </>,
        'Submit for Approval'
        );
    }

    const handleApprove = () => {
        if (!handleValidation('')) {
                handleClose(); 
                return;
        }
        const allApprovedOrRejected = sections.every(section => parseInt(section.approvedStatus) === approveStatusId 
                                                 || parseInt(section.approvedStatus) === rejectStatusId);
        if (!allApprovedOrRejected) {
            showModal('failure','All positions approval status must be either approved or rejected.');
            return;
        }
        let overallApprovalStatus;
        if (sections.every(section => parseInt(section.approvedStatus) === approveStatusId)) {
            overallApprovalStatus = 'Approve';
        } else if (sections.some(section => parseInt(section.approvedStatus) === rejectStatusId)) {
            overallApprovalStatus = 'Partial Approve';
        }
        openModal(
            'Confirm Approval',
            overallApprovalStatus === 'Approve' ? 'Are you sure you want to approve this Request?' :
            <>
            Are you sure you want to approve this Request? Please provide a reason.
            <span style={{ color: 'red' }}>*</span>
            </>,
            overallApprovalStatus
        );
    };

    const handleReject = () => {
        openModal(
            'Confirm Rejection',
            <>
            Are you sure you want to reject this Request? Please provide reason for Rejection. 
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Reject'
        );
    };

    const handleReSubmit = () => {
        openModal(
            'Confirm Re Submit',
            <>
            Are you sure you want to re submit this Request? Please provide reason for Re Submit.',
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Resubmit'
        );
    };

    const handleRevert = () => {
        openModal(
            'Confirm Revert',
            <>
            Are you sure you want to revert this Request? Please provide a reason for Revert.
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Revert'
        );
    };

    const handleReopen = () => {
        navigate(`/esfmdeploymentrequest/edit/${deploymentRequestId}`);
    }

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const userHasApproveESFMRequest = hasAccess([process.env.REACT_APP_ROLENAME_APPROVEREQUESTESFM]);
    const userHasReviewESFMRequest = hasAccess([process.env.REACT_APP_ROLENAME_REVIEWREQUESTESFM]);
    const userHasPostRequestOnDeploymentRoster = hasAccess([process.env.REACT_APP_ROLENAME_POSTREQUESTONDR]);
    const userHasProvideWBS = hasAccess([process.env.REACT_APP_ROLENAME_PROVIDEWBS]);
    const userHasDraftExpense = hasAccess([process.env.REACT_APP_ROLENAME_DRAFTEXPENSES]);
    const userHasSubmitExpense = hasAccess([process.env.REACT_APP_ROLENAME_SUBMITEXPENSES]);
    const userHasApproveExpense = hasAccess([process.env.REACT_APP_ROLENAME_APPROVEEXPENSES]);

    const handleOpenPopup = (index) => {
        setSelectedSection(sections[index]);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedSection(null);
    };

    const submitApprovalButtonVisibilityStatus = ['Submitted']
    const approveButtonVisibilityStatus = ['Submitted for Approval'];
    const rejectButtonVisibilityStatus = ['Submitted','Submitted for Approval','Approved','Partial Approved'];
    const revertButtonVisibilityStatus = ['Submitted','Approved','Partial Approved','Rejected','Submitted for Approval'];
    const responseEventsVisibilityStatus = ['Submitted for Approval','Approved','Partial Approved','In Progress','Completed'];
    const postRequestOnDeploymentRosterVisibility = ['Approved','In Progress','Completed'];
    const submitExpenseButtonVisibilityStatus = ['Draft']
    const approveExpenseButtonVisibilityStatus = ['Submitted']
    const revertExpenseButtonVisibilityStatus = ['Submitted','Approved']

    const handleShowHistory = () =>{
        fetchHistoryData(deploymentRequestId);
        setShowHistory(true);
        setShowDetails(false);
        setShowRemarks(false);
        setShowExpenses(false);
        setShowFinalReport(false);
    }; 

    const handleShowRemarks = () => {
        fetchRemarksData(deploymentRequestId);
        setShowRemarks(true)
        setShowHistory(false);
        setShowDetails(false);
        setShowExpenses(false);
        setShowFinalReport(false);
    };

    const handleExpenses = () =>{
        setShowRemarks(false)
        setShowHistory(false);
        setShowDetails(false);
        setShowExpenses(true);
        setShowFinalReport(false);
        fetchExpensesWbs(deploymentRequestId);
    };

    const handleBackToDetails = () => {
        setShowRemarks(false)
        setShowHistory(false);
        setShowDetails(true);
        setShowExpenses(false);
        setShowFinalReport(false);
    };

    const handleShowFinalReport = () => {
        setShowFinalReport(true);
        setShowRemarks(false)
        setShowHistory(false);
        setShowDetails(false);
        setShowExpenses(false);
    };

    function formatDateToUTC(dateString) {
        const date = new Date(dateString);
        
        const formattedDate = date.toLocaleString("en-US", {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,        // 12-hour format
          timeZone: 'UTC'      // Convert to UTC time
        });
        return formattedDate;
    };

    const handleAddRemarkModel = () =>{
    setShowRemarkModal(true);
    };

    const handleCloseRemarkModal = () => {
        setShowRemarkModal(false);
        setRemarkPopupText('');
        setRemarkError('');
    };
    
    const handleRemarkPopupText = (event) => {
        setRemarkPopupText(event.target.value);
    };

    const handleModalConfirmRemark = () => {
        if (remarkPopupText.trim() === '') {
            setRemarkError('Remark cannot be empty');
            return;
        }
        var payload = {deploymentRequestId: parseInt(deploymentRequestId), remarkText: remarkPopupText, requestCode: requestCode, requestedTeam: supportingTeam};
            AddRemarkDetails(payload).then((res) => {
               if (res.data.success) {
                showModal('success',res.data.message);
                handleCloseRemarkModal();
                fetchRemarksData(deploymentRequestId);
              } else {
                showModal('failure',res.data.message);
                handleCloseRemarkModal();
            }       
        });
    };

    const handlePostRequestOnDeploymentRoster = (index) => {
        if (!handleValidation('ForPostDeploymentRequest', index)) {
                handleClose(); 
                return;
            }
            openModal(
            'Confirm',
            'Are you sure you want to Post Request on Deployment Roster for this Request?',
            'Post Request on Deployment Roster'
        );
    };

    const handleUpdateDeployeeInformation = (index) => {
        if (!handleValidation('ForUpdateDeployeeInformation', index)) {
            handleClose(); 
            return;
        }
        openModal(
        'Confirm',
        'Are you sure you want to Update Deployee Information for this Request?',
        'Update Deployee Information'
        );
    };

    const calculateDaysInclusive = (startDate, endDate) => {
        // Parse the date strings into Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);
    
        // Normalize the time to midnight to avoid time differences
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
    
        // Calculate the time difference in milliseconds
        const timeDifference = end - start;

        // Convert time difference from milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms)
        const daysDifference = timeDifference / (1000 * 3600 * 24);
    
        // Add 1 to include both the start and end date
        return daysDifference + 1;
    };
    
    const toggleEditMode = (index) => {
        setHideSection(false);
        setEditableSections((prev) => {
            const newEditable = [...prev];
            newEditable[index] = !newEditable[index]; // Toggle the editable state for this section
            return newEditable;
        });
    };

    const validateEmails = (emails) => {
        const emailArray = emails.split(",").map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        for (let email of emailArray) {
          if (!emailRegex.test(email)) {
            return `Invalid CC User emailId: ${email}`;
          }
        }
        return null;
    };

    const handleEditCcUsersClick = () => {
        setIsCcMailEditing(true);
    };
    
    const handleSaveCcUsersClick = () => {        
        if (ccUsersMail.trim() === '') {
            setCcUserEmailIdError('Cc Emails can not be empty');
            return;
        }
        const validationErrorMessage = validateEmails(ccUsersMail);
        if(validationErrorMessage){
            setCcUserEmailIdError(validationErrorMessage);
            return;
        }
        var payload = {deploymentRequestId: parseInt(deploymentRequestId), ccUsersEmailId: ccUsersMail.trim()};
            UpdateCCUsersDetails(payload).then((res) => {
               if (res.data.success) {
                setIsCcMailEditing(false);
                showModal('success',res.data.message);
                fetchDeploymentRequestData(deploymentRequestId);
              } else {
                showModal('failure',res.data.message);
            }       
        });
    };

    /////Section for Approval Cieling and WBS
    const [rows, setRows] = useState([]);
    const [newRow, setNewRow] = useState({ id: '', selectedWbsCode: '', wbsCode: '',wbsDescription:'', wbsAmount: '' });
    const [rowToDelete, setRowToDelete] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [addCeiling, setAddCeiling] = useState(false);
    const [updateCeiling, setUpdateCeiling] = useState(false);

    const handleAddCeiling = () =>{
        setAddCeiling(true);
        setUpdateCeiling(true)
    };

    const handleDynamicRowChange = (e) => {
        const { name, value } = e.target;    
        if (name === "selectedWbsCode") {
            const selectedWBS = allWbs.find((wbs) => wbs.wbsDetailId === parseInt(value)); 
            setNewRow({
                ...newRow,
                selectedWbsCode: selectedWBS ? selectedWBS.wbsDetailId : '', 
                wbsCode: selectedWBS ? selectedWBS.wbsCode : '', 
                wbsDescription: selectedWBS ? selectedWBS.wbsDescription : ''
            });
        } else {
            setNewRow({
                ...newRow,
                [name]: value, 
            });
        }
    };
    
    const handleAddRow = () => {
        if (newRow.selectedWbsCode && newRow.wbsAmount) {
            const selectedWBS = allWbs.find(wbs => wbs.wbsDetailId === parseInt(newRow.selectedWbsCode));
            if (!selectedWBS) {
                showModal('failure', "Please select a valid WBS Code.");
                return;
            }            
            const isDuplicate = rows.some(row => row.selectedWbsCode === selectedWBS.wbsDetailId && row.id !== newRow.id);
            if (isDuplicate) {
                showModal('failure', "Duplicate WBS! Please select a different WBS.");
                return;
            }    
            if (newRow.id) {
                // Update existing row
                setRows((prevRows) => 
                    prevRows.map(row => 
                        row.id === newRow.id
                            ? { ...row, selectedWbsCode: selectedWBS.wbsDetailId,wbsCode: selectedWBS.wbsCode, wbsDescription: selectedWBS.wbsDescription, wbsAmount: parseFloat(newRow.wbsAmount) }
                            : row
                    )
                );
            } else {
                // Add new row with a unique ID
                setRows([
                    ...rows,
                    {
                        id: Date.now(), 
                        selectedWbsCode: selectedWBS.wbsDetailId,
                        wbsCode: selectedWBS.wbsCode,
                        wbsDescription: selectedWBS.wbsDescription,
                        wbsAmount: parseFloat(newRow.wbsAmount)                        
                    }
                ]);
            }
            setNewRow({ id: '', selectedWbsCode: '', wbsCode: '', wbsDescription: '', wbsAmount: '' });
            setAddCeiling(false);
        } else {
            showModal('failure', "Please fill in all required fields.");
        }
    };
    
    const handleRemoveRow = (id) => {
        const rowToDelete = rows.find((row) => row.id === id);
        if (rowToDelete) {
            setRowToDelete(id); // Store the ID of the row to be deleted
            setShowDeleteConfirm(true); // Show the confirmation modal
        }
    };
    
    const handleConfirmDeleteRow = async() => {
        if (rowToDelete) {
            const removedRow = rows.find((row) => row.id === rowToDelete);
            if (!removedRow) {
                setShowDeleteConfirm(false);
                return;
            }
            setShowDeleteConfirm(false); 
            const res = await deleteCeilingRecord(allWbs.find((wbs) => wbs.wbsCode === removedRow.wbsCode)?.wbsDetailId, deploymentRequestId);
                if (res.data.success) {
                    setRows((prevRows) => 
                        prevRows.filter((row) => row.id !== rowToDelete).map((row) => ({
                            id: row.id,
                            selectedWbsCode: allWbs.find((wbs) => wbs.wbsCode === row.wbsCode)?.wbsDetailId,
                            wbsCode: row.wbsCode,
                            wbsDescription: row.wbsDescription,
                            wbsAmount: row.wbsAmount,
                        }))
                    );
                    showModal('success', res.data.message);
                } else {
                    showModal('failure', res.data.message);
                }
            setRowToDelete(null);
            setUpdateCeiling(false); 
        }
    };

    const handleUpdateRow = (id) => {
        setAddCeiling(true);
        setUpdateCeiling(true); 
        const rowToEdit = rows.find((row) => row.id === id);
        if (rowToEdit) {
            const selectedWBS = allWbs.find((wbs) => wbs.wbsCode === rowToEdit.wbsCode); 
            setNewRow({
                id: rowToEdit.id,
                selectedWbsCode: selectedWBS ? selectedWBS.wbsDetailId : '',
                wbsCode: rowToEdit.wbsCode,
                wbsDescription: rowToEdit.wbsDescription,
                wbsAmount: rowToEdit.wbsAmount,
            });
        }
    };    

    const getUpdatedInnerHTMLWithoutOverwriting = (sectionId) => {
        const originalSection = document.getElementById(sectionId);
        if (originalSection) {
            const clonedSection = originalSection.cloneNode(true);
            styleTables(clonedSection);
            return clonedSection.innerHTML;
        } 
    };
    
    const handleStyleTablesAndFetchHTML = () => {
        const reportSectionId = "approvedCeilingDetails";
        const updatedInnerHTML = getUpdatedInnerHTMLWithoutOverwriting(reportSectionId);
        return updatedInnerHTML;
    };  

    const handlePostRows = async () => {
        setUpdateCeiling(false);   
        if (rows.length === 0) {
            showModal("failure", "No Record to save!");
            return;
        }    
        try {
            const payload = {
                requestId: deploymentRequestId,
                wbsDetails: rows.map(row => ({
                    wbsDetailId: parseInt(row.selectedWbsCode), 
                    wbsCode: allWbs.find(wbs => wbs.wbsDetailId === parseInt(row.selectedWbsCode))?.wbsCode || '',
                    wbsAmount: parseFloat(row.wbsAmount),
                })),
                deploymentTypeName: deploymentType,
                requestedTeam: supportingTeam,
                requestCode: requestCode,
                htmlContent: handleStyleTablesAndFetchHTML()
            };
            const response = await UpdateWBSAndCieling(payload);
            if (response.data.success) {
                showModal('success', response.data.message);                             
            } else {
                showModal('failure', response.data.message);
            }  
            fetchDeploymentRequestData(deploymentRequestId);     
        } catch (error) {
            showModal('failure', 'Failed to update WBS and Ceiling. Please try again later.');
        }  
        
    };    

    ///Section Expenses
    const [addExpenseSection, setAddExpenseSection] = useState(false);
    const [expenseSelectedPosition, setExpenseSelectedPosition] = useState('');
    const [expenseSelectedWbs, setExpenseSelectedWbs] = useState('');
    const [expenseSelectedType, setExpenseSelectedType] = useState('');
    const [expenseCost, setExpenseCost] = useState('');
    const [expenseDescription, setExpenseDescription] = useState('');
    const [allExpensesPositions, setAllExpensesPositions] = useState([]);
    const [allExpensesWbs, setAllExpensesWbs] = useState([]);
    const [allExpensesType, setAllExpensesType] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [showDeleteExpensePopup, setShowDeleteExpensePopup] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [expenseRecordStatus, setExpenseRecordStatus] = useState('Draft');

    const AddExpense = () => {
        setAddExpenseSection(true);
    };

    const validateExpenseEntry = (newExpenseEntry, expensesData, approvedCeiling) => {
        // Validate required fields
        if (newExpenseEntry.positionId === null || newExpenseEntry.positionId === "") {
            return { isValid: false, message: "Position is required." };
        }
        if (!newExpenseEntry.expenseTypeId) {
            return { isValid: false, message: "Expense Type is required." };
        }
        if (!newExpenseEntry.wbsId) {
            return { isValid: false, message: "WBS is required." };
        }
        if (!newExpenseEntry.description) {
            return { isValid: false, message: "Description is required." };
        }
        if (!newExpenseEntry.amount || isNaN(newExpenseEntry.amount) || newExpenseEntry.amount <= 0) {
            return { isValid: false, message: "Amount must be a valid positive number." };
        }
    
        // Check for duplicate entry
        const isDuplicate = expensesData.some(expense =>
            expense.position === newExpenseEntry.position &&
            expense.expenseType === newExpenseEntry.expenseType &&
            expense.wbs === newExpenseEntry.wbs
        );
    
        if (isDuplicate) {
            return { isValid: false, message: "An expense with the same Position, WBS, and Expense Type already exists." };
        }
    
        // Calculate current expenditure for the selected WBS
        const currentExpenditure = expensesData
            .filter(exp => exp.wbs === newExpenseEntry.wbs)
            .reduce((sum, exp) => sum + exp.amount, 0);
    
        // Validation check for approved ceiling
        if (currentExpenditure + newExpenseEntry.amount > approvedCeiling) {
            return {
                isValid: false,
                message: `The amount entered exceeds the Approved Ceiling for the selected WBS. \n\nPlease note the Approved Ceiling Amount: $${approvedCeiling.toFixed(2)}`
            };
        }    
        return { isValid: true };
    };
    
    const handleUpdateExpenses = async () => {
        const selectedPosition = allExpensesPositions.find(pos => pos.expensePositionId === parseInt(expenseSelectedPosition));
        const selectedExpenseType = allExpensesType.find(type => type.expenseTypeId === parseInt(expenseSelectedType));
        const wbsItem = allExpensesWbs.find(wbs => wbs.wbsDetailId === parseInt(expenseSelectedWbs));
        const approvedCeiling = wbsItem?.wbsAmount;
    
        // Create the new expense entry
        const newExpenseEntry = {
            fundingExpenseId: 0,
            position: selectedPosition ? selectedPosition.expensePositionDescription : "Request Level Expenses",
            positionId: selectedPosition ? selectedPosition.expensePositionId : null,
            expenseType: selectedExpenseType ? selectedExpenseType.expenseTypeDescription : null,
            expenseTypeId: selectedExpenseType ? selectedExpenseType.expenseTypeId : null,
            wbs: wbsItem ? wbsItem.wbsCode : null,
            wbsId: wbsItem ? wbsItem.wbsDetailId : null,
            description: expenseDescription,
            amount: parseFloat(expenseCost),
            status: ''
        };
    
        // Validate the new expense entry
        const validation = validateExpenseEntry(newExpenseEntry, expensesData, approvedCeiling);
        if (!validation.isValid) {
            showModal("failure", validation.message);
            return;
        }
    
        // Add new expense to expensesData and save it to the server
        setExpensesData([...expensesData, newExpenseEntry]);
        const expenseData = {
            positionId: newExpenseEntry.positionId,
            positionDescription: newExpenseEntry.position,
            expenseTypeId: newExpenseEntry.expenseTypeId,
            wbsId: newExpenseEntry.wbsId,
            description: newExpenseEntry.description,
            amount: newExpenseEntry.amount,
            requestId: deploymentRequestId
        };
    
        try {
            const res = await saveExpenseRecord(expenseData);
            if (res.data.success) {
                showModal('success', res.data.message);
                setAddExpenseSection(false);
                resetExpensesControls();
                fetchAllExpensess(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        } catch (error) {
            showModal('failure', 'Error saving expense record.');
        }
    };      

    const resetExpensesControls = () =>{
        setExpenseSelectedPosition('');
        setExpenseSelectedWbs('');
        setExpenseSelectedType('');
        setExpenseCost('');
        setExpenseDescription('');
    };    

    const handleDeleteExpense = async (expense, index) => {
        setSelectedExpense(expense);
        setSelectedIndex(index);
        setShowDeleteExpensePopup(true);
    };    

    const handleConfirmDelete = async () => {
        if (selectedExpense.fundingExpenseId !== 0) {
            try {
                const res = await deleteExpenseRecord(selectedExpense.fundingExpenseId);
                if (res.data.success) {
                    const updatedExpensesData = expensesData.filter((_, idx) => idx !== selectedIndex);
                    setExpensesData(updatedExpensesData);
                    showModal('success', res.data.message);
                    fetchAllExpensess(parseInt(deploymentRequestId));
                } else {
                    showModal('failure', res.data.message);
                }
            } catch (error) {
                showModal('failure', 'Error deleting expense record.');
            }
        } else {
            const updatedExpensesData = expensesData.filter((_, idx) => idx !== selectedIndex);
            setExpensesData(updatedExpensesData);
            showModal('success', 'Expense record deleted successfully.');
            fetchAllExpensess(parseInt(deploymentRequestId));
        }
        handleCloseDeleteExpensePopup();
    };
    
    const handleCloseDeleteExpensePopup = () => {
        setShowDeleteExpensePopup(false);
        setSelectedExpense(null);
        setSelectedIndex(null);
    };

    const handleSubmitExpenses = async() => {
        const expenseTableRecord = document.getElementById('expenseTableRecord');
        if (!expenseTableRecord) {
            console.error("Expense table not found.");
            return;
        }
    
        // Clone the content to avoid directly modifying the DOM
        const clonedContent = expenseTableRecord.cloneNode(true);
    
        // Create a wrapper div for the entire content
        const wrapperDiv = document.createElement("div");
        wrapperDiv.style.backgroundColor = "white"; 
        wrapperDiv.style.padding = "20px"; 
        wrapperDiv.style.border = "1px solid #ccc"; 
    
        // Append the cloned content to the wrapper div
        wrapperDiv.appendChild(clonedContent);
    
        // Add background color to all section-header divs
        const sectionHeaders = clonedContent.getElementsByClassName("section-header");
        for (let header of sectionHeaders) {
            header.style.padding = "10px"; 
            header.style.fontWeight = "bold"; 
        }
    
        // Add borders and remove 'Action' column cells from all tables in the cloned content
        const tables = clonedContent.getElementsByTagName("table");
        for (let table of tables) {
            // Apply border styling to each table
            table.style.border = "1px solid black";
            table.style.borderCollapse = "collapse";
            table.style.width = '100%'
            // Apply border to each cell
            const allCells = table.querySelectorAll("th, td");
            allCells.forEach(cell => {
                cell.style.border = "1px solid black";
            });
    
            // Find the index of the 'Action' column if it exists
            const headerCells = table.querySelectorAll("thead th");
            let actionColumnIndex = -1;
            headerCells.forEach((headerCell, index) => {
                if (headerCell.innerText.trim() === "Action") {
                    actionColumnIndex = index;
                }
            });
    
            // Remove 'Action' column cells from the header and all rows
            if (actionColumnIndex !== -1) {
                headerCells[actionColumnIndex].remove();
                const rows = table.querySelectorAll("tbody tr");
                rows.forEach(row => {
                    const cells = row.getElementsByTagName("td");
                    if (cells[actionColumnIndex]) {
                        cells[actionColumnIndex].remove();
                    }
                });
            }
        }
        const modifiedHtmlContent = wrapperDiv.innerHTML;
        const fundingExpenseIds = expensesData.map(expense => expense.fundingExpenseId);
        const totalExpenseAmount = expensesData.reduce((total, expense) => total + expense.amount, 0);
        var payload = {
            deploymentRequestId,
            deploymentType,
            supportingTeam,
            requestCode,
            tableContent: modifiedHtmlContent,
            fundingExpenseIds: fundingExpenseIds,
            expenseCost: totalExpenseAmount
        }
        try {
            const res = await submitExpenseRecord(payload);
            if (res.data.success) {
                showModal('success', res.data.message);
                fetchAllExpensess(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        } catch (error) {
            showModal('failure', 'Error submitting expense record.');
        }
    };  

    const handleApproveExpenses = async() => {
        const fundingExpenseIds = expensesData.map(expense => expense.fundingExpenseId);
        const totalExpenseAmount = expensesData.reduce((total, expense) => total + expense.amount, 0);
        var payload = {
            deploymentRequestId,
            deploymentType,
            supportingTeam,
            requestCode,
            tableContent: '',
            fundingExpenseIds: fundingExpenseIds,
            expenseCost: totalExpenseAmount
        }
        try {
            const res = await approveExpenseRecord(payload);
            if (res.data.success) {
                showModal('success', res.data.message);
                fetchAllExpensess(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        } catch (error) {
            showModal('failure', 'Error approving expense record.');
        }
    };

    const handleRevertExpenses = () => {
        openModal(
            'Confirm Revert',
            <>
            Are you sure you want to revert the Expenses? Please provide a reason for Revert.
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Revert Expenses'
        );
    };

    const revertExpenseRecord = async() => {
        const fundingExpenseIds = expensesData.map(expense => expense.fundingExpenseId);
        var payload = {
            deploymentRequestId,
            deploymentType,
            supportingTeam,
            requestCode,
            tableContent: reason,
            fundingExpenseIds: fundingExpenseIds,
            expenseCost: 0
        }
        try {
            const res = await revertExpensesRecord(payload);
            if (res.data.success) {
                showModal('success', res.data.message);
                fetchAllExpensess(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        } catch (error) {
            showModal('failure', 'Error revert expense record.');
        }
    };

    ///Section Final Report
    const [reportAchievements, setReportAchievements] = useState('');
    const [reportChallenges, setReportChallenges] = useState('');
    const [reportRecommendations, setReportRecommendations] = useState('');

    const validateReportForm = (reportAchievements, reportRecommendations, reportChallenges, requestStatus) => {
        if (!reportAchievements || reportAchievements.trim() === "") {
            return { isValid: false, message: "Main Achievements and Deliverables are required." };
        }
        if (!reportRecommendations || reportRecommendations.trim() === "") {
            return { isValid: false, message: "Follow Up Actions & Recommendations are required." };
        }
        if (!reportChallenges || reportChallenges.trim() === "") {
            return { isValid: false, message: "Challenges & Lessons Learnt are required." };
        }    
        if (requestStatus !== 'In Progress') {
            return { isValid: false, message: "Submission is only allowed when the request status is 'In Progress'." };
        }
        return { isValid: true };
    };
    
    const handleSubmitFinalReport = async () => {
        const reportSection = document.getElementById('finalReportSection');
        if (!reportSection) {
            console.error("Final report section not found.");
            return;
        }
    
        const mainWrapper = generateFinalReportHTML(reportSection);
    
        const validationResult = validateReportForm(reportAchievements, reportRecommendations, reportChallenges, requestStatus);
        if (!validationResult.isValid) {
            showModal("failure", validationResult.message);
            return;
        }
    
        var payload = {
            deploymentRequestId,
            reportAchievements,
            reportRecommendations,
            reportChallenges,
            deploymentType,
            supportingTeam,
            requestCode,
            htmlContent: mainWrapper.innerHTML
        };
    
        try {
            const res = await submitFinalReport(payload);
            if (res.data.success) {
                showModal('success', res.data.message);
                fetchAllExpensess(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        } catch (error) {
            showModal('failure', 'Error submitting final report');
        }
    };
    
    const generateFinalReportHTML = (reportSection) => {
        const mainWrapper = document.createElement("div");
        mainWrapper.style.border = "3px solid #7a9fd9"; 
        mainWrapper.style.padding = "20px";
        mainWrapper.style.backgroundColor = "#f9f9f9"; 
        mainWrapper.style.borderRadius = "8px";
    
        reportSection.parentNode.insertBefore(mainWrapper, reportSection);
        mainWrapper.appendChild(reportSection);
    
        const requestCodeParagraph = document.createElement("p");
        requestCodeParagraph.textContent = "Request Code: " + requestCode; 
        requestCodeParagraph.style.fontWeight = "bold";
        requestCodeParagraph.style.marginBottom = "15px";
        requestCodeParagraph.style.fontSize = "16px"; 
        requestCodeParagraph.style.color = "#333"; 
        reportSection.insertBefore(requestCodeParagraph, reportSection.firstChild);
        
        reportSection.style.border = "2px solid #000000"; 
        reportSection.style.padding = "20px";
        reportSection.style.backgroundColor = "white"; 
    
        styleSectionHeaders(reportSection);
        styleTables(reportSection);
        styleFormElements(reportSection);
    
        return mainWrapper;
    };
    
    const styleSectionHeaders = (reportSection) => {
        const sectionHeaders = reportSection.getElementsByClassName("section-header");
        for (let header of sectionHeaders) {
            header.style.backgroundColor = "#f0f8ff"; 
            header.style.padding = "10px"; 
            header.style.marginBottom = "10px"; 
            header.style.marginTop = "10px"; 
            header.style.fontWeight = "bold";
        }
    };
    
    const styleTables = (reportSection) => {
        const tables = reportSection.getElementsByTagName("table");
        for (let table of tables) {
            table.style.border = "1px solid black";
            table.style.borderCollapse = "collapse";
            table.style.width = "100%";
    
            const allCells = table.querySelectorAll("th, td");
            allCells.forEach(cell => {
                cell.style.border = "1px solid black";
                cell.style.padding = "5px";
            });
    
            const headerCells = table.querySelectorAll("thead th");
            let actionColumnIndex = -1;
            headerCells.forEach((headerCell, index) => {
                if (headerCell.innerText.trim() === "Action") {
                    actionColumnIndex = index;
                }
            });
    
            if (actionColumnIndex !== -1) {
                headerCells[actionColumnIndex].remove();
                const rows = table.querySelectorAll("tbody tr");
                rows.forEach(row => {
                    const cells = row.getElementsByTagName("td");
                    if (cells[actionColumnIndex]) {
                        cells[actionColumnIndex].remove();
                    }
                });
            }
        }
    };
    
    const styleFormElements = (reportSection) => {
        const submitButtons = reportSection.querySelectorAll("button[type='submit']");
        submitButtons.forEach(button => button.remove());
    
        const labels = reportSection.getElementsByTagName('label');
        for (let label of labels) {
            label.style.display = 'block';
            label.style.marginBottom = '8px';
            label.style.marginTop = '8px';
            label.style.fontWeight = 'bold';
        }
    
        const textareas = reportSection.getElementsByTagName('textarea');
        for (let textarea of textareas) {
            textarea.style.width = '100%';
            textarea.style.padding = '8px';
            textarea.style.border = '1px solid #ccc';
            textarea.style.borderRadius = '4px';
            textarea.style.resize = 'vertical';
        }
    };
    
    const generatePDFFinalReport = async () => {
        const reportSection = document.getElementById('finalReportSection');
        if (!reportSection) {
            console.error("Final report section not found.");
            return;
        }
    
        const generatePdfButton = document.getElementById('generatePdfButton');
        
        // Temporarily hide the button during PDF generation
        if (generatePdfButton) {
            generatePdfButton.style.display = 'none';
        }
    
        // Create a wrapper for PDF content generation
        const pdfWrapper = document.createElement("div");
    
        // Create a request code paragraph
        const requestCodeParagraph = document.createElement("p");
        requestCodeParagraph.textContent = "Request Code: " + requestCode;
        requestCodeParagraph.style.fontWeight = "bold";
        requestCodeParagraph.style.marginBottom = "15px";
        requestCodeParagraph.style.fontSize = "16px";
        requestCodeParagraph.style.color = "#333";
    
        pdfWrapper.appendChild(requestCodeParagraph);
    
        // Clone the report section to avoid modifying the original content
        const clonedReportSection = reportSection.cloneNode(true);
    
        // Replace <textarea> elements with <div> for proper PDF rendering
        const textareas = clonedReportSection.querySelectorAll('textarea');
        textareas.forEach(textarea => {
            const div = document.createElement('div');
            div.style.whiteSpace = 'pre-wrap'; // Preserve line breaks
            div.style.wordBreak = 'break-word';
            div.textContent = textarea.value; // Copy the content of the textarea
            textarea.parentNode.replaceChild(div, textarea);
        });
    
        // Append the cloned section to the wrapper
        pdfWrapper.appendChild(clonedReportSection);
    
        const options = {
            margin: [10, 10, 10, 10],  
            filename: 'finalreport-' + requestCode + '.pdf',
            html2canvas: { scale: 2 },  
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        // Generate and save the PDF
        html2pdf().from(pdfWrapper).set(options).save();
        
        // Restore the button after PDF generation
        if (generatePdfButton) {
            generatePdfButton.style.display = 'block';
        }
    };           

    const handleUpdateActualFromToDateClick = (index) => {
        setIsActualDatesEditing(index);
    };

    const handleSaveActualFromToDateClick = async(index) =>{
        const section = sections[index]; 
        if (!section.actualStartDate) {
            showModal('failure',"Please select an Actual From Date.");
            return;
        }
        if (!section.actualEndDate) {
            showModal('failure',"Please select an Actual To Date.");
            return;
        }
        if (section.actualEndDate < section.actualStartDate) {
            showModal('failure',"Actual To Date cannot be earlier than Actual From Date.");
            return;
        }
        const payload = {
            actualStartDate: section?.actualStartDate ? new Date(section.actualStartDate).toISOString() : null,
            actualEndDate: section?.actualEndDate ? new Date(section.actualEndDate).toISOString() : null,
            deploymentRequestId: parseInt(section?.deploymentRequestId ?? 0),
            positionId: parseInt(section?.deploymentRequestPositionId ?? 0),
        };
        try {
            const res =await updatePositionActualFromToDates(payload);
            if (res.data.success) {
                showModal('success', res.data.message);
                fetchAllExpensess(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        } catch (error) {
            showModal('failure', 'Error while updating the actual start end dates');
        }
        setIsActualDatesEditing(null);
    };

    const handleDownloadDocument = async (documentName, documentPath) => {
            if (!documentName || !documentPath) {
                showModal('failure', "Document details are missing.");
                return;
            }
            try {
                const response = await DownloadUploadedDocument(documentName, documentPath);
                if (response.success !== false) {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    const contentDisposition = response.headers['content-disposition'];
                    let fileName = documentName; 
                    if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
                        fileName = contentDisposition.split('filename=')[1].replace(/"/g, ''); 
                    }
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    } else {
                        showModal('failure', response.message || "Failed to download the document.");
                    }
            } catch (error) {
                showModal('failure', "An unexpected error occurred while downloading the file.");
            }
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>  
                            <div className="container mt-5">
                                {showDeatils && (
                                    <div className="card mb-3">
                                        <div className='card-header'>
                                            <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Approved' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                            {expenseRecordStatus === 'Approved' && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowFinalReport}>Final Report</button>)}
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowRemarks}>Remarks</button>
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowHistory}>History</button>
                                            {(requestStatus === 'In Progress' || requestStatus === 'Completed') && rows.length > 0 && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleExpenses}>Expenses</button>)}
                                        </div>
                                        <div className='card-body'>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>
                                                        Response & Deployment Type with Request Cost
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label htmlFor="responseEvent" className="form-label"><strong>Response/Event <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                    <select id="responseEvent" className="form-select" value={selectedResponseEvent} onChange={(e) => handleInputChange(e)} disabled={responseEventsVisibilityStatus.includes(requestStatus)}>
                                                            <option value="">--Select--</option>
                                                            {responseEvents.map((res) => (
                                                                <option key={res.responseId} value={res.responseId}>{res.responseDescription}</option>
                                                            ))}
                                                    </select>
                                                    {errors.selectedResponseEvent && <p style={{color: 'red'}}>{errors.selectedResponseEvent}</p>}
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label htmlFor="deploymentType" className="form-label"><strong>Deployment Type <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                    <input type="text" className="form-control" id="deploymentType" placeholder="Deployment Type" value={deploymentType} disabled />
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label htmlFor="supportingTeam" className="form-label"><strong>Requested Team <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                    <input type="text" className="form-control" id="supportingTeam" placeholder="Requested Team" value={supportingTeam} disabled />
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label htmlFor="requestCost" className="form-label"><strong>Request Cost <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                    <input type="text" className="form-control" id="requestCost" placeholder="Request Cost" value={requestCost} disabled />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="form-group mb-3 col-md-12">
                                                    <label htmlFor="ccUsersMail" className="form-label" title="Provide the Cc Emails in comma separated format"><strong>Cc Emails</strong></label>
                                                    <input type="text" className="form-control" id="ccUsersMail" placeholder="Provide the Cc Emails in comma separated format" value={ccUsersMail} onChange={(e) => setCcUsersMail(e.target.value)} disabled={!isCcMailEditing}/>
                                                    {requestStatus !== 'Completed' &&(
                                                        <>
                                                        {isCcMailEditing ? (<button type="button" onClick={handleSaveCcUsersClick} className="btn btn-primary btn-sm" style={{position: 'absolute', right: '20px', transform: 'translateY(-112%)', background: 'none', color: '#28a745', border: 'none'}}><i class="bi bi-floppy"></i></button>) : (
                                                            <button type="button" onClick={handleEditCcUsersClick} className="btn btn-secondary btn-sm" style={{position: 'absolute', right: '20px', transform: 'translateY(-112%)', background: 'none', border: 'none'}}><i className="bi bi-pencil" style={{color:'#ffc107'}}></i></button>
                                                        )}
                                                        {ccUserEmailIdError && (<p style={{color: 'red'}}>{ccUserEmailIdError}</p>)}
                                                        </>
                                                    )}                                                    
                                                </div>
                                            </div>
                                            {isESFM && (<>
                                                {sections.map((section, index) => (
                                                    <div key={index} className='responseevents'>
                                                        <div className='section-header'>
                                                            <span>
                                                                Position Specific Information - {section.postionHeader} 
                                                            </span>
                                                            <button className="btn btn-link collapse-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsePosition_${index}`} aria-expanded="false" aria-controls={`collapsePosition_${index}`} onClick={() => toggleIcon(index)}>
                                                                <FontAwesomeIcon icon={section.isCollapsed ? faPlus : faMinus} />
                                                            </button>
                                                        </div>
                                                        <div id={`collapsePosition_${index}`} className={`collapse ${section.isCollapsed ? '' : 'show'}`}>
                                                            <div className='form-outline'>
                                                            <fieldset>
                                                                <div className='row'>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`dutyStation_${index}`} className='form-label'>
                                                                        <strong>Duty Station:</strong> {section.dutyStation} 
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`positionLevel_${index}`} className='form-label'>
                                                                            <strong>Position Level:</strong> {section.selectedPositionLevel}
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`positionType_${index}`} className='form-label me-2'>
                                                                        <strong>Position Type:</strong> {section.selectedPositionType}
                                                                        </label>
                                                                    </div>    
                                                                    <div className='form-group mb-3 col-md-3'>
                                                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={() => handleOpenPopup(index)}>View Position Details</button>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>                                                                
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`dateFrom_${index}`} className='form-label me-2'>
                                                                        <strong>Target From Date:</strong> {section.fromDate !== null ? formatDate(section.fromDate) : null}
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`toDate_${index}`} className='form-label me-2'>
                                                                        <strong>Target To Date:</strong> {section.toDate !== null ? formatDate(section.toDate) : null}
                                                                        </label>
                                                                    </div> 
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`estimatedCost_${index}`} className='form-label'>
                                                                            <strong>Requested Duration:</strong> {section?.fromDate && section?.toDate ? (<> {calculateDaysInclusive(section.fromDate, section.toDate)} <span>Days</span> </>):''}
                                                                        </label>
                                                                    </div>                                                                                                                                     
                                                                </div>                                                                
                                                                <div className='row'> 
                                                                    <hr style={{marginTop: '-10px'}}/>                                                               
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`dateFrom_${index}`} className='form-label me-2'>
                                                                        <strong>Approved From Date:</strong> {section.plannedFromDate !== null ? formatDate(section.plannedFromDate) : null}
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`toDate_${index}`} className='form-label me-2'>
                                                                        <strong>Approved To Date:</strong> {section.plannedToDate !== null ? formatDate(section.plannedToDate) : null}
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`estimatedCost_${index}`} className='form-label'>
                                                                            <strong>Approved Duration:</strong> {section?.plannedFromDate && section?.plannedToDate ? (<> {calculateDaysInclusive(section.plannedFromDate, section.plannedToDate)} <span>Days</span> </>):''} 
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`estimatedCost_${index}`} className='form-label'>
                                                                            <strong>Approval Status: <span style={{color: section.positionStatus === 'Requested' ? 'blue' : section.positionStatus === 'Approved' ? 'green' : section.positionStatus === 'Rejected' ? 'red' : section.positionStatus === 'Pending' ? 'orange' : 'black'}}>{section.positionStatus}</span></strong>
                                                                        </label>
                                                                    </div>                                                                    
                                                                </div>                                                                
                                                                {(postRequestOnDeploymentRosterVisibility.includes(requestStatus) || requestStatus === 'Partial Approved') && section.positionStatus === 'Approved' && (
                                                                <>
                                                                    
                                                                    {(section.postedPositionOnDeploymentRosterView !== null && section.postedPositionOnDeploymentRosterView !== '') && (
                                                                        <>          
                                                                        <hr style={{marginTop: '-10px'}}/>                                                                  
                                                                            <div className='row'>
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label htmlFor={`postedPositionOnDeploymentRosterView_${index}`} className='form-label me-2'>
                                                                                    <strong>Have you posted the position on Deployment Roster?:</strong> {section.postedPositionOnDeploymentRosterView}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label htmlFor={`opportunityReferenceNumberView_${index}`} className='form-label me-2'>
                                                                                    <strong>Opportunity Reference Number:</strong> {section.opportunityReferenceNumber}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label htmlFor={`postedDateView_${index}`} className='form-label'>
                                                                                        <strong>Posted Date:</strong> {section.postedDate !== null ? formatDate(section.postedDate) : null}
                                                                                    </label>
                                                                                </div>  
                                                                                <div className='form-group mb-3 col-md-3'>
                                                                                    {(expenseRecordStatus === 'Draft' || expenseRecordStatus === 'Submitted') && (<button type='button' className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={() => toggleEditMode(index)}>Update Deployee</button>)}
                                                                                </div>                                                                      
                                                                            </div>
                                                                            <div className='row'> 
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label htmlFor={`deployeeEmailIdView_${index}`} className='form-label me-2'>
                                                                                    <strong>Deployee EmailId:</strong> {section.deployeeEmailId}
                                                                                    </label>
                                                                                </div>                                                                       
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label htmlFor={`deployeeFNameView_${index}`} className='form-label me-2'>
                                                                                    <strong>Deployee First Name:</strong> {section.deployeeFName}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label htmlFor={`deployeeLNameView_${index}`} className='form-label'>
                                                                                        <strong>Deployee Last Name:</strong> {section.deployeeLName}
                                                                                    </label>
                                                                                </div>                                                                        
                                                                            </div>
                                                                        </>
                                                                    )}                                                                                                                                         
                                                                </>
                                                                )}
                                                                {section.positionStatus === 'Approved' && (<div className='row'> 
                                                                    <hr style={{marginTop: '-10px'}}/>                                                               
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`actualdateFrom_${index}`} className='form-label me-2'>
                                                                        <strong>Actual From Date: </strong> 
                                                                        {isActualDatesEditing === index ? (
                                                                            <DatePicker
                                                                            selected={section.actualStartDate}
                                                                            onChange={(date) => handleChange(index, 'actualStartDate', date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText="Select from date"
                                                                            className='form-control'
                                                                        />
                                                                        ): (<span>{section.actualStartDate !== null ? formatDate(section.actualStartDate) : null}</span>)}                                                                        
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`actualtoDate_${index}`} className='form-label me-2'>
                                                                        <strong>Actual To Date: </strong> 
                                                                        {isActualDatesEditing === index ? (
                                                                            <DatePicker
                                                                            selected={section.actualEndDate}
                                                                            onChange={(date) => handleChange(index, 'actualEndDate', date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText="Select to date"
                                                                            className='form-control'
                                                                        />
                                                                        ): (<span>{section.actualEndDate !== null ? formatDate(section.actualEndDate) : null}</span>)}                                                                        
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-3 col-md-3">
                                                                        <label htmlFor={`actualduration_${index}`} className='form-label'>
                                                                            <strong>Actual Duration: </strong>
                                                                            {isActualDatesEditing === index ? (
                                                                                <input type='text' className='form-control' readOnly value={section?.actualStartDate && section?.actualEndDate ? `${calculateDaysInclusive(section.actualStartDate, section.actualEndDate)} Days` : ''}></input>
                                                                            ) : (<span>{section?.actualStartDate && section?.actualEndDate ? (<> {calculateDaysInclusive(section.actualStartDate, section.actualEndDate)} <span>Days</span> </>):''} </span>)}                                                                             
                                                                        </label>
                                                                    </div> 
                                                                    <div className='form-group mb-3 col-md-3'>
                                                                    {(expenseRecordStatus === 'Draft' || expenseRecordStatus === 'Submitted') && (
                                                                        <>
                                                                        {isActualDatesEditing === index ? (<button id={`saveActualDates_${index}`} type='button' className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={() =>handleSaveActualFromToDateClick(index)}>Save Actual From To Dates</button>)
                                                                        :
                                                                        (<button id={`updateActualDates_${index}`} type='button' className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={() => handleUpdateActualFromToDateClick(index)}>Update Actual From To Dates</button>)}
                                                                        </>
                                                                    )}
                                                                    </div>                                                                   
                                                                </div>)}
                                                                {(userInfo.isAdmin || userHasApproveESFMRequest || userHasReviewESFMRequest) && !postRequestOnDeploymentRosterVisibility.includes(requestStatus)  &&(
                                                                <>
                                                                <hr style={{marginTop: '-10px'}}/>
                                                                    <h6>Approval Details</h6>
                                                                    <div className='row'>
                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <label htmlFor={`plannedFromDate_${index}`} className='form-label'>
                                                                            <strong>Approved From Date: <span style={{ color: "red", font: "bold" }}>*</span></strong> 
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={section.plannedFromDate}
                                                                                onChange={(date) => handleChange(index, 'plannedFromDate', date)}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="Select from date"
                                                                                className='form-control'
                                                                            />
                                                                            {errors[index]?.plannedFromDate && <p style={{ color: 'red' }}>{errors[index].plannedFromDate}</p>}
                                                                        </div>
                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <label htmlFor={`plannedToDate_${index}`} className='form-label'>
                                                                                <strong>Approved To Date: <span style={{ color: "red", font: "bold" }}>*</span></strong>
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={section.plannedToDate}
                                                                                onChange={(date) => handleChange(index, 'plannedToDate', date)}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="Select to date"
                                                                                className='form-control'
                                                                            />
                                                                            {errors[index]?.plannedToDate && <p style={{ color: 'red' }}>{errors[index].plannedToDate}</p>}
                                                                        </div>
                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <label htmlFor={`estimatedCostview_${index}`} className='form-label'>
                                                                                <strong>Approved Duration: <span style={{ color: "red", font: "bold" }}>*</span></strong> 
                                                                            </label>
                                                                            <input type='text' className='form-control' readOnly value={section?.plannedFromDate && section?.plannedToDate ? `${calculateDaysInclusive(section.plannedFromDate, section.plannedToDate)} Days` : ''}></input>
                                                                        </div>
                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <label htmlFor={`approvalStatus_${index}`} className='form-label'>
                                                                                <strong>Approval Status: <span style={{ color: "red", font: "bold" }}>*</span></strong>
                                                                            </label>
                                                                            <select id={`approvalStatus_${index}`} className="form-select" value={section.approvedStatus} onChange={(e) => handleChange(index, 'approvedStatus', e.target.value)}>
                                                                                    <option value="">--Select--</option>
                                                                                    {allPositionStatus.map((res) => (
                                                                                        <option key={res.positionStatusId} value={res.positionStatusId}>{res.statusDescription}</option>
                                                                                    ))}
                                                                            </select>
                                                                            {errors[index]?.approvedStatus && <p style={{color: 'red'}}>{errors[index].approvedStatus}</p>}
                                                                        </div>
                                                                    </div>                                                                    
                                                                </>                                                                  
                                                                )}
                                                                {!hideSection && editableSections[index] && (userInfo.isAdmin || userHasPostRequestOnDeploymentRoster) && section.positionStatus === 'Approved' && (
                                                                <>  
                                                                <hr style={{marginTop: '-10px'}}/>
                                                                    <h6>Deployee Information</h6>
                                                                    <div className='row'>
                                                                        <div className="form-group mb-3 col-md-5">
                                                                            <label htmlFor={`postedPositionOnDeploymentRoster_${index}`} className='form-label'>
                                                                            <strong>Have you posted the position on Deployment Roster? <span style={{ color: "red", font: "bold" }}>*</span></strong> 
                                                                            </label>
                                                                            <select id={`postedPositionOnDeploymentRoster_${index}`} className="form-select" value={section.postedPositionOnDeploymentRoster || defaultPendingValue} onChange={(e) => handleChange(index, 'postedPositionOnDeploymentRoster', e.target.value)}>
                                                                                    <option value="">--Select--</option>
                                                                                    {allPostedPositionForDR.map((postedPos) => (
                                                                                        <option key={postedPos.postedPositionId} value={postedPos.postedPositionId}>{postedPos.postedPositionDescription}</option>
                                                                                    ))}
                                                                            </select>
                                                                            {errors[index]?.postedPositionOnDeploymentRoster && <p style={{ color: 'red' }}>{errors[index].postedPositionOnDeploymentRoster}</p>}
                                                                        </div>
                                                                        {section.postedPositionOnDeploymentRoster !== undefined && postDeploymentRemainingFields && (
                                                                            <div className="form-group mb-3 col-md-4">
                                                                                <label htmlFor={`opportunityReferenceNumber_${index}`} className='form-label'>
                                                                                    <strong>Opportunity Reference Number: <span style={{ color: "red", font: "bold" }}>*</span></strong>
                                                                                </label>
                                                                                <input type="text" className="form-control" id={`opportunityReferenceNumber_${index}`} placeholder="Opportunity Reference Number" value={section.opportunityReferenceNumber} onChange={(e) => handleChange(index, 'opportunityReferenceNumber', e.target.value)}/>
                                                                                {errors[index]?.opportunityReferenceNumber && <p style={{ color: 'red' }}>{errors[index].opportunityReferenceNumber}</p>}
                                                                            </div>
                                                                        )}
                                                                        {parseInt(section.postedPositionOnDeploymentRoster) === postedPositionOnDrYes && (
                                                                            <div className='col-md-1' style={{ marginTop: '33px'}}>
                                                                                <button type='submit' className='btn btn-primary' id={`updatePostedPositionsOnDr_${index}`} onClick={() => handlePostRequestOnDeploymentRoster(index)}>Search</button>
                                                                            </div>
                                                                        )}                                                                        
                                                                    </div>
                                                                    {(parseInt(section.postedPositionOnDeploymentRoster) === postedPositionOnDrNotApplicable || 
                                                                     (parseInt(section.postedPositionOnDeploymentRoster) === postedPositionOnDrYes && section.deployeeEmailId !== '')) && (
                                                                    <>
                                                                        <div className='row'>
                                                                            <div className="form-group mb-3 col-md-3">
                                                                                <label htmlFor={`deployeeEmailId_${index}`} className='form-label'>
                                                                                    <strong>Deployee EmailId <span style={{ color: "red", font: "bold" }}>*</span></strong> 
                                                                                </label>
                                                                                <input type="text" className="form-control" id={`deployeeEmailId_${index}`} placeholder="Deployee Email" value={section.deployeeEmailId} onChange={(e) => {handleChange(index, 'deployeeEmailId', e.target.value);handleChange(index, 'deployeeFName', '');handleChange(index, 'deployeeLName', '');}} onBlur={(e)=>handleBlur(index, 'deployeeEmailId', e.target.value)}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                      handleBlur(index, 'deployeeEmailId', e.target.value);
                                                                                    } }}/>
                                                                                <p style={{color: 'darkorange', fontSize: '0.8rem'}}>Search Deployment Roster Profile by Email</p>
                                                                                {errors[index]?.deployeeEmailId && <p style={{ color: 'red' }}>{errors[index].deployeeEmailId}</p>}
                                                                            </div>
                                                                            <div className="form-group mb-3 col-md-3">
                                                                                <label htmlFor={`deployeeFName_${index}`} className='form-label'>
                                                                                    <strong>Deployee First Name <span style={{ color: "red", font: "bold" }}>*</span></strong> 
                                                                                </label>
                                                                                <input type="text" className="form-control" id={`deployeeFName_${index}`} placeholder="Deployee First Name" value={section.deployeeFName} onChange={(e) => handleChange(index, 'deployeeFName', e.target.value)}/>
                                                                                {errors[index]?.deployeeFName && <p style={{ color: 'red' }}>{errors[index].deployeeFName}</p>}
                                                                            </div>
                                                                            <div className="form-group mb-3 col-md-3">
                                                                                <label htmlFor={`deployeeLName_${index}`} className='form-label'>
                                                                                    <strong>Deployee Last Name <span style={{ color: "red", font: "bold" }}>*</span></strong> 
                                                                                </label>
                                                                                <input type="text" className="form-control" id={`deployeeLName_${index}`} placeholder="Deployee Last Name" value={section.deployeeLName} onChange={(e) => handleChange(index, 'deployeeLName', e.target.value)}/>
                                                                                {errors[index]?.deployeeLName && <p style={{ color: 'red' }}>{errors[index].deployeeLName}</p>}
                                                                            </div>
                                                                            <div className='col-md-1' style={{ marginTop: '33px'}}>
                                                                                <button type='submit' className='btn btn-primary' id={`updateDeployeeInfo_${index}`} onClick={() => handleUpdateDeployeeInformation(index)}>Update</button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    )}
                                                                     <button type='button' className='btn btn-default' style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={() => toggleEditMode(index)}>Cancel</button>
                                                                </>
                                                                )}                                                                
                                                            </fieldset>
                                                            </div>
                                                        </div>                                                                                                    
                                                    </div>                                                
                                                ))}   
                                            </>)} 
                                            {isESFM && (userInfo.isAdmin || userHasProvideWBS) && (requestStatus === 'In Progress' || requestStatus === 'Completed') && (
                                            <>
                                                <div className='responseevents'>
                                                    <div className='section-header'>
                                                        <span>
                                                            Approved Ceiling
                                                        </span>
                                                        {requestStatus === 'In Progress' && (<button className="btn" style={{float:'right', color:'white', fontWeight: 'bold'}} onClick={handleAddCeiling}>Add Ceiling</button>)}
                                                    </div>
                                                </div>
                                                {addCeiling && (
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-3">
                                                        <label htmlFor="wbsCode" className="form-label"><strong>WBS <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                        <select id="wbsCode" name='selectedWbsCode' className="form-select" value={newRow.selectedWbsCode} onChange={handleDynamicRowChange}>
                                                                <option value="">--Select--</option>
                                                                {allWbs.map((res) => (
                                                                    <option key={res.wbsDetailId} value={res.wbsDetailId}>{res.wbsCode}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-5">
                                                        <label htmlFor="wbsDescription" className="form-label"><strong>Description <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                        <input type="text" name='wbsDescription' className="form-control" id="wbsDescription" placeholder="Description" value={newRow.wbsDescription} disabled />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-2">
                                                        <label htmlFor="wbsAmount" className="form-label"><strong>Amount <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                        <input type="text" name='wbsAmount' className="form-control" id="wbsAmount" placeholder="Amount" value={newRow.wbsAmount} onChange={handleDynamicRowChange} />
                                                    </div>
                                                    <div className="form-group col-md-2 d-flex align-items-center" style={{ marginTop: '25px' }}>
                                                        <button type="button" className="btn btn-primary mb-2 me-2" onClick={handleAddRow}>Add Row</button>
                                                    </div> 
                                                </div>
                                                )}
                                                {rows.length > 0 && (
                                                <>
                                                <div id='approvedCeilingDetails' className="table-responsive" style={{fontSize:"0.9rem"}}>
                                                    <table className="table table-bordered table-striped">
                                                        <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                            <tr>
                                                                <th style={{width:"25%", background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>WBS</th>
                                                                <th style={{width:"45%", background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Description</th>
                                                                <th style={{width:"15%", background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Amount</th>
                                                                {requestStatus !== 'Completed' && (<th style={{width:"15%", background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {rows.map((row) => (
                                                            <tr key={row.id}>
                                                            <td>{row.wbsCode}</td>
                                                            <td>{row.wbsDescription}</td>
                                                            <td style={{textAlign:'right'}}>$ {row.wbsAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            {requestStatus !== 'Completed' && (
                                                                <>
                                                                    <td style={{textAlign:'center'}}>
                                                                        <button type="button" className="btn mb-2" onClick={() => handleRemoveRow(row.id)} style={{background:"none", border: "none", color: "red"}}><i className="bi bi-trash"></i></button>
                                                                        <button type="button" className="btn mb-2" onClick={() => handleUpdateRow(row.id)} style={{background:"none", border: "none", color: "black"}}><i className="bi bi-pencil" style={{color:'#ffc107'}}></i></button>
                                                                    </td>
                                                                </>)}
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td></td>
                                                            <td style={{textAlign:'right'}}><strong>Total</strong></td>
                                                            <td style={{textAlign:'right'}}>
                                                                <strong>
                                                                 $ {rows.reduce((sum, row) => sum + parseFloat(row.wbsAmount || 0), 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </strong>
                                                            </td>
                                                            {requestStatus !== 'Completed' && (<td></td>)}
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {updateCeiling && (<button type="button" style={{float:'right'}} className="btn btn-primary mb-2 me-2" onClick={handlePostRows}>Update Ceiling</button>)}
                                                </>
                                                )}
                                                <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)} centered>
                                                    <Modal.Header>
                                                        <Modal.Title className="text-center w-100">Confirm Deletion</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body className="modal-body-scrollable">
                                                        <p>Are you sure you want to delete this row?</p>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button type='button' className="btn btn-secondary me-2" onClick={() => setShowDeleteConfirm(false)}>
                                                            Cancel
                                                        </button>
                                                        <button type='button' className="btn btn-danger" onClick={handleConfirmDeleteRow}>
                                                            Delete
                                                        </button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>   
                                            )}
                                            {(userInfo.isAdmin || userHasApproveESFMRequest || userHasReviewESFMRequest) &&(
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-12">
                                                        {submitApprovalButtonVisibilityStatus.includes(requestStatus) && userHasReviewESFMRequest && (
                                                            <button type="submit" id="btnSubmitApproval" className="btn btn-primary me-2 mb-2" onClick={handleSubmitForApprove}>Submit for Approval</button>
                                                        )}                                                    
                                                        {approveButtonVisibilityStatus.includes(requestStatus) && approveButtonEnable && userHasApproveESFMRequest && (
                                                            <button type="submit" id="btnApprove" className="btn btn-primary me-2 mb-2" onClick={handleApprove}>Approve</button>
                                                        )}
                                                        {rejectButtonVisibilityStatus.includes(requestStatus) && userHasApproveESFMRequest && (
                                                            <button type="submit" id="btnReject" className="btn btn-danger me-2 mb-2" onClick={handleReject}>Reject</button>
                                                        )}
                                                        {requestStatus === 'Rejected' && userHasApproveESFMRequest && (
                                                            <button type="submit" id="btnReopen" className="btn btn-primary me-2 mb-2" onClick={handleReopen}>Re Open</button>
                                                        )}
                                                        {revertButtonVisibilityStatus.includes(requestStatus) && (userHasApproveESFMRequest || userHasReviewESFMRequest) && (
                                                            <button type="submit" id="btnRevert" className="btn btn-secondary me-2 mb-2" onClick={handleRevert}>Revert</button>
                                                        )}         
                                                        {requestStatus === 'Reverted' && (
                                                            <>
                                                                <button type="submit" id="btnEdit" className="btn btn-secondary me-2 mb-2" onClick={handleReopen}>Edit</button>
                                                                <button type="submit" id="btnReSubmit" className="btn btn-primary me-2 mb-2" onClick={handleReSubmit}>Re Submit</button>
                                                            </>
                                                        )}                                                                                           
                                                    </div>
                                                </div>
                                            )}                                        
                                        </div>
                                    </div>   
                                )}
                                {showHistory && (
                                    <div className='card mb-3'>
                                        <div className='card-header'>
                                            <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Approved' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                            {expenseRecordStatus === 'Approved' && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowFinalReport}>Final Report</button>)}
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowRemarks}>Remarks</button>
                                            {(requestStatus === 'In Progress' || requestStatus === 'Completed') && rows.length > 0 && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleExpenses}>Expenses</button>)}
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleBackToDetails}>Request Details</button>
                                        </div>
                                        <div className='card-body'>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>
                                                    Request Histories
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                                {historyData && historyData.length > 0 ? (
                                                    <table className="table table-bordered table-striped">
                                                        <thead className='thead-light' style={{background:"ghostwhite"}}>
                                                            <tr>
                                                            <th style={{width:'10%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>User Name</th>
                                                            <th style={{width:'20%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action Date</th>
                                                            <th style={{width:'70%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action Performed</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {historyData.length > 0 ? (
                                                                historyData.map((item, index) => (
                                                                    <tr key={index}>
                                                                    <td>{item.createdBy}</td>                                                                
                                                                    <td>{formatDateToUTC(item.actionDate)}</td>   
                                                                    <td>{item.actionDescription}</td>                                                             
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">No history available.</td>
                                                            </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No history available.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {showRemarks && (
                                    <div className='card mb-3'>
                                        <div className='card-header'>
                                            <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Approved' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                            {expenseRecordStatus === 'Approved' && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowFinalReport}>Final Report</button>)}
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowHistory}>History</button>
                                            {(requestStatus === 'In Progress' || requestStatus === 'Completed') && rows.length > 0 && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleExpenses}>Expenses</button>)}
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleBackToDetails}>Request Details</button>
                                        </div>
                                        <div className='card-body'>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>
                                                    Request Remarks
                                                    </span>
                                                    <button className="btn" style={{float:'right', color:'white'}} onClick={handleAddRemarkModel}>Add Remark</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                                {remarksData && remarksData.length > 0 ? (
                                                    <table className="table table-bordered table-striped">
                                                        <thead className='thead-light' style={{background:"ghostwhite"}}>
                                                            <tr>
                                                                <th style={{width:'10%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>User Name</th>                                                                                                            
                                                                <th style={{width:'20%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Remark Date</th>
                                                                <th style={{width:'70%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Remark</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {remarksData.length > 0 ? (
                                                            remarksData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.createdBy}</td>
                                                                    <td>{formatDateToUTC(item.remarkDate)}</td>
                                                                    <td>{item.remark}</td>                                                                    
                                                                </tr>
                                                            ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">No Remarks available.</td>
                                                            </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No Remarks available.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}   
                                {showExpenses && (
                                <div className='card mb-3'>
                                    <div className='card-header'>
                                        <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Approved' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                        {expenseRecordStatus === 'Approved' && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowFinalReport}>Final Report</button>)}
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowHistory}>History</button>
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowRemarks}>Remarks</button>
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleBackToDetails}>Request Details</button>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                            <div className='section-header'>
                                                <span>
                                                Expenses Details having Status: <b style={{color:'orange'}}>{expenseRecordStatus}</b>
                                                </span>
                                                {(userInfo.isAdmin || userHasDraftExpense) && submitExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && (
                                                    <button className="btn" style={{float:'right', color:'white', fontWeight: 'bold'}} onClick={AddExpense}>Add Expense</button>
                                                )}
                                            </div>
                                        </div>
                                        {addExpenseSection && (
                                            <>
                                                <div className='row mb-3'>
                                                    <div className="col-md-4">
                                                        <select id="addexpense_position" className="form-select" value={expenseSelectedPosition} onChange={(e) => setExpenseSelectedPosition(e.target.value)}>
                                                            <option value="">Select Position</option>
                                                                {allExpensesPositions.map((res) => (
                                                            <option key={res.expensePositionId} value={res.expensePositionId}>{res.expensePositionDescription}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <select id="addexpense_wbs" className="form-select" value={expenseSelectedWbs} onChange={(e) => setExpenseSelectedWbs(e.target.value)}>
                                                            <option value="">Select WBS</option>
                                                            {allExpensesWbs.map((res) => (
                                                            <option key={res.wbsDetailId} value={res.wbsDetailId}>{res.wbsCode}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <select id="addexpense_expensetype" className="form-select" value={expenseSelectedType} onChange={(e) => setExpenseSelectedType(e.target.value)}>
                                                            <option value="">Select Expense Type</option>
                                                                {allExpensesType.map((res) => (
                                                            <option key={res.expenseTypeId} value={res.expenseTypeId}>{res.expenseTypeDescription}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='row mb-3'>
                                                    <div className="col-md-4">
                                                        <input type="text" className="form-control" id="addexpense_cost" placeholder="$ Expenses" value={expenseCost}  onChange={(e) => setExpenseCost(e.target.value)}/>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="text" className="form-control" id="addexpense_expensedescription" placeholder="Expense Description" value={expenseDescription} onChange={(e) => setExpenseDescription(e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-12 offset-md-12">
                                                        <button className='btn btn-primary' style={{float:'right'}} onClick={handleUpdateExpenses}>Update Expenses</button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {expensesData.length > 0 && (
                                        <>
                                            <div id='expenseTableRecord' className="table-responsive" style={{ fontSize: "0.9rem", border: '1px solid', padding: '25px', marginTop: '15px' }}>    
                                                {Array.from(new Set(expensesData.map(exp => exp.position)))
                                                    .filter(position => position !== "Request Level Expenses")
                                                    .map((position, index) => {
                                                        const totalAmount = expensesData
                                                            .filter(exp => exp.position === position)
                                                            .reduce((sum, exp) => sum + exp.amount, 0);
                                                        return (
                                                            <div key={index}>
                                                                <div className='responseevents'>
                                                                    <div className='section-header'>
                                                                        <span>
                                                                            Position {index + 1}: {position}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Expenses Type</th>
                                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>WBS</th>
                                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Description</th>
                                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Amount</th>
                                                                            {(userInfo.isAdmin || userHasDraftExpense) && submitExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && (
                                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                                            )}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {expensesData.filter(exp => exp.position === position).map((expense, idx) => (
                                                                            <tr key={idx} style={{verticalAlign:'middle'}}>
                                                                                <td>{expense.expenseType}</td>
                                                                                <td>{expense.wbs}</td>
                                                                                <td>{expense.description}</td>
                                                                                <td style={{textAlign: 'right'}}>${expense.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                {(userInfo.isAdmin || userHasDraftExpense) && submitExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && (
                                                                                <td style={{textAlign: 'center'}}><button className="btn btn-sm" onClick={() => handleDeleteExpense(expense, idx)}>
                                                                                <i className="bi bi-trash" style={{color:'red'}}></i>
                                                                                </button></td>
                                                                                )}
                                                                            </tr>
                                                                        ))}
                                                                        <tr style={{verticalAlign:'middle'}}>
                                                                            <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                                                                            <td style={{ fontWeight: 'bold', textAlign:'right' }}>${totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        );
                                                    })}                                        
                                                {expensesData.some(exp => exp.position === "Request Level Expenses") && (
                                                    <div>
                                                        <div className='responseevents'>
                                                            <div className='section-header'>
                                                                <span>Request Level Expenses</span>
                                                            </div>
                                                        </div>
                                                        <table className="table table-bordered">
                                                            <thead className='thead-light'>
                                                                <tr>
                                                                    <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Expenses Type</th>
                                                                    <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>WBS</th>
                                                                    <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Description</th>
                                                                    <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Amount</th>
                                                                    {(userInfo.isAdmin || userHasDraftExpense) && submitExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && (
                                                                    <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expensesData
                                                                    .filter(exp => exp.position === "Request Level Expenses")
                                                                    .map((expense, idx) => (
                                                                        <tr key={idx} style={{verticalAlign: 'middle'}}>
                                                                            <td>{expense.expenseType}</td>
                                                                            <td>{expense.wbs}</td>
                                                                            <td>{expense.description}</td>
                                                                            <td style={{textAlign: 'right'}}>${expense.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                            {(userInfo.isAdmin || userHasDraftExpense) && submitExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && (
                                                                            <td style={{textAlign: 'center'}}><button className="btn btn-sm" onClick={() => handleDeleteExpense(expense, idx)}>
                                                                            <i className="bi bi-trash" style={{color:'red'}}></i>
                                                                            </button></td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                <tr style={{verticalAlign:'middle'}}>
                                                                    <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                                                                    <td style={{ fontWeight: 'bold', textAlign:'right' }}>${expensesData
                                                                        .filter(exp => exp.position === "Request Level Expenses")
                                                                        .reduce((sum, exp) => sum + exp.amount, 0)
                                                                        .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}                                        
                                                <div className='responseevents'>
                                                    <div className='section-header'>
                                                        <span>Approved Ceiling & Expenditure</span>
                                                    </div>
                                                </div>
                                                <table className="table table-bordered">
                                                    <thead className='thead-light'>
                                                        <tr>
                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>WBS</th>
                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Approved Ceiling</th>
                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Expenditure</th>
                                                            <th style={{background:'#adaab0', textAlign: 'center', verticalAlign: 'middle'}}>Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allExpensesWbs.map((wbsItem, idx) => {
                                                            const expenditure = expensesData
                                                                .filter(exp => exp.wbs === wbsItem.wbsCode)
                                                                .reduce((sum, exp) => sum + exp.amount, 0);
                                                            const balance = wbsItem.wbsAmount - expenditure;
                                                            return (
                                                                <tr key={idx} style={{verticalAlign:'middle'}}>
                                                                    <td>{wbsItem.wbsCode}</td>
                                                                    <td style={{ textAlign:'right' }}>${wbsItem.wbsAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                    <td style={{ textAlign:'right' }}>${expenditure.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                    <td style={{ textAlign:'right' }}>${balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                        <tr style={{verticalAlign:'middle'}}>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>${allExpensesWbs.reduce((sum, wbs) => sum + wbs.wbsAmount, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            <td style={{ textAlign: 'right',fontWeight: 'bold' }}>${expensesData.reduce((sum, exp) => sum + exp.amount, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>${allExpensesWbs.reduce((totalBalance, wbsItem) => {
                                                                    const expenditure = expensesData
                                                                        .filter(exp => exp.wbs === wbsItem.wbsCode)
                                                                        .reduce((sum, exp) => sum + exp.amount, 0);
                                                                    return totalBalance + (wbsItem.wbsAmount - expenditure);
                                                                }, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {(userInfo.isAdmin || userHasSubmitExpense || userHasApproveExpense) &&(
                                            <div className='row' style={{marginTop:'10px', textAlign:'right'}}>
                                                <div className='col-md-12 mb-2'>
                                                    {submitExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && userHasSubmitExpense && (
                                                    <button type="submit" id='btnSubmitExpense' className='btn btn-primary me-2 mb-2' onClick={handleSubmitExpenses}>Submit</button>
                                                    )}
                                                    {approveExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && userHasApproveExpense && (
                                                    <button type="submit" id="btnExpenseApprove" className="btn btn-primary me-2 mb-2" onClick={handleApproveExpenses}>Approve</button>
                                                    )} 
                                                    {revertExpenseButtonVisibilityStatus.includes(expenseRecordStatus) && requestStatus !== 'Completed' &&(
                                                    <button type="submit" id='btnRevertExpense' className='btn btn-secondary me-2 mb-2' onClick={handleRevertExpenses}>Revert</button>
                                                    )}
                                                </div>
                                            </div>
                                            )}
                                        </>                                    
                                        )}                                        
                                    </div>
                                </div>
                                )}  
                                {showFinalReport && (
                                    <div className='card mb-3'>
                                        <div className='card-header'>
                                            <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Approved' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                            {(requestStatus === 'In Progress' || requestStatus === 'Completed') && rows.length > 0 && (<button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleExpenses}>Expenses</button>)}
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowHistory}>History</button>
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowRemarks}>Remarks</button>
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleBackToDetails}>Request Details</button>
                                        </div>
                                        <div id='finalReportSection' className='card-body'>
                                        {isESFM && (
                                            <>
                                                <div className='responseevents'>
                                                    <div className='section-header'>
                                                        <span>
                                                        ESFM - MISSION REPORT
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                                    <table className="table table-bordered table-striped">
                                                        <thead className='thead-light' style={{background:"ghostwhite"}}>
                                                            <tr>
                                                                <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Deployee Name</th>
                                                                <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Mission</th>
                                                                <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Position Title</th>
                                                                <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Start Date</th>
                                                                <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>End Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sections.map((section, index) => (
                                                                <tr key={index}>
                                                                    <td>{`${section.deployeeFName} ${section.deployeeLName}`}</td>
                                                                    <td>{section.dutyStation}</td>
                                                                    <td>{section.postionHeader}</td>
                                                                    <td>{section.actualStartDate !== null ? formatDate(section.actualStartDate) : null}</td>
                                                                    <td>{section.actualEndDate !== null ? formatDate(section.actualEndDate) : null}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='responseevents'>
                                                    <div className='section-header'>
                                                        <span>Approved Ceiling & Expenditure</span>
                                                    </div>
                                                </div>
                                                <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                                    <table className="table table-bordered table-striped">
                                                        <thead className='thead-light' style={{background:"ghostwhite"}}>
                                                            <tr>
                                                                <th style={{background:'#7a9fd9', textAlign: 'center', verticalAlign: 'middle'}}>WBS</th>
                                                                <th style={{background:'#7a9fd9', textAlign: 'center', verticalAlign: 'middle'}}>Approved Ceiling</th>
                                                                <th style={{background:'#7a9fd9', textAlign: 'center', verticalAlign: 'middle'}}>Expenditure</th>
                                                                <th style={{background:'#7a9fd9', textAlign: 'center', verticalAlign: 'middle'}}>Balance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allExpensesWbs.map((wbsItem, idx) => {
                                                                const expenditure = expensesData
                                                                    .filter(exp => exp.wbs === wbsItem.wbsCode)
                                                                    .reduce((sum, exp) => sum + exp.amount, 0);
                                                                const balance = wbsItem.wbsAmount - expenditure;
                                                                return (
                                                                    <tr key={idx} style={{verticalAlign:'middle'}}>
                                                                        <td>{wbsItem.wbsCode}</td>
                                                                        <td style={{ textAlign:'right' }}>${wbsItem.wbsAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                        <td style={{ textAlign:'right' }}>${expenditure.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                        <td style={{ textAlign:'right' }}>${balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            <tr style={{verticalAlign:'middle'}}>
                                                                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                                                                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>${allExpensesWbs.reduce((sum, wbs) => sum + wbs.wbsAmount, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                <td style={{ textAlign: 'right',fontWeight: 'bold' }}>${expensesData.reduce((sum, exp) => sum + exp.amount, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>${allExpensesWbs.reduce((totalBalance, wbsItem) => {
                                                                        const expenditure = expensesData
                                                                            .filter(exp => exp.wbs === wbsItem.wbsCode)
                                                                            .reduce((sum, exp) => sum + exp.amount, 0);
                                                                        return totalBalance + (wbsItem.wbsAmount - expenditure);
                                                                    }, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='responseevents'>
                                                    <div className='section-header'>
                                                        <span>Achievements, Challenges, Recommendations</span>
                                                    </div>
                                                </div>
                                                <p style={{color:'gray'}}>The surge support will enable the mission to support government with leadership and coordination of strategic and technical components of the emergency response through the following: (1) Overall emergency coordination.</p>
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-12">
                                                        <label htmlFor='txtAchievements' className='form-label'><strong>Main Achievements and Deliverables <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                        <textarea 
                                                            className='form-control col-md-12'
                                                            id='txtAchievements'
                                                            value={reportAchievements}
                                                            onChange={(e) => setReportAchievements(e.target.value)}
                                                            rows="4"
                                                            cols="50"
                                                            placeholder="Enter Main Achievements and Deliverables"
                                                            disabled = {requestStatus === 'Completed'}
                                                        />                                                                    
                                                    </div>
                                                    <div className="form-group mb-3 col-md-12">
                                                        <label htmlFor='txtRecommendations' className='form-label'><strong>Follow Up Actions & Recommendations <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                        <textarea 
                                                            className='form-control col-md-12'
                                                            id='txtRecommendations'
                                                            value={reportRecommendations}
                                                            onChange={(e) => setReportRecommendations(e.target.value)}
                                                            rows="4"
                                                            cols="50"
                                                            placeholder="Enter Follow Up Actions & Recommendations"
                                                            disabled = {requestStatus === 'Completed'}
                                                        />                                                                    
                                                    </div>
                                                    <div className="form-group mb-3 col-md-12">
                                                        <label htmlFor='txtChallenges' className='form-label'><strong>Challenges & Lessons Learnt <span style={{color:"red", font:"bold"}}>*</span></strong></label>
                                                        <textarea 
                                                            className='form-control col-md-12'
                                                            id='txtChallenges'
                                                            value={reportChallenges}
                                                            onChange={(e) => setReportChallenges(e.target.value)}
                                                            rows="4"
                                                            cols="50"
                                                            placeholder="Enter Challenges & Lessons Learnt"
                                                            disabled = {requestStatus === 'Completed'}
                                                        />                                                                    
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="form-group col-md-12">
                                                    {requestStatus === 'In Progress' && (<button type="submit" className="btn btn-primary mb-2 me-2" style={{float:'right'}} onClick={handleSubmitFinalReport}>Submit</button>)}
                                                    {requestStatus === 'Completed' && (<button id='generatePdfButton' type="submit" className="btn btn-primary mb-2 me-2" style={{float:'right'}} onClick={generatePDFFinalReport}>Generate PDF</button>)}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        </div>
                                    </div>
                                )}                    
                            </div>
                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center w-100">{modalTitle}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <p>{modalBody}</p>
                                    {(buttonText !== 'Approve' && buttonText !== "Submit for Approval" && buttonText !== "Save" && buttonText !== "Update") && (<textarea
                                        className="form-control"
                                        rows="3"
                                        value={reason}
                                        onChange={handleReasonChange}
                                        placeholder="Enter your reason here..."
                                    ></textarea>)}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-secondary me-2" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button type='button' className="btn btn-primary" onClick={handleModalConfirm}>
                                        {buttonText}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showRemarkModal} onHide={handleCloseRemarkModal} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center w-100">Add Remark</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        value={remarkPopupText}
                                        onChange={handleRemarkPopupText}
                                        placeholder="Enter remark here..."
                                    ></textarea>
                                    {remarkError && (
                                        <div className="text-danger mt-2">
                                            {remarkError}
                                        </div>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-primary" onClick={handleModalConfirmRemark}>
                                        Save
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            {selectedSection && (
                                <Modal show={showPopup} onHide={handleClosePopup} className='positions-modal'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Details for Position: {selectedSection.selectedPosition}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="text-start">
                                            <div className="form-group mb-3">
                                                <strong>Supervisor Name:</strong> {selectedSection.supervisorName}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Supervisor Position Title:</strong> {selectedSection.supervisorPosition}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Supervisor Email:</strong> {selectedSection.supervisorEmail}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Terms of Reference:</strong> {selectedSection.termsofReference}
                                            </div>
                                                {selectedSection.torDocumentPath && selectedSection.uploadedDocumentName && (
                                                    <div className="form-group mb-3">
                                                        <strong>Uploaded Documents: </strong> 
                                                        <a style={{textDecoration:'auto'}}>{selectedSection.uploadedDocumentName}</a>
                                                        <button className='btn btn-sm me-4' style={{float:'right'}} onClick={() => handleDownloadDocument(selectedSection.uploadedDocumentName, selectedSection.torDocumentPath)}><i class="bi bi-download" style={{color:'#0000ff'}}></i></button>
                                                    </div>
                                                )}
                                            <div className="form-group mb-3">
                                                <strong>Please explain why this surge deployment is urgent, why current IOM Mission capacity can not meet this need and what are the long term plans of the mission (beyond surge deployment period)?: </strong>{selectedSection.requestJustification}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Area of Responsibility Requested: </strong>{selectedSection.responsibilitiesText}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Do you need support with identifying surge deployment profile?: </strong>{selectedSection.supportIdentifyProfile}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            )}
                            <Modal show={showDeleteExpensePopup} onHide={handleCloseDeleteExpensePopup} centered>
                                <Modal.Header>
                                    <Modal.Title className="text-center w-100">Confirm Deletion</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <p>Are you sure you want to delete this Expense Record?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-secondary me-2"  onClick={handleCloseDeleteExpensePopup}>Cancel</button>
                                    <button type='button' className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewDeploymentRequest;