import { config } from "../constants/constant";
import axiosInstance from "../constants/globalaxiosinterceptor";
export const adminAPI = config.apiUrl + "/api/DeploymentRequest";

let deploymentRequestsConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
    postHeaders: {
        'Content-Type': 'multipart/form-data'
      },
}

export async function SaveDraftESFMRequest(data) {
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_DRAFTREQUEST;
    return await axiosInstance.post(adminAPI + '/savedraftrequest', data,{headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function SubmitESFMRequest(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_SUBMITREQUEST;
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    return await axiosInstance.post(adminAPI + '/submitrequest', data,{headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllRequestsList() {
    return await axiosInstance.get(adminAPI + '/allrequests',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetRequestDetailsById(id) {
    deploymentRequestsConfig.headers.requestId = id;
    return await axiosInstance.get(adminAPI + '/requestDetailsById',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function ReviewESFMRequest(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_REVIEWREQUESTESFM;
    return await axiosInstance.post(adminAPI + '/reviewesfmrequest', data, {headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });  
}

export async function ApproveESFMRequest(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_APPROVEREQUESTESFM;
    return await axiosInstance.post(adminAPI + '/approveesfmrequest', data, {headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllHistories(requestId){
    deploymentRequestsConfig.headers.requestId = requestId;
    return await axiosInstance.get(adminAPI+'/requestHistory', {headers:deploymentRequestsConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllRemarks(requestId){
    deploymentRequestsConfig.headers.requestId = requestId;
    return await axiosInstance.get(adminAPI+'/requestRemarks', {headers:deploymentRequestsConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function DeleteDeploymentRequest(requestId){
    deploymentRequestsConfig.headers.requestId = requestId;
    return await axiosInstance.delete(adminAPI+'/deleteRequest', {headers:deploymentRequestsConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function DownloadUploadedDocument(documentName, documentPath) {
    try {
        deploymentRequestsConfig.headers.documentName = documentName;
        deploymentRequestsConfig.headers.documentPath = documentPath;

        const response = await axiosInstance.get(adminAPI + '/downloaddocument', {
            headers: deploymentRequestsConfig.headers,
            responseType: 'arraybuffer',
        });
        if (response.status === 200) {
            return response;
        } else {
            return { success: false, message: 'Failed to download document. Status: ' + response.status };
        }
    } catch (error) {
        return { success: false, message: error.message || 'An error occurred while downloading the document.' };
    }
}

export async function AddRemarkDetails(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    deploymentRequestsConfig.headers.email = userInfo.email;
    return await axiosInstance.post(adminAPI + '/addremarkdetails', data, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function PostRequestOnDeploymentRoster(requestData) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_POSTREQUESTONDR;
    return await axiosInstance.post(adminAPI + '/postrequestondeploymentroster', requestData, {headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetDeployeeDetailsByEmail(email) {
    deploymentRequestsConfig.headers.deployeeEmailId = email;
    return await axiosInstance.get(adminAPI+'/deployeeDetailsByEmail', {headers:deploymentRequestsConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function PostDeployeeUserDetails(deployeeUser) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_POSTREQUESTONDR;
    return await axiosInstance.post(adminAPI + '/postdeployeeinformation', deployeeUser, {headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function UpdateCCUsersDetails(data) {
    return await axiosInstance.post(adminAPI + '/updateCcUsersEmailid', data, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function UpdateWBSAndCieling(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_PROVIDEWBS;
    return await axiosInstance.post(adminAPI + '/updateWbsAndCieling', data, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllExpensesPositions(id) {
    deploymentRequestsConfig.headers.requestId = id;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_DRAFTEXPENSES;
    return await axiosInstance.get(adminAPI + '/expensePositionsById',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllExpensesWbs(id) {
    deploymentRequestsConfig.headers.requestId = id;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_DRAFTEXPENSES;
    return await axiosInstance.get(adminAPI + '/expensesWbsById',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllExpensesType() {
    return await axiosInstance.get(adminAPI + '/expenseType',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function saveExpenseRecord(expenseData) {
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_DRAFTEXPENSES;
    return await axiosInstance.post(adminAPI + '/draftexpenses', expenseData, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function AllExpensessById(id) {
    deploymentRequestsConfig.headers.requestId = id;
    return await axiosInstance.get(adminAPI + '/allexpenses',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function deleteExpenseRecord(fundingId) {
    deploymentRequestsConfig.headers.deploymentRequestFundingId = fundingId;
    return await axiosInstance.delete(adminAPI + '/deleteexpense',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function submitExpenseRecord(expenseData) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_SUBMITEXPENSES;
    return await axiosInstance.post(adminAPI + '/submitexpenses', expenseData, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function approveExpenseRecord(expenseData) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_APPROVEEXPENSES;
    return await axiosInstance.post(adminAPI + '/approveexpenses', expenseData, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function revertExpensesRecord(expenseData) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    return await axiosInstance.post(adminAPI + '/revertexpenses', expenseData, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function submitFinalReport(reportData) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    return await axiosInstance.post(adminAPI + '/submitreport', reportData, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function updatePositionActualFromToDates(positionData) {
    return await axiosInstance.post(adminAPI + '/updatepositionactualfromtodates', positionData, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function deleteCeilingRecord(wbsDetailId, deploymentRequestId) {
    deploymentRequestsConfig.headers.wbsDetailId = wbsDetailId;
    deploymentRequestsConfig.headers.deploymentRequestId = deploymentRequestId;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_PROVIDEWBS;
    return await axiosInstance.delete(adminAPI + '/deleteceilingrecord',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function SaveDraftSBPRequest(data) {
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_DRAFTREQUEST;
    return await axiosInstance.post(adminAPI + '/savedraftsbprequest', data,{headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function SubmitSBPRequest(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_SUBMITREQUEST;
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    return await axiosInstance.post(adminAPI + '/submitsbprequest', data,{headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetSbpRequestDetailsById(id) {
    deploymentRequestsConfig.headers.requestId = id;
    return await axiosInstance.get(adminAPI + '/sbpRequestDetailsById',{headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function ApproveSBPRequest(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.postHeaders.userName = userInfo.userName;
    deploymentRequestsConfig.postHeaders.roleName = process.env.REACT_APP_ROLENAME_APPROVEREQUESTSBP;
    return await axiosInstance.post(adminAPI + '/approvesbprequest', data, {headers:deploymentRequestsConfig.postHeaders}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function HandleSendToSbpPartners(data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    deploymentRequestsConfig.headers.userName = userInfo.userName;
    deploymentRequestsConfig.headers.roleName = process.env.REACT_APP_ROLENAME_SENDTOSBPAGENCIES;
    return await axiosInstance.post(adminAPI + '/sendtosbppartners', data, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function UpdateSbpPartnersDetais(data) {
    return await axiosInstance.put(adminAPI + '/updatesbppartners', data, {headers:deploymentRequestsConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}