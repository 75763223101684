import React, { useState, useEffect,useRef } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { GetAllUserDetails, UpdateUserDetails } from '../../services/adminServices.js';
import { useModal } from '../modalPopup/modelcontext.js';
import './administrator.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SearchableDropdown from '../login_signup/searchableDropdown.js';
import Select from 'react-select';
import { CustomOption,CustomSingleValue } from './customOption.js';
import { Modal } from 'react-bootstrap';
import { GetUserDetailsOffices, GetTeamDetailsOffices, GetAllRoles, GetAllTeams } from '../../services/dropdownServices.js';
import { getToken, userRoles } from '../../services/loginService.js';
import { useAuth } from '../../services/authContext.js';
import GroupCheckboxList from './GroupCheckboxList.js';

const UserDetails = () => {
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
    const { logout } = useAuth();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userAccountType, setUserAccountType] = useState('');
    const [userJobTitle,setUserJobTitle] = useState('');
    const [selectedUserOffice, setSelectedUserOffice] = useState({officeId : 0, officeDescription : ''});
    const [offices, setOffices] = useState([{ officeId: 0, officeDescription: '' }]);
    const [selectedUserTeam, setSelectedUserTeam] = useState({ teamId: '', teamName: '' });
    const [isActive,setIsActive] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAccountLocked, setIsAccountLock] = useState(false);
    const [selectedUserRoles, setSelectedUserRoles] = useState([]);
    const [deletedUserRoles, setDeletedUserRoles] = useState([]);
    const [insertedUserRoles, setInsertedUserRoles] = useState([]);
    const [allTeams,setAllTeams] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [allOffices, setAllOffices] = useState([]);
    const [userdetailsAllOffices, setUserdetailsAllOffices] = useState([]);
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [usersList, setUsersList] = useState([]);
    const [userLoginId, setUserLoginId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState(''); 
    const formRef = useRef(null);
    const [show, setShow] = useState(false);
    const [modalSelectedEvent, setModalSelectedEvent] = useState(null);
    const [selectedField, setSelectedField] = useState('');
    //Filter variables 
    const [searchSelectedTeam, setSearchSelectedTeam] = useState(null);
    const [searchSelectedRoles, setSearchSelectedRoles] =  useState([]);
    const [groupedRolesForDropdown, setgroupedRolesForDropdown] = useState([]);
    const [searchSelectedOffice, setSearchSelectedOffice] = useState(null);
    const [searchIsAdmin, setSearchIsAdmin] = useState(false);
    const [searchIsAccountLock,setSearchIsAccountLock] = useState(false);
    const [searchIsActive,setSearchIsActive] = useState(false);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);

    const handleCancelClick = () => {
        setIsFormVisible(false);
        setErrors({});
        setUserName(null);
        setUserEmail(null);
        setUserAccountType(null);
        setUserJobTitle(null);
        setSelectedUserOffice({officeId: '', officeDescription: ''});
        setOffices([{ officeId: '', officeDescription: '' }]);
        setSelectedUserTeam({ teamId: '', teamName: '' });
        setIsActive(false);
        setIsAdmin(false);
        setIsAccountLock(false);
        setSelectedUserRoles(null);
    };

    const handleSeacrhUserClick = () => {
        setIsSearchFormVisible(true);
        setIsFormVisible(false);
        const groupedRoles = allRoles.reduce((acc, role) => {
            let group = acc.find(g => g.label === role.roleGroup);
            if (!group) {
                group = { label: role.roleGroup, groupSort: role.groupSort, options: [] };
                acc.push(group);
            }
            group.options.push({ label: role.roleName, value: role.roleId, sortOrder: role.sortOrder });
            return acc;
        }, []);
        groupedRoles.forEach(group => {
            group.options.sort((a, b) => a.sortOrder - b.sortOrder);
        });
        groupedRoles.sort((a, b) => a.groupSort - b.groupSort);
        setgroupedRolesForDropdown(groupedRoles);
    };

    const handleChange = (selectedOptions) => {
        setSearchSelectedRoles(selectedOptions || []);
    };

    const handleSearchCancelClick = () => {
        setIsSearchFormVisible(false);
        setSearchSelectedTeam(null);
        setSearchIsAccountLock(false);
        setSearchIsActive(false);
        setSearchIsActive(false);
        setSearchSelectedRoles([]);
        setSearchSelectedOffice(null);
    };

    const fetchRoles = async () => {
        try {
            GetAllRoles().then((response) => {
                if (response.status === 200) {
                    setAllRoles(response.data);     
                }       
             });            
        } catch (error) {
            console.error('Error fetching roles: ', error);
        }
    };

    const fetchTeams = async () => {
        try {
            GetAllTeams().then((response) => {
                if (response.status === 200) {
                    setAllTeams(response.data); 
                }       
             });            
        } catch (error) {
            console.error('Error fetching teams: ', error);
        }
    };

    const fetchGeneralOffices = async () => {
        try {
            GetTeamDetailsOffices().then((response) => {
                if (response.status === 200) {
                    setAllOffices(response.data); 
                }       
             });            
        } catch (error) {
            console.error('Error fetching teams: ', error);
        }
    };

    const fetchUserDetailsOffices = async () => {
        try {
            GetUserDetailsOffices().then((response) => {
                if (response.status === 200) {
                    setUserdetailsAllOffices(response.data); 
                }       
             });            
        } catch (error) {
            console.error('Error fetching teams: ', error);
        }
    };

    const fetchUserDetails = async() => {
        try{
            GetAllUserDetails().then((response) =>{
                if(response.status === 200){
                    setUsersList(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching team details:', error);
        }
    };

    useEffect(() => { 
        fetchRoles();
        fetchTeams();
        fetchGeneralOffices();
        fetchUserDetails();
        fetchUserDetailsOffices();
    }, []);

    const groupRolesByGroupName = (roles) => {
        return roles.reduce((acc, role) => {
            if (!acc[role.roleGroup]) {
                acc[role.roleGroup] = {
                    groupSort: role.groupSort,
                    roles: []
                };
            }
            acc[role.roleGroup].roles.push(role);
            return acc;
        }, {});
    };
    
    const sortGroupsAndRoles = (groupedRoles) => {
        // Convert groupedRoles object to an array for sorting
        const sortedGroups = Object.entries(groupedRoles)
            .map(([groupName, groupData]) => ({
                groupName,
                ...groupData
            }))
            .sort((a, b) => a.groupSort - b.groupSort);
    
        // Sort roles within each group by SortOrder
        sortedGroups.forEach(group => {
            group.roles.sort((a, b) => a.sortOrder - b.sortOrder);
        });
    
        return sortedGroups;
    };    
      
    const groupedRoles = groupRolesByGroupName(allRoles);
    const sortedGroupsWithRoles = sortGroupsAndRoles(groupedRoles);

    const userHasApproveUserRequestsRole = hasAccess(["Approve User Requests"]);

    // Filter the groups to exclude "Accounts Admins" if the user has the "Approve User Requests" role
    const filteredGroups = userInfo.isAdmin 
    ? sortedGroupsWithRoles 
    : sortedGroupsWithRoles.filter(group => userHasApproveUserRequestsRole || group.groupName !== "Accounts Admins");
    
    // Function to calculate pagination and filter data
    const filterUserDetailsByFormFields = (userList) => {
        const selectedRolesIds = searchSelectedRoles ? searchSelectedRoles.map(role => parseInt(role.value)) : [];
        return (
            (!searchSelectedTeam || userList.teamId === parseInt(searchSelectedTeam, 10)) &&
            (!searchIsActive || userList.isActive !== searchIsActive) &&
            (!searchIsAdmin || userList.isAdmin === searchIsAdmin) &&
            (!searchIsAccountLock || userList.isAccountLock === searchIsAccountLock) &&
            (!searchSelectedOffice || userList.offices.find(item=>item.officeId ===searchSelectedOffice)) &&
            (selectedRolesIds.length === 0 || userList.roleIds.some(roleId => selectedRolesIds.includes(roleId)))
        );
    };

    const filteredUsersList = usersList.filter((user) =>(
        (user.fullName && user.fullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.accountType && user.accountType.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.jobTitle && user.jobTitle.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.office && user.office.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.roleName && user.roleName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        ((user.isActive ? "yes" : "no").includes(searchQuery.toLowerCase())) ||
        ((user.isAdmin ? "yes" : "no").includes(searchQuery.toLowerCase())) ||
        ((user.isAccountLocked ? "yes" : "no").includes(searchQuery.toLowerCase()))
    ) && filterUserDetailsByFormFields(user)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredUsersList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredUsersList.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredUsersList.length);
    //End Pagination logic here 

    const handleInputChange = (e, setState, fieldName) => {
        setState(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '',}));
    };

    const handlePersonalOfficeChange = (selectedOfficeId) => {
        const selectedOffice = allOffices.find(office => office.officeId === selectedOfficeId);
        if (selectedOffice) {
          setSelectedUserOffice({ officeId: selectedOffice.officeId, officeDescription: selectedOffice.officeDescription });
        } else {
          setSelectedUserOffice({ officeId: '', officeDescription: '' });
        }
        setErrors((prevErrors) => ({ ...prevErrors, selectedUserOffice: '' }));
      };

    const handleOfficeChange = (index, selectedOfficeId) => {
        const selectedOffice = userdetailsAllOffices.find(office => office.officeId === selectedOfficeId);
        const newOffices = [...offices];
        newOffices[index] = selectedOffice;
        setOffices(newOffices);

        const newErrors = { ...errors };
        delete newErrors[`office-${index}`];
        setErrors(newErrors);
    };

    const addOffice = () => {
        setOffices([...offices, { officeId: 0, officeDescription: '' }]);
    };

    const removeOffice = (index) => {
        const newOffices = offices.filter((_, i) => i !== index);
        setOffices(newOffices);
    };

    const handleSearchOfficeChange = (selectedOfficeId) => {
        const selectedOffice = allOffices.find(office => office.officeId === selectedOfficeId);
        if (selectedOffice) {
          setSearchSelectedOffice(selectedOffice.officeId);
        } else {
            setSearchSelectedOffice('');
        }
    }; 

    const handleTeamChange = (e) => {
        const selectedValue = e.target.value;
        const selectedTeam = allTeams.find(team => team.teamId === parseInt(selectedValue));
        if (selectedTeam) {
            setSelectedUserTeam({ teamId: selectedTeam.teamId, teamName: selectedTeam.teamName });
        } else {
            setSelectedUserTeam({ teamId: '', teamName: '' });
        }
        setErrors((prevErrors) => ({ ...prevErrors, selectedUserTeam: '' }));
    };
    
    const handleCheckBoxChange = (e, setState, checkboxType) => {
        const isChecked = e.target.checked;
        setState(isChecked);
        if (checkboxType === 'isAdmin') {
            const allRoles = sortedGroupsWithRoles.flatMap(group => group.roles);
            const allRoleIds = allRoles.map(role => role.roleId); 
            if (isChecked) {  
                setSelectedUserRoles(allRoleIds);
                setInsertedUserRoles(allRoles.map(role => ({ roleId: role.roleId, roleName: role.roleName })));
                setDeletedUserRoles(deletedUserRoles.filter(role => !allRoleIds.includes(role.roleId)));
            } else {                   
                setSelectedUserRoles([]);
                setDeletedUserRoles(allRoles.map(role => ({ roleId: role.roleId, roleName: role.roleName })));
                setInsertedUserRoles([]);
            }
        }
    };

    const handleMultiSelectCheckboxChange = (event) => {
        const roleId = parseInt(event.target.value);
        const roleName = allRoles.find((role) => role.roleId === roleId).roleName;
    
        if (event.target.checked) {
            if (!selectedUserRoles.includes(roleId)) {
                setSelectedUserRoles([...selectedUserRoles, roleId]);
                setInsertedUserRoles([...insertedUserRoles, { roleId, roleName }]);
                setDeletedUserRoles(deletedUserRoles.filter(role => role.roleId !== roleId));
            }
        } else {
            if (selectedUserRoles.includes(roleId)) {
                setSelectedUserRoles(selectedUserRoles.filter(id => id !== roleId));
                setDeletedUserRoles([...deletedUserRoles, { roleId, roleName }]);
                setInsertedUserRoles(insertedUserRoles.filter(role => role.roleId !== roleId));
            }
        }
    };

    const validate = () => {
        const newErrors = {};
        const officeIds = new Set();
        if (!userName) newErrors.userName = 'Full name is required.';
        if (!userEmail) newErrors.userEmail = 'Email is required.';
        if (!userAccountType) newErrors.userAccountType = 'Account Type is required.';
        if (!userJobTitle) newErrors.userJobTitle = 'Job Title is required.';
        if (selectedUserOffice.officeId === 0) newErrors.selectedUserOffice = 'Office is required.';
        offices.forEach((office, index) => {
            if (officeIds.has(office.officeId)) {
                newErrors[`office-${index}`] = 'Duplicate office found, Please add different office ';
            } else {
                officeIds.add(office.officeId);
            }
        });
        if (!selectedUserTeam) newErrors.selectedUserTeam = 'Team is required.';
        if(!selectedUserRoles) newErrors.selectedUserRoles = 'Roles are required.'
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const clearControls=()=>{
        setUserName('');
        setUserEmail('');
        setUserAccountType('');
        setUserJobTitle('');
        setSelectedUserOffice({officeId: '', officeDescription: ''});
        setOffices([{ officeId: '', officeDescription: '' }]);
        setSelectedUserTeam({ teamId: '', teamName: '' });
        setSelectedUserRoles('');
        setIsAdmin(false);
        setIsActive(false);
        setIsAccountLock(false);
        setErrors({});
    }

    const saveUserData = async () =>{
        try {
            if (!validate()) {
              return;
            }
              const payload = {
                userLoginId,
                fullName: userName,
                email: userEmail,
                accountType: userAccountType,
                jobTitle: userJobTitle,
                roleName: '',
                officeId: parseInt(selectedUserOffice.officeId),
                officeDescription: selectedUserOffice.officeDescription,
                offices,
                teamName: selectedUserTeam.teamName,
                teamId: parseInt(selectedUserTeam.teamId),
                isActive,
                isAdmin,
                isAccountLocked,
                roleIds: selectedUserRoles,
                insertedRoles: insertedUserRoles,
                deletedRoles: deletedUserRoles
            };
              UpdateUserDetails(payload).then((res) => {
               if (res.data.success) {
                if(res.data.message === 'User Details updated successfully, please re-login to apply the changes in your account.'){
                    showModal('success',res.data.message);
                    logout();
                }else{
                    showModal('success',res.data.message);
                    clearControls();
                    setIsFormVisible(false);
                    fetchUserDetails();
                }                
              } else {
                showModal('failure',res.data.message);
              }       
            });
          } 
          catch (error) {
            showModal('failure','Failed to update user details, Please try again later.');
          }
    };

    const handleUpdateClick = (user) => {
        if (formRef.current) {
            formRef.current.focus(); // Focus the form when the button is clicked
        }
        setIsSearchFormVisible(false);
        setIsFormVisible(true);
        setUserLoginId(user.userLoginId);
        setUserName(user.fullName);
        setUserEmail(user.email);
        setUserAccountType(user.accountType);
        setUserJobTitle(user.jobTitle);
        setSelectedUserOffice({
            officeId: user.officeId || '',
            officeDescription: user.officeDescription || ''
        });
        if (user.offices && Array.isArray(user.offices) && user.offices.length > 0) {
            const filteredOffices = user.offices.filter(office => office.officeId !== user.officeId);
            if(filteredOffices.length > 0){
                setOffices(filteredOffices.map(detail => ({
                    officeId: detail.officeId || '',
                    officeDescription: detail.officeDescription || ''
                })));
            }else {
                setOffices([{ officeId: 0, officeDescription: '' }]);
            }            
        }  
        if (offices.length === 0) {
            addOffice();
        }      
        setIsActive(user.isActive);
        setIsAdmin(user.isAdmin);
        setIsAccountLock(user.isAccountLocked);
        setSelectedUserTeam({
            teamId: user.teamId || '',
            teamName: user.teamName || ''
        });
        setSelectedUserRoles(user.roleIds);
    };
      //Modal popup
      const handleClose = () => {
        setShow(false);
        setSelectedField('');
    };

    const handleShow = (event, field) => {
        setModalSelectedEvent(event);
        setSelectedField(field);
        setShow(true);
    };

    const getFieldTitle = (field) => {
        switch (field) {
            case 'offices':
                return 'Duty Stations';
            case 'roleName':
                return 'Roles';
            default:
                return '';
        }
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>User Details</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className='btn btn-primary me-2 mr-2' onClick={handleSeacrhUserClick}>Filter Users</button>
                                        </div>
                                    </div>
                                </div>
                                {isFormVisible && (
                                <div className="card mb-3" ref={formRef} tabIndex="-1">
                                    <div className="card-header">
                                    <h6>Team Information</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                        <titletext>Personal Details</titletext>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="userName" className="form-label">Name <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="userName" placeholder="Full Name" disabled value={userName} />
                                                {errors.userName && <p style={{color: 'red'}}>{errors.userName}</p>}   
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="email" className="form-label">Email <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="email" placeholder="Email" disabled value={userEmail} />
                                                {errors.userEmail && <p style={{color: 'red'}}>{errors.userEmail}</p>}   
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="accountType" className='form-label'>Account Type <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="accountType" placeholder="Account Type" disabled value={userAccountType} />
                                                {errors.userAccountType && <p style={{color: 'red'}}>{errors.userAccountType}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>                                            
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="jobTitle" className='form-label'>Job Title <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="email" className="form-control" id="jobTitle" placeholder="Job Title" disabled value={userJobTitle} />
                                                {errors.userJobTitle && <p style={{color: 'red'}}>{errors.userJobTitle}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="office" className='form-label'>Office <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <SearchableDropdown options={allOffices} selectedValue={selectedUserOffice.officeId} onChange={handlePersonalOfficeChange} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={false}/>
                                                {errors.selectedUserOffice && <p style={{color: 'red'}}>{errors.selectedUserOffice}</p>}
                                            </div>
                                        </div>
                                        <div className='responseevents'>
                                        <titletext>Team Details</titletext>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="team" className='form-label'>Team <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <select id="team" className="form-select" value={selectedUserTeam.teamId} onChange={handleTeamChange}>
                                                    <option value="">--Select--</option>
                                                    {allTeams.map((team) => (
                                                        <option key={team.teamId} value={team.teamId}>{team.teamName}</option>
                                                    ))}
                                                </select>
                                                {errors.selectedUserTeam && <p style={{color: 'red'}}>{errors.selectedUserTeam}</p>}
                                            </div>
                                        </div>
                                        <div className='responseevents'>
                                        <titletext>Other Details</titletext>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-4">
                                                <input type='checkbox' id="isActive" className='me-2 mb-2' checked={isActive} onChange={(e) => handleCheckBoxChange(e, setIsActive, 'isActive')}/>
                                                <label htmlFor="isActive" className='form-label'>Mark Active </label>
                                            </div>
                                            {userInfo.isAdmin &&(
                                            <div className="form-group mb-3 col-md-4">
                                                <input type='checkbox' id="isAdmin" className='me-2 mb-2' checked={isAdmin} onChange={(e) => handleCheckBoxChange(e, setIsAdmin, 'isAdmin')}/>
                                                <label htmlFor="isAdmin" className='form-label'>Mark Admin </label>
                                            </div>
                                            )}                                            
                                            <div className="form-group mb-3 col-md-4">
                                                <input type='checkbox' id="lockAccount" className='me-2 mb-2' checked={isAccountLocked} onChange={(e) => handleCheckBoxChange(e, setIsAccountLock, 'isAccountLocked')}/>
                                                <label htmlFor="lockAccount" className='form-label'>Lock Account </label>
                                            </div>
                                        </div>
                                        <div className='responseevents'>
                                        <titletext>Role Details</titletext>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group mb-3 col-md-12'>
                                                <label htmlFor="roles" className='form-label'>
                                                    Roles <span style={{ color: "red", font: "bold" }}>*</span>
                                                </label>
                                                <div className="checkbox-list">
                                                    <GroupCheckboxList
                                                        filteredGroups={filteredGroups}
                                                        selectedUserRoles={selectedUserRoles}
                                                        setSelectedUserRoles={setSelectedUserRoles}
                                                    />
                                                    {errors.selectedUserRoles && <p style={{ color: 'red' }}>{errors.selectedUserRoles}</p>}
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className='responseevents'>
                                        <titletext>Duty stations Details</titletext>
                                        </div>
                                        {offices.map((office, index) => (
                                            <div key={index}>
                                                <div className='row'>
                                                    <div className="form-group mb-3 col-md-6" key={index}>
                                                        <label htmlFor={`office-${index}`} className='form-label'>
                                                        Duty station <span style={{ color: "red", font: "bold" }}>*</span>
                                                        </label>
                                                        <SearchableDropdown
                                                            options={userdetailsAllOffices}
                                                            selectedValue={office.officeId}
                                                            onChange={(selectedOffice) => handleOfficeChange(index, selectedOffice)}
                                                            displayKey="officeDescription"
                                                            valueKey="officeId"
                                                            className="w-100" 
                                                            disabled={false}/>
                                                        {errors[`office-${index}`] && <p style={{ color: 'red' }}>{errors[`office-${index}`]}</p>}                                                        
                                                    </div>
                                                    <div className="form-group col-md-2 d-flex align-items-center" style={{ marginTop: '25px' }}>
                                                        <button type="button" className="btn btn-primary mb-2 me-2" onClick={addOffice}><i className="bi bi-plus-circle"></i></button>
                                                            {offices.length > 1 && (
                                                            <button type="button" className="btn btn-danger mb-2" onClick={() => removeOffice(index)}><i className="bi bi-dash-circle"></i></button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='row' style={{float:'right'}}>
                                            <div className="form-group">
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2" onClick={saveUserData}>Update</button> 
                                                <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                {isSearchFormVisible && (
                                    <div className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Search User Details</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-1">
                                                <label htmlFor="searchteam" className="form-label me-2 mb-0">Team </label>       
                                            </div>
                                            <div className="form-group mb-3 col-md-5">
                                                <select id="searchteam" className="form-select" value={searchSelectedTeam} onChange={(e) => handleInputChange(e, setSearchSelectedTeam, 'searchSelectedTeam')}>
                                                        <option selected>--Select--</option>
                                                        {allTeams.map((team) => (
                                                            <option key={team.teamId} value={team.teamId}>{team.teamName}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='form-group mb-3 col-md-1'>
                                                <label htmlFor="roles" className='form-label'>Roles</label>   
                                            </div>
                                            <div className='form-group mb-3 col-md-5'>
                                                <Select classNamePrefix="mySelect" options={groupedRolesForDropdown} value={searchSelectedRoles} onChange={handleChange} isMulti closeMenuOnSelect={false} hideSelectedOptions={false}
                                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }} />
                                            </div>                                            
                                        </div>
                                        <div className='row'>                                            
                                            <div className="form-group col-md-1">
                                                <label htmlFor="searchoffice" className="form-label me-2 mb-0">Duty station </label>       
                                            </div>
                                            <div className="form-group col-md-5">
                                                <SearchableDropdown options={allOffices} selectedValue={searchSelectedOffice} onChange={handleSearchOfficeChange} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={false}/>
                                            </div> 
                                            <div className="form-group col-md-2">
                                                <input type='checkbox' id="searchIsActive" className='me-2 mb-2' checked={searchIsActive} onChange={(e) => handleCheckBoxChange(e, setSearchIsActive)}/>
                                                <label htmlFor="isActive" className='form-label'>InActive </label>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <input type='checkbox' id="searchIsAdmin" className='me-2 mb-2' checked={searchIsAdmin} onChange={(e) => handleCheckBoxChange(e, setSearchIsAdmin)}/>
                                                <label htmlFor="isAdmin" className='form-label'>Admin </label>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <input type='checkbox' id="searchIslockedAccount" className='me-2 mb-2' checked={searchIsAccountLock} onChange={(e) => handleCheckBoxChange(e, setSearchIsAccountLock)}/>
                                                <label htmlFor="lockAccount" className='form-label'>Lock Account </label>
                                            </div>                                          
                                        </div>
                                        <div className='row' style={{float:'right'}}>
                                            <div className='form-group'>
                                                <button type="button" className="btn btn-danger mb-2" onClick={handleSearchCancelClick}>Remove Filters</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                                
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>User Management List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Name</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Email</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Account Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Job Title</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Duty stations</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Active</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Admin</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Account Locked</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Roles</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Team</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((user) => (
                                                    <tr key={user.userLoginId}>
                                                    <td>{user.fullName}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.accountType}</td>
                                                    <td>{user.jobTitle}</td>
                                                    <td>
                                                        {user.officeDescription.substring(0, 20)}
                                                        {user.officeDescription.length > 20 && (
                                                            <a href="#" onClick={() => handleShow(user, 'offices')}>...View More</a>
                                                        )}
                                                    </td>
                                                    <td>{user.isActive ? 'Yes' : 'No'}</td>
                                                    <td>{user.isAdmin ? 'Yes' : 'No'}</td> 
                                                    <td>{user.isAccountLocked ? 'Yes' : 'No'}</td>                                                    
                                                    <td>
                                                    {user.roleName ? (
                                                        <div>
                                                            <p>{user.roleName.substring(0, 10)}</p>
                                                            <a href="#" onClick={() => handleShow(user,'roleName')}>View More</a>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    </td>
                                                    <td>{user.teamName}</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button className="btn btn-default btn-sm" onClick={() => handleUpdateClick(user)}>
                                                        <span className='bi bi-pencil' style={{color:'#ffc107'}}/>
                                                        </button>
                                                    </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {usersList.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <Modal show={show} onHide={handleClose} centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title className="text-center w-100">{getFieldTitle(selectedField)}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal-body-scrollable">
                                                {modalSelectedEvent && (
                                                    <>
                                                        {selectedField === 'offices' && modalSelectedEvent.officeDescription.split('|').map((item, index) => (
                                                                                        <p key={index}>{item.trim()}</p>
                                                                                        ))}
                                                        {selectedField === 'roleName' && modalSelectedEvent.roleName.split(',').map((item, index) => (
                                                                                        <p key={index}>{item.trim()}</p>
                                                                                        ))}
                                                    </>
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDetails;
