import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { useModal } from '../modalPopup/modelcontext.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './deploymentrequest.css';
import { GetAllSBPPartnersDetails } from '../../services/adminServices.js'
import { GetAllResponseEvents, GetAllPositionStatuses, GetSbpPartnerSupportStatus } from '../../services/dropdownServices.js';
import { GetSbpRequestDetailsById, GetAllHistories, GetAllRemarks, AddRemarkDetails, DownloadUploadedDocument, UpdateCCUsersDetails, ApproveSBPRequest, HandleSendToSbpPartners, UpdateSbpPartnersDetais } from '../../services/deploymentrequestServices.js';
import { Modal } from 'react-bootstrap';
import { getToken, userRoles } from '../../services/loginService.js';
import { useParams, useNavigate } from 'react-router-dom';

const ViewSbpDeploymentRequest = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
    const [selectedDeploymentType, setSelectedDeploymentType] = useState(null);
    const [requestCode, setRequestCode] = useState('');
    const [requestStatus, setRequestStatus] = useState(null);
    const [deploymentRequestId, setDeploymentRequestId] = useState(0);
    const [deploymentRequestPosition, setDeploymentRequestPosition] = useState('');
    const [selectedResponseEvent, setSelectedResponseEvent] = useState('');
    const [ccUsersMail, setCcUsersMail] = useState('');
    const [isCcMailEditing, setIsCcMailEditing] = useState(false);
    const [ccUserEmailIdError, setCcUserEmailIdError] = useState('');
    const [responseEvents, setResponseEvents] = useState([]);
    const [allPositionStatus, setAllPositionStatus] = useState([]);
    const [sbpPartnerSupportStatus, setSbpPartnerSupportStatus] = useState([]);
    const [selectedSupportingTeam, setSelectedSupportingTeam] = useState('');
    const { showModal } = useModal();
    const [show, setShow] = useState(false);
    const [showRemarkModal, setShowRemarkModal] = useState(false);
    const [showSbpPartnersModal, setShowSbpPartnersModal] = useState(false);
    const [remarkPopupText, setRemarkPopupText] = useState('');
    const [remarkError, setRemarkError] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [reason, setReason] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isSBP, setIsSBP] = useState(true);
    const [dutyStation, setDutyStation] = useState('');
    const [selectedPositionLevel, setSelectedPositionLevel] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedPositionType, setSelectedPositionType] = useState('');
    const [torDocumentPath, setTorDocumentPath] = useState('');
    const [uploadedDocumentName, setUploadedDocumentName] = useState('');
    const [supervisorName, setSupervisorName] = useState('');
    const [supervisorPosition, setSupervisorPosition] = useState('');
    const [supervisorEmail, setSupervisorEmail] = useState('');
    const [practicalArrangementSupervisorName, setPracticalArrangementSupervisorName] = useState('');
    const [practicalArrangementSupervisorPosition, setPracticalArrangementSupervisorPosition] = useState('');
    const [practicalArrangementSupervisorEmail, setPracticalArrangementSupervisorEmail] = useState('');
    const [performanceEvaluationSupervisorName, setPerformanceEvaluationSupervisorName] = useState('');
    const [performanceEvaluationSupervisorPosition, setPerformanceEvaluationSupervisorPosition] = useState('');
    const [performanceEvaluationSupervisorEmail, setPerformanceEvaluationSupervisorEmail] = useState('');
    const [urgentRequestJustification, setUrgentRequestJustification] = useState('');
    const [criticalRequestJustification, setCriticalRequestJustification] = useState('');
    const [longerTermRequestJustification, setLongerTermRequestJustification] = useState('');
    const [isRequestExtension, setIsRequestExtension] = useState(false);
    const [existingRequestCode, setExistingRequestCode] = useState('');
    const [isOfficeSpaceProvided, setIsOfficeSpaceProvided] = useState(null);
    const [unSecurityPhaseDutyStation, setUnSecurityPhaseDutyStation] = useState('');
    const [specialMedicalRequirements, setSpecialMedicalRequirements] = useState('');
    const [visaRequirements, setVisaRequirements] = useState('');
    const [rrCycle, setRrCycle] = useState('');
    const [errors, setErrors] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [showRemarks, setShowRemarks] = useState(false);
    const [showDeatils, setShowDetails] = useState(true);
    const [deploymentRequestPositionId, setDeploymentRequestPositionId] = useState('');
    const [selectedPostionId, setSelectedPositionId] = useState('');
    const [sbpPartnersList, setSbpPartnersList] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [sbpPartnerName, setSbpPartnerName] = useState('');
    const [sendToDate, setSendToDate] = useState(null);
    const [showSbpPartnersNameModal, setShowSbpPartnersNameModal] = useState(false);
    const [sendSbpRequestType, setSendSbpRequestType] = useState('');
    const [proofOfApprovalDocumentPath, setProofOfApprovalDocumentPath] = useState('');
    const [uploadedProofOfApprovalDocumentName, setUploadedProofOfApprovalDocumentName] = useState('');
    const [existingPartnersId, setExistingPartnersId] = useState([]);
    const [sbpPartnersDetails, setSbpPartnersDetails] = useState([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedPartnerEdit, setSelectedPartnerEdit] = useState({
        sbpPartnerName: '',
        partnerStatusId: '',
        createdDate: null,
        rejectedReason: '',
    });
    const [sbpPartnerUpdateValidation, setSbpPartnerUpdateValidation] = useState(false);

    const fetchResponseEvents = async() => {
        try{
            GetAllResponseEvents().then((response) =>{
                if(response.status === 200){
                    setResponseEvents(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching response event dropdownlist details:', error);
        }
    };

    const fetchPositionStatuses = () => {
        try{
            GetAllPositionStatuses().then((response) => {
                if(response.status === 200){
                    setAllPositionStatus(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positionStatus detials:', error);
        }
    };

    const fetchRequestDetailsById = async (id) => {
        const response = await GetSbpRequestDetailsById(id); 
        if (response.status === 200) {
            return response.data;
        }
    };

    const fetchDeploymentRequestData = async (id) => {
        try {
                const data = await fetchRequestDetailsById(id);
                setDeploymentRequestId(data.deploymentRequestId);
                setDeploymentRequestPosition(data.positionHeader);
                setRequestCode(data.requestCode);
                setRequestStatus(data.status);
                setSelectedDeploymentType(data.deploymentTypeName);
                setSelectedSupportingTeam(data.requestedTeam);
                setSelectedResponseEvent(data.responseId);
                setCcUsersMail(data.ccUserMailIds);
                setIsRequestExtension(data.isExtensionRequest);
                setExistingRequestCode(data.existingRequestCode);
                setDutyStation(data.dutyStation);
                setSelectedPositionLevel(data.selectedPositionLevel);
                setFromDate(data.targetFromDate === '01/01/1970' ? null : data.targetFromDate);
                setToDate(data.targetToDate === '01/01/1970' ? null : data.targetToDate);
                setSelectedPositionType(data.selectedPositionType);
                setUploadedDocumentName(data.uploadedDocumentName);
                setTorDocumentPath(data.torDocumentPath);
                setUrgentRequestJustification(data.urgentRequestJustification);
                setCriticalRequestJustification(data.criticalRequestJustification);
                setLongerTermRequestJustification(data.longerTermRequestJustification);
                setSupervisorName(data.supervisorName);
                setSupervisorPosition(data.supervisorPositionTitle);
                setSupervisorEmail(data.supervisorEmail);
                setPracticalArrangementSupervisorName(data.paSupervisorName);
                setPracticalArrangementSupervisorPosition(data.paSupervisorPositionTitle);
                setPracticalArrangementSupervisorEmail(data.paSupervisorEmail);
                setPerformanceEvaluationSupervisorName(data.peSupervisorName);
                setPerformanceEvaluationSupervisorPosition(data.peSupervisorPositionTitle);
                setPerformanceEvaluationSupervisorEmail(data.peSupervisorEmail);
                setUnSecurityPhaseDutyStation(data.unSecurityPhase);
                setSpecialMedicalRequirements(data.specialMedicalRequirements);
                setVisaRequirements(data.visaRequirement);
                setRrCycle(data.rnRCycle);
                setIsOfficeSpaceProvided(data.isOfficeSpaceProvide);
                setIsSBP(data.deploymentTypeName==='SBP' ? true : false);
                setDeploymentRequestPositionId(data.deploymentRequestPositionId);
                setSelectedPositionId(data.capacityMappingId);
                setSbpPartnerName(data.sbpPartnerName);
                setSendToDate(data.sbpSendToDate);
                setUploadedProofOfApprovalDocumentName(data.uploadedProofOfApprovalDocumentName);
                setProofOfApprovalDocumentPath(data.proofOfApprovalDocumentPath);
                setExistingPartnersId(data.sbpPartnersId);
                setSbpPartnersDetails(data.sbpPartnersDetails);
        } catch (error) {
        }
    };

    const fetchSbpPartnersSupportStatuses = () => {
        try{
            GetSbpPartnerSupportStatus().then((response) => {
                if(response.status === 200){
                    setSbpPartnerSupportStatus(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positionStatus detials:', error);
        }
    };

    useEffect(() => {
        fetchResponseEvents();
        fetchPositionStatuses();
        fetchSbpPartnersList();
        fetchSbpPartnersSupportStatuses();
        if (id) {
            const numericId = parseInt(id, 10);
            if (!isNaN(numericId)) {
                fetchDeploymentRequestData(numericId);
            } else {
                console.error('Invalid id parameter:', id);
            }
        }
    }, [id]);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const validateEmails = (emails) => {
        const emailArray = emails.split(",").map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        for (let email of emailArray) {
          if (!emailRegex.test(email)) {
            return `Invalid Cc User emailId: ${email}`;
          }
        }
        return null;
    }; 

    const handleDownloadDocument = async (documentName, documentPath) => {
        if (!documentName || !documentPath) {
            showModal('failure', "Document details are missing.");
            return;
        }
        try {
            const response = await DownloadUploadedDocument(documentName, documentPath);
            if (response.success !== false) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const contentDisposition = response.headers['content-disposition'];
                let fileName = documentName; 
                if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
                    fileName = contentDisposition.split('filename=')[1].replace(/"/g, ''); 
                }
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
                } else {
                    showModal('failure', response.message || "Failed to download the document.");
                }
        } catch (error) {
            showModal('failure', "An unexpected error occurred while downloading the file.");
        }
    };

    const handleEditCcUsersClick = () => {
            setIsCcMailEditing(true);
    };
        
    const handleSaveCcUsersClick = () => {        
        if (ccUsersMail.trim() === '') {
            setCcUserEmailIdError('Cc Emails can not be empty');
            return;
        }
        const validationErrorMessage = validateEmails(ccUsersMail);
        if(validationErrorMessage){
            setCcUserEmailIdError(validationErrorMessage);
            return;
        }
        var payload = {deploymentRequestId: parseInt(deploymentRequestId), ccUsersEmailId: ccUsersMail.trim()};
            UpdateCCUsersDetails(payload).then((res) => {
            if (res.data.success) {
                setIsCcMailEditing(false);
                showModal('success',res.data.message);
                fetchDeploymentRequestData(deploymentRequestId);
                } else {
                showModal('failure',res.data.message);
            }       
        });
    };

    const calculateDaysInclusive = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        const timeDifference = end - start;
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return daysDifference + 1;
    };
    
    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleInputChange = (e, setter, field) => {
        if(field === 'plannedFromDate' || field === 'plannedToDate'){
            setter(e);
        }else{
            const { value } = e.target;
            setter(value);
        }      
    };
    
    function formatDateToUTC(dateString) {
        const date = new Date(dateString);
        
        const formattedDate = date.toLocaleString("en-US", {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,        // 12-hour format
          timeZone: 'UTC'      // Convert to UTC time
        });
        return formattedDate;
    };
    
    const fetchHistoryData = async (deploymentRequestId) => {
        try{
            GetAllHistories(deploymentRequestId).then((response) =>{
                if(response.status === 200){
                    setHistoryData(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching History details:', error);
        }
    };
      
    const fetchRemarksData = async (deploymentRequestId) => {
        try{
            GetAllRemarks(deploymentRequestId).then((response) =>{
                if(response.status === 200){
                    setRemarksData(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching remarks details:', error);
        }
    };
    
    const handleShowHistory = () =>{
        fetchHistoryData(deploymentRequestId);
        setShowHistory(true);
        setShowDetails(false);
        setShowRemarks(false);
    }; 

    const handleShowRemarks = () => {
        fetchRemarksData(deploymentRequestId);
        setShowRemarks(true)
        setShowHistory(false);
        setShowDetails(false);
    };

    const handleBackToDetails = () => {
        setShowRemarks(false)
        setShowHistory(false);
        setShowDetails(true);
    };
    
    const handleAddRemarkModel = () =>{
        setShowRemarkModal(true);
    };

    const handleCloseRemarkModal = () => {
        setShowRemarkModal(false);
        setRemarkPopupText('');
        setRemarkError('');
    };
    
    const handleRemarkPopupText = (event) => {
        setRemarkPopupText(event.target.value);
    };

    const handleModalConfirmRemark = () => {
        if (remarkPopupText.trim() === '') {
            setRemarkError('Remark cannot be empty');
            return;
        }
        var payload = {deploymentRequestId: parseInt(deploymentRequestId), remarkText: remarkPopupText, requestCode: requestCode, requestedTeam: selectedSupportingTeam};
            AddRemarkDetails(payload).then((res) => {
               if (res.data.success) {
                showModal('success',res.data.message);
                handleCloseRemarkModal();
                fetchRemarksData(deploymentRequestId);
              } else {
                showModal('failure',res.data.message);
                handleCloseRemarkModal();
            }       
        });
    };

    const handleClose = () => {
            setShow(false);
            setReason('');
    };
    
    const openModal = (title, body, btnText) => {
            setModalTitle(title);
            setModalBody(body);
            setButtonText(btnText);
            setShow(true);
    };
    
    const handleModalConfirm = async () => {
        try {
            if(isSBP){
                if(reason==='' && (buttonText !== 'Launch' && buttonText !== "Save" && buttonText !== "Update")){
                    showModal('failure','Reason is required, Please provide a reason');
                    handleClose();
                    return;
                }
                else if(buttonText === 'Launch' || buttonText === 'Reject' || buttonText === 'Resubmit' || buttonText === 'Revert') {
                    const formData = createFormDataRequest(buttonText);
                    ApproveSBPRequest(formData).then((res) => {
                        handleClose(); 
                        if (res.data.success) {
                            showModal('success', res.data.message);
                            fetchDeploymentRequestData(parseInt(deploymentRequestId));
                        } else {
                            showModal('failure', res.data.message);
                        }
                    });
                }
            }            
        } catch (error) {
            handleClose(); 
            showModal('failure', 'Request '+ buttonText +' failed with error ' + error);
        }
    };

    function createFormDataRequest(buttonText) {
        const formData = new FormData();
        formData.append('deploymentRequestId', deploymentRequestId);
        formData.append('responseId', parseInt(selectedResponseEvent, 10));
        formData.append('buttonText', buttonText);
        formData.append('remarks', reason);
        formData.append('deploymentTypeText', selectedDeploymentType);
        formData.append('requestedTeamName',selectedSupportingTeam);
        formData.append('requestCode', requestCode);
        formData.append('deploymentRequestPositionId', deploymentRequestPositionId);
        formData.append('capacityMappingId', parseInt(selectedPostionId ?? 0));
        formData.append('postionHeader', deploymentRequestPosition);
        formData.append('dutyStation', dutyStation);
        formData.append('positionStatusId', buttonText==='Approve' ? approveStatusId : buttonText==='Reject' ? rejectStatusId : buttonText ==='Revert' ? pendingStatusId : buttonText ==='Resubmit'? pendingStatusId : 0);
        return formData;
    };

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const approveStatusId = allPositionStatus.find(item => item.statusDescription === 'Launched')?.positionStatusId;
    const rejectStatusId = allPositionStatus.find(item => item.statusDescription === 'Rejected')?.positionStatusId;
    const pendingStatusId = allPositionStatus.find(item => item.statusDescription === 'Pending')?.positionStatusId;

    const userHasApproveSBPRequest = hasAccess([process.env.REACT_APP_ROLENAME_APPROVEREQUESTESFM]);
    const userHasReviewSBPRequest = hasAccess([process.env.REACT_APP_ROLENAME_REVIEWREQUESTESFM]);
    const userHasSendToSBPAgencies = hasAccess([process.env.REACT_APP_ROLENAME_SENDTOSBPAGENCIES]);
    const responseEventsVisibilityStatus = ['Submitted for Approval','Launched','In Progress','Completed'];
    const sendSBPPartnersButtonVisibilityStatus = ['Launched']
    const approveButtonVisibilityStatus = ['Submitted','Submitted for Approval'];
    const rejectButtonVisibilityStatus = ['Submitted','Submitted for Approval','Launched'];
    const revertButtonVisibilityStatus = ['Submitted','Launched','Rejected','Submitted for Approval'];

    const handleApprove = () =>{
        openModal(
        'Confirm Launch',
        <>
        Are you sure you want to Launch this Request?.
        <span style={{ color: 'red' }}>*</span>
        </>,
        'Launch'
        );
    }

    const handleSendToSbpPartners = (requestType) => {
        setShowSbpPartnersModal(true);
        setSendSbpRequestType(requestType);
    };

    const handleReject = () => {
        openModal(
            'Confirm Rejection',
            <>
            Are you sure you want to reject this Request? Please provide reason for Rejection. 
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Reject'
        );
    };

    const handleReSubmit = () => {
        openModal(
            'Confirm Re Submit',
            <>
            Are you sure you want to re submit this Request? Please provide reason for Re Submit.',
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Resubmit'
        );
    };

    const handleRevert = () => {
        openModal(
            'Confirm Revert',
            <>
            Are you sure you want to revert this Request? Please provide a reason for Revert.
            <span style={{ color: 'red' }}>*</span>
            </>,
            'Revert'
        );
    };

    const handleReopen = () => {
        navigate(`/sbpdeploymentrequest/edit/${deploymentRequestId}`);
    }

    const fetchSbpPartnersList = async() => {
        try{
            GetAllSBPPartnersDetails().then((response) =>{
                if(response.status === 200){
                    setSbpPartnersList(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching sbpPartners List:', error);
        }
    };

    const handleCloseSbpPartnersModal = () =>{
        setShowSbpPartnersModal(false);
        setSelectedPartners([]);
        setSendSbpRequestType('');
    };

    const handleSelectAllPartners = () => {
        const selectableIds = sbpPartnersList
          .filter((partner) => !existingPartnersId.includes(partner.sbpId))
          .map((partner) => partner.sbpId);
      
        if (selectedPartners.filter((id) => !existingPartnersId.includes(id)).length === selectableIds.length) {
            setSelectedPartners((prev) => prev.filter((id) => existingPartnersId.includes(id)));
        } else {
            setSelectedPartners([...existingPartnersId, ...selectableIds]);
        }
    };
      
    const handlePartnerSelection = (sbpId) => {
        if (selectedPartners.includes(sbpId)) {
            // If the partner is already selected, remove it
            setSelectedPartners(selectedPartners.filter(id => id !== sbpId));
        } else {
            // Otherwise, add it to the selected list
            setSelectedPartners([...selectedPartners, sbpId]);
        }
    };
    
    const handleOpenConfirmationModal = () => {
        setShowConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleSendEmailConfirmed = () => {
        setShowConfirmationModal(false);
        setShowSbpPartnersModal(false);
        setSelectedPartners([]);
        var payload = {
            deploymentRequestId : deploymentRequestId,
            remarks : sendSbpRequestType,
            deploymentTypeName : selectedDeploymentType,
            requestedTeam : selectedSupportingTeam,
            requestCode : requestCode,
            positionHeader : deploymentRequestPosition,
            dutyStation : dutyStation,
            sbpPartnersId : selectedPartners,
            deploymentRequestPositionId : deploymentRequestPositionId,
            uploadedDocumentName : uploadedDocumentName,
            torDocumentPath : torDocumentPath,
            grade : selectedPositionLevel,
            duration : formatDate(fromDate) + ' To ' + formatDate(toDate)
        };
        HandleSendToSbpPartners(payload).then((res) => {
            setShowConfirmationModal(false);
            handleClose(); 
            if (res.data.success) {
                showModal('success', res.data.message);
                fetchDeploymentRequestData(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        });
    };   

    const handleCloseSbpPartnersNameModal = () => {
        setShowSbpPartnersNameModal(false);
    };

    const handleShowSbpPartnersNameModal = () => {
        setShowSbpPartnersNameModal(true);
    };

    const handleUpdatePartnerDetailsClick = (partner) =>{
        setSelectedPartnerEdit(partner);
        setShowUpdateModal(true);
    };
    
    const handleCloseUpdateModal = () => setShowUpdateModal(false);

    const sbpRejectedStatusId = sbpPartnerSupportStatus.find(item => item.statusDescription === 'Rejected')?.partnerStatusId;

    const handleUpdateSubmit = () => {
        if (!selectedPartnerEdit.sbpPartnerName || !selectedPartnerEdit.partnerStatusId || !selectedPartnerEdit.createdDate) {
            setSbpPartnerUpdateValidation(true);
            return;
        }
        if(selectedPartnerEdit.partnerStatusId && (parseInt(selectedPartnerEdit.partnerStatusId) === sbpRejectedStatusId) && !selectedPartnerEdit.rejectedReason){
            setSbpPartnerUpdateValidation(true);
            return;
        }
        // Prepare data for update
        const updatedPartnerData = {
            sbpPartnerId: parseInt(selectedPartnerEdit.sbpPartnerId),
            deploymentRequestId : deploymentRequestId,
            partnerStatusId: parseInt(selectedPartnerEdit.partnerStatusId),
            createdDate: selectedPartnerEdit.createdDate ? new Date(selectedPartnerEdit.createdDate).toISOString() : null,
            rejectedReason: selectedPartnerEdit.rejectedReason,
        };
        UpdateSbpPartnersDetais(updatedPartnerData).then((res) => {
            handleClose(); 
            if (res.data.success) {
                setShowSbpPartnersNameModal(false);
                setShowUpdateModal(false);
                showModal('success', res.data.message);
                fetchDeploymentRequestData(parseInt(deploymentRequestId));
            } else {
                showModal('failure', res.data.message);
            }
        });
    };    

    const handlePartnerChange = (name, value) => {
        setSbpPartnerUpdateValidation(false);
        setSelectedPartnerEdit((prev) => {
            const updatedState = { ...prev, [name]: value };
    
            // If the status is not "Rejected," reset the rejectedReason
            if (name === "partnerStatusId" && value !== sbpRejectedStatusId) {
                updatedState.rejectedReason = "";
            }    
            return updatedState;
        });
    };
    
    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                            {showDeatils && (
                                <div className="card mb-3">
                                    <div className='card-header'>
                                        <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Launched' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowRemarks}>Remarks</button>
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowHistory}>History</button>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                            <div className='section-header'>
                                                <span>Response & Deployment Type</span>
                                            </div>
                                            <div className='row'>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label htmlFor="responseEvent" className="form-label">Response/Event</label>
                                                    <select id="responseEvent" className="form-select" value={selectedResponseEvent} onChange={(e) => handleInputChange(e, setSelectedResponseEvent, 'selectedResponseEvent')} disabled={responseEventsVisibilityStatus.includes(requestStatus)}>
                                                        <option value="">--Select--</option>
                                                        {responseEvents.map((res) => (
                                                            <option key={res.responseId} value={res.responseId}>{res.responseDescription}</option>
                                                        ))}
                                                    </select>
                                                    {errors.selectedResponseEvent && <p style={{color: 'red'}}>{errors.selectedResponseEvent}</p>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label htmlFor="deploymentType" className="form-label">Deployment Type <span style={{color:"red", font:"bold"}}>*</span></label>
                                                    <input type="text" className="form-control" id="deploymentType" placeholder="Deployment Type" value={selectedDeploymentType} disabled />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label htmlFor="supportingTeam" className="form-label">Requested Team <span style={{color:"red", font:"bold"}}>*</span></label>
                                                    <input type="text" className="form-control" id="supportingTeam" placeholder="Requested Team" value={selectedSupportingTeam} disabled />
                                                </div>                                            
                                            </div>
                                            <div className='row'>
                                                <div className="form-group mb-3 col-md-12">
                                                    <label htmlFor="ccUsersMail" className="form-label" title="Provide the Cc Emails in comma separated format">Cc Emails</label>
                                                    <input type="text" className="form-control" id="ccUsersMail" placeholder="Provide the Cc Emails in comma separated format" value={ccUsersMail} onChange={(e) => setCcUsersMail(e.target.value)} disabled={!isCcMailEditing}/>
                                                        {requestStatus !== 'Completed' &&(
                                                            <>
                                                            {isCcMailEditing ? (<button type="button" onClick={handleSaveCcUsersClick} className="btn btn-primary btn-sm" style={{position: 'absolute', right: '20px', transform: 'translateY(-112%)', background: 'none', color: '#28a745', border: 'none'}}><i class="bi bi-floppy"></i></button>) : (
                                                                <button type="button" onClick={handleEditCcUsersClick} className="btn btn-secondary btn-sm" style={{position: 'absolute', right: '20px', transform: 'translateY(-112%)', background: 'none', border: 'none'}}><i className="bi bi-pencil" style={{color:'#ffc107'}}></i></button>
                                                            )}
                                                            {ccUserEmailIdError && (<p style={{color: 'red'}}>{ccUserEmailIdError}</p>)}
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-md-6 mb-3'>
                                                    <label htmlFor="isRequestExtension" className='form-label'>Is it Extension of a previous request? </label>
                                                    <input type="text" className="form-control" id="isRequestExtension" value={isRequestExtension === false ? 'No' : 'Yes'} disabled />
                                                </div>
                                                {isRequestExtension && (
                                                <div className='form-group col-md-6 mb-3'>
                                                    <label htmlFor="existingRequestCode" className='form-label'>Previous Request Code </label>
                                                    <input type="text" className="form-control" id="existingRequestCode" value={existingRequestCode} disabled />
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                        {isSBP && (<>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>Position Specific Information - {deploymentRequestPosition}</span>
                                                    <button className="btn" style={{float:'right', color:'white', fontWeight: 'bold'}} onClick={handleOpenPopup}>View Position Details</button>
                                                </div>
                                                <div className='form-outline'>
                                                    <fieldset>
                                                        <div className='row'>
                                                            <div className="form-group mb-1 col-md-3">
                                                                <label htmlFor='dutyStation' className='form-label'>
                                                                    <strong>Duty Station:</strong> {dutyStation}
                                                                </label>
                                                            </div>
                                                            <div className="form-group mb-1 col-md-3">
                                                                <label htmlFor='positionLevel' className='form-label'>
                                                                    <strong>Position Level:</strong> {selectedPositionLevel}
                                                                </label>
                                                            </div>
                                                            <div className="form-group mb-1 col-md-3">
                                                                <label htmlFor='positionType' className='form-label'>
                                                                    <strong>Position Type:</strong> {selectedPositionType}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor='dateFrom' className='form-label me-2'>
                                                                    <strong>Target From Date:</strong> {fromDate !== null ? formatDate(fromDate) : null}
                                                                </label>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor='toDate' className='form-label me-2'>
                                                                    <strong>Target To Date:</strong> {toDate !== null ? formatDate(toDate) : null}
                                                                </label>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor='estimatedCost' className='form-label'>
                                                                    <strong>Requested Duration:</strong> {fromDate && toDate ? (<> {calculateDaysInclusive(fromDate, toDate)} <span>Days</span> </>):''}
                                                                </label>
                                                            </div>
                                                            <div className='form-group col-md-3'>
                                                                <label htmlFor='torDocument' className='form-label'>
                                                                    <strong>Tor Document: </strong> {uploadedDocumentName && (<button style={{textAlign:'right', color:'#265aeda6', background:'none', border: 'none', fontWeight:'bold'}} onClick={() => handleDownloadDocument(uploadedDocumentName, torDocumentPath)} title={uploadedDocumentName}>Download</button>)}
                                                                </label>
                                                            </div>
                                                        </div>                                                    
                                                    </fieldset>
                                                </div>                                                                                                
                                            </div> 
                                            {sbpPartnerName && (
                                                <>
                                                    <div className='responseevents'>
                                                        <div className='section-header'>
                                                            <span>Send To SBP Partners</span>
                                                        </div>
                                                        <div className='form-outline'>
                                                            <fieldset>
                                                                <div className='row align-items-center'>
                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="sbpPartnerName" className="form-label"><strong>SBP Partner Name: </strong>{sbpPartnerName.length > 35 ? (
                                                                            <>
                                                                                {sbpPartnerName.substring(0, 35)}...
                                                                                <a href="#" onClick={(e) => {e.preventDefault(); handleShowSbpPartnersNameModal();}}>
                                                                                    View More
                                                                                </a>
                                                                            </>
                                                                            ) : (
                                                                                sbpPartnerName
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                    <div className='form-group col-md-3'>
                                                                        <label htmlFor='sbpSendToDate' className='form-label'><strong>Sent Date: </strong>{sendToDate !== null ? formatDate(sendToDate) : null }</label>
                                                                    </div>
                                                                    <div className='form-group col-md-3 d-flex justify-content-end'>
                                                                        <button className="btn" style={{float:'right', padding: '0px 0 4px 0', color:'#265aeda6', fontWeight: 'bold'}} onClick={() => handleSendToSbpPartners('reminder')}>Send Reminder to SBP Partners</button>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div> 
                                                </>
                                            )}                                                                               
                                        </>)}
                                        {(userInfo.isAdmin || userHasApproveSBPRequest || userHasReviewSBPRequest) &&(                                                                     
                                        <div className='row' style={{float:'right'}}>
                                            <div className="form-group mb-3 col-md-12">
                                                {approveButtonVisibilityStatus.includes(requestStatus) && userHasApproveSBPRequest && (
                                                    <button type="submit" id="btnApprove" className="btn btn-primary me-2 mb-2" onClick={handleApprove}>Launch</button>
                                                )}
                                                {sendSBPPartnersButtonVisibilityStatus.includes(requestStatus) && userHasSendToSBPAgencies && !sbpPartnerName && (
                                                    <button type="submit" id="btnSendToSbpAgencies" className="btn btn-primary me-2 mb-2" onClick={() => handleSendToSbpPartners('normal')}>Send To SBP Partners</button>
                                                )}
                                                {rejectButtonVisibilityStatus.includes(requestStatus) && userHasApproveSBPRequest && (
                                                    <button type="submit" id="btnReject" className="btn btn-danger me-2 mb-2" onClick={handleReject}>Reject</button>
                                                )}
                                                {requestStatus === 'Rejected' && userHasApproveSBPRequest && (
                                                    <button type="submit" id="btnReopen" className="btn btn-primary me-2 mb-2" onClick={handleReopen}>Re Open</button>
                                                )}
                                                {revertButtonVisibilityStatus.includes(requestStatus) && (userHasApproveSBPRequest || userHasReviewSBPRequest) && (
                                                    <button type="submit" id="btnRevert" className="btn btn-secondary me-2 mb-2" onClick={handleRevert}>Revert</button>
                                                )}         
                                                {requestStatus === 'Reverted' && (
                                                    <>
                                                        <button type="submit" id="btnEdit" className="btn btn-secondary me-2 mb-2" onClick={handleReopen}>Edit</button>
                                                        <button type="submit" id="btnReSubmit" className="btn btn-primary me-2 mb-2" onClick={handleReSubmit}>Re Submit</button>
                                                    </>
                                                )}                                                                                           
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {showHistory && (
                                <div className='card mb-3'>
                                    <div className='card-header'>
                                        <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Launched' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowRemarks}>Remarks</button>
                                        <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleBackToDetails}>Request Details</button>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                            <div className='section-header'>
                                                <span>
                                                    Request Histories
                                                </span>
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                        {historyData && historyData.length > 0 ? (
                                            <table className="table table-bordered table-striped">
                                                <thead className='thead-light' style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{width:'10%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>User Name</th>
                                                        <th style={{width:'20%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action Date</th>
                                                        <th style={{width:'70%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action Performed</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {historyData.length > 0 ? ( historyData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.createdBy}</td>                                                                
                                                        <td>{formatDateToUTC(item.actionDate)}</td>   
                                                        <td>{item.actionDescription}</td>                                                             
                                                    </tr>
                                                ))) : (
                                                        <tr>
                                                            <td colSpan="4" className="text-center">No history available.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            ) : (
                                            <p>No history available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            )}
                            {showRemarks && (
                                    <div className='card mb-3'>
                                        <div className='card-header'>
                                            <strong>Request Code :</strong> {requestCode } | <strong>Current Status :</strong> <span style={{color: requestStatus === 'Submitted' ? 'blue' : requestStatus === 'Launched' ? 'green' : requestStatus === 'Submitted for Approval' ? 'green' : requestStatus === 'Rejected' ? 'red' : requestStatus === 'Reverted' ? 'orange' : requestStatus === 'In Progress' ? 'green' : requestStatus === 'Completed' ? '#0033A0' : 'black', fontWeight:'bold'}}>{requestStatus}</span>
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleShowHistory}>History</button>
                                            <button className="btn" style={{float:'right', color:'#265aeda6', fontWeight: 'bold'}} onClick={handleBackToDetails}>Request Details</button>
                                        </div>
                                        <div className='card-body'>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>
                                                    Request Remarks
                                                    </span>
                                                    <button className="btn" style={{float:'right', color:'white'}} onClick={handleAddRemarkModel}>Add Remark</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                                {remarksData && remarksData.length > 0 ? (
                                                    <table className="table table-bordered table-striped">
                                                        <thead className='thead-light' style={{background:"ghostwhite"}}>
                                                            <tr>
                                                                <th style={{width:'10%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>User Name</th>                                                                                                            
                                                                <th style={{width:'20%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Remark Date</th>
                                                                <th style={{width:'70%', background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Remark</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {remarksData.length > 0 ? (
                                                            remarksData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.createdBy}</td>
                                                                    <td>{formatDateToUTC(item.remarkDate)}</td>
                                                                    <td>{item.remark}</td>                                                                    
                                                                </tr>
                                                            ))
                                                            ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">No Remarks available.</td>
                                                            </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No Remarks available.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                            )}  
                            </div>
                            <Modal show={showPopup} onHide={handleClosePopup} className='positions-modal'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Details for Position: {deploymentRequestPosition}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="text-start">
                                        {uploadedDocumentName && torDocumentPath && (
                                        <>
                                            <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Terms of Reference</div>
                                            <div className="form-group mb-3">
                                                <strong>Uploaded Documents: </strong> 
                                                <a style={{textDecoration:'auto'}}>{uploadedDocumentName}</a>
                                                <button className='btn btn-sm me-4' style={{float:'right'}} onClick={() => handleDownloadDocument(uploadedDocumentName, torDocumentPath)}><i class="bi bi-download" style={{color:'#0000ff'}}></i></button>
                                            </div>
                                        </>
                                        )}                                        
                                        <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Request Justification</div>
                                        <div className="form-group mb-2">
                                            <strong>Please explain why the post is urgent and why current IOM capacity cannot meet this need (or if requesting an extension, why one is needed)?: </strong>{urgentRequestJustification}
                                        </div>
                                        <div className="form-group mb-2">
                                            <strong>What are 3-4 critical deliverables of this post?: </strong>{criticalRequestJustification}
                                        </div>
                                        <div className="form-group mb-3">
                                            <strong>What is the longer-term staffing plan after SBP temporary support period/handover strategy (where relevant)?: </strong>{longerTermRequestJustification}
                                        </div>
                                        <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Security & Administrative</div>
                                        <div className='form-group mb-2'>
                                            <strong>UN Security Phase in-country/duty station: </strong>{unSecurityPhaseDutyStation}
                                        </div>
                                        <div className='form-group mb-2'>
                                            <strong>Special Medical Requirements (vaccinations etc): </strong>{specialMedicalRequirements}
                                        </div>
                                        <div className='form-group mb-2'>
                                            <strong>Visa Requirement (e.g., visa on arrival, invitation letter, etc): </strong>{visaRequirements}
                                        </div>
                                        <div className='form-group mb-2'>
                                            <strong>R & R cycle at duty station: </strong>{rrCycle}
                                        </div>
                                        <div className='form-group mb-3'>
                                            <strong>Will office space and working tools be provided?: </strong>{isOfficeSpaceProvided === true ? 'Yes' : 'No'}
                                        </div>
                                        <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Direct Supervisor of Deployee</div>
                                        <div className="form-group mb-2">
                                            <strong>Name:</strong> {supervisorName}
                                        </div>
                                        <div className="form-group mb-2">
                                            <strong>Position Title:</strong> {supervisorPosition}
                                        </div>
                                        <div className="form-group mb-3">
                                            <strong>Email:</strong> {supervisorEmail}
                                        </div>
                                        <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Focal Point for Practical Arrangements related to deployment (Security Clearance, Pick-up at Airport, Booking of Hotel, Briefing, etc)</div>
                                        <div className="form-group mb-2">
                                            <strong>Name:</strong> {practicalArrangementSupervisorName}
                                        </div>
                                        <div className="form-group mb-2">
                                            <strong>Position Title:</strong> {practicalArrangementSupervisorPosition}
                                        </div>
                                        <div className="form-group mb-3">
                                            <strong>Email:</strong> {practicalArrangementSupervisorEmail}
                                        </div>
                                        <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Focal point for the Performance Evaluation</div>
                                        <div className="form-group mb-2">
                                            <strong>Name:</strong> {performanceEvaluationSupervisorName}
                                        </div>
                                        <div className="form-group mb-2">
                                            <strong>Position Title:</strong> {performanceEvaluationSupervisorPosition}
                                        </div>
                                        <div className="form-group mb-3">
                                            <strong>Email:</strong> {performanceEvaluationSupervisorEmail}
                                        </div>
                                        {uploadedProofOfApprovalDocumentName && proofOfApprovalDocumentPath && (
                                        <>
                                            <div className='section-header' style={{marginBottom:'3px', marginLeft:'-5px', fontWeight:'bold'}}>Proof of Approval from COM/HOD</div>
                                            <div className="form-group">
                                                <strong>Uploaded Documents: </strong> 
                                                <a style={{textDecoration:'auto'}}>{uploadedProofOfApprovalDocumentName}</a>
                                                <button className='btn btn-sm me-4' style={{float:'right'}} onClick={() => handleDownloadDocument(uploadedProofOfApprovalDocumentName, proofOfApprovalDocumentPath)}><i class="bi bi-download" style={{color:'#0000ff'}}></i></button>
                                            </div>
                                        </>
                                        )}
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center w-100">{modalTitle}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <p>{modalBody}</p>
                                    {(buttonText !== 'Launch' && buttonText !== "Submit for Approval" && buttonText !== "Save" && buttonText !== "Update") && (
                                        <textarea className="form-control" rows="3" value={reason} onChange={handleReasonChange} placeholder="Enter your reason here..."></textarea>)}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-secondary me-2" onClick={handleClose}>Cancel</button>
                                    <button type='button' className="btn btn-primary" onClick={handleModalConfirm}>{buttonText}</button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showRemarkModal} onHide={handleCloseRemarkModal} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center w-100">Add Remark</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <textarea className="form-control" rows="3" value={remarkPopupText} onChange={handleRemarkPopupText} placeholder="Enter remark here..."></textarea>
                                    {remarkError && (
                                        <div className="text-danger mt-2">
                                            {remarkError}
                                        </div>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-primary" onClick={handleModalConfirmRemark}>Save</button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showSbpPartnersModal} onHide={handleCloseSbpPartnersModal} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center w-100">Select SBP Partners</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <div className="form-check" style={{ textAlign: 'left' }}>
                                        <input className="form-check-input" type="checkbox" id="select-all-partners" checked={ sbpPartnersList.length > 0 && selectedPartners.filter((id) => !existingPartnersId.includes(id)).length === sbpPartnersList.filter((partner) => !existingPartnersId.includes(partner.sbpId)).length } onChange={handleSelectAllPartners} />
                                        <label className="form-check-label" htmlFor="select-all-partners" style={{ fontWeight: 'bold' }} >
                                            Select All
                                        </label>
                                    </div>
                                    {sbpPartnersList.map((partner, index) => (
                                    <div key={index} className="form-check" style={{ textAlign: 'left' }}>
                                        <input className="form-check-input" type="checkbox" id={`partner-${index}`}
                                        checked={selectedPartners.includes(partner.sbpId) || existingPartnersId.includes(partner.sbpId)}
                                        onChange={() => handlePartnerSelection(partner.sbpId)}
                                        disabled={existingPartnersId.includes(partner.sbpId)} />
                                        <label className="form-check-label" htmlFor={`partner-${index}`}>
                                        {partner.sbpName}
                                        </label>
                                    </div>
                                    ))}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-primary" onClick={handleOpenConfirmationModal}>
                                    {existingPartnersId.length > 0 ? 'Resend Email' : 'Send Email'}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            {showConfirmationModal && (
                            <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} centered style={{position:'absolute'}}>
                                <Modal.Header>
                                    <Modal.Title className="text-center w-100">
                                        {selectedPartners.length === 0 ? <span style={{color:'red'}}>Failed!</span> : 'Confirm Action'}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedPartners.length === 0
                                        ? <span style={{color:'red'}}>Please select at least one SBP partner before sending the email.</span>
                                        : 'Are you sure you want to send the email to the selected SBP partners?'}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-secondary" onClick={handleCloseConfirmationModal}>{selectedPartners.length === 0 ? 'Ok' : 'Cancel'}</button>
                                    {selectedPartners.length > 0 && (
                                        <button type="button" className="btn btn-primary" onClick={handleSendEmailConfirmed}>Yes, Send Email</button>
                                    )}
                                </Modal.Footer>
                            </Modal>
                            )}
                            <Modal show={showSbpPartnersNameModal} onHide={handleCloseSbpPartnersNameModal} className='positions-modal'>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center w-100">
                                        SBP Partners for the Request
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                        <table className="table table-bordered table-striped">
                                            <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                <tr>
                                                    <th style={{background:"#7a9fd9", width:'25%', textAlign: 'center', verticalAlign: 'middle'}}>Partner Name</th>
                                                    <th style={{background:"#7a9fd9", width:'10%', textAlign: 'center', verticalAlign: 'middle'}}>Status</th>
                                                    <th style={{background:"#7a9fd9", width:'10%', textAlign: 'center', verticalAlign: 'middle'}}>Date</th>
                                                    <th style={{background:"#7a9fd9", width:'50%', textAlign: 'center', verticalAlign: 'middle'}}>Reason</th>
                                                    <th style={{background:"#7a9fd9", width:'5%', textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sbpPartnersDetails.map((partner) => (
                                                <tr key={partner.sbpPartnerId}>
                                                    <td>{partner.sbpPartnerName}</td>
                                                    <td>{partner.sbpPartnerStatus}</td>
                                                    <td>{partner.createdDate !== null ? formatDate(partner.createdDate) : null}</td>
                                                    <td>{partner.rejectedReason}</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <button className="btn btn-default btn-sm" onClick={() => handleUpdatePartnerDetailsClick(partner)}>
                                                        <span className='bi bi-pencil' style={{color:'#ffc107'}}/>
                                                        </button>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Partner Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedPartnerEdit && (
                                        <>
                                            <div className="form-group mb-3">
                                                <label htmlFor="sbpPartnerName" className="form-label" style={{textAlign:'left', fontWeight:'bold', display:'block'}}>Partner Name <span style={{ color: "red", font: "bold" }}>*</span></label>
                                                <input type="text" className="form-control" id="sbpPartnerName" name="sbpPartnerName" value={selectedPartnerEdit.sbpPartnerName} disabled />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="sbpPartnerStatus" className="form-label" style={{textAlign:'left', fontWeight:'bold', display:'block'}}>Status <span style={{ color: "red", font: "bold" }}>*</span></label>
                                                <select id='sbpPartnerStatus' name="partnerStatusId" className="form-select" value={selectedPartnerEdit.partnerStatusId || ''} onChange={(e) => handlePartnerChange(e.target.name, e.target.value)}>
                                                    <option value={''}>--Select--</option>
                                                    {sbpPartnerSupportStatus.map((status) => (
                                                        <option key={status.partnerStatusId} value={status.partnerStatusId}>
                                                            {status.statusDescription}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='form-group mb-3'> 
                                                <label htmlFor='partnerUpdatedDate' className='form-label' style={{textAlign:'left', fontWeight:'bold', display:'block'}}>Date <span style={{ color: "red", font: "bold" }}>*</span></label>
                                                <DatePicker selected={selectedPartnerEdit.createdDate} onChange={(date) => handlePartnerChange('createdDate', date)} dateFormat="dd/MM/yyyy" placeholderText="Select Update date" className='form-control' />
                                            </div>
                                            {parseInt(selectedPartnerEdit.partnerStatusId) === sbpRejectedStatusId && (
                                            <div className="form-group mb-3">
                                                <label htmlFor="rejectedReason" className="form-label" style={{textAlign:'left', fontWeight:'bold', display:'block'}}>Reason <span style={{ color: "red", font: "bold" }}>*</span></label>
                                                <textarea className="form-control" id="rejectedReason" name="rejectedReason" value={selectedPartnerEdit.rejectedReason} onChange={(e) => handlePartnerChange(e.target.name, e.target.value)} />
                                            </div>
                                            )}   
                                            {sbpPartnerUpdateValidation && (
                                            <div className='form-group mb-3'>    
                                                <span style={{color:'red', textAlign:'left', fontWeight:'bold', display:'block'}}>Please fill the required fields before update the Partner Details</span>
                                            </div>
                                                )}                                         
                                            <button type="submit" className="btn btn-primary" onClick={handleUpdateSubmit}>Update</button>
                                        </>
                                    )}
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ViewSbpDeploymentRequest;
