import React, { useState, useEffect } from 'react';
import Header from "../../assets/header/Header";
import Sidebar from "../../assets/sidebar/sidebar";
import { DeleteDeploymentRequest, GetAllRequestsList } from '../../services/deploymentrequestServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../modalPopup/modelcontext.js';
import { GetAllDeploymentTypes, GetAllRequestedTeams, GetAllRequestStatus } from '../../services/dropdownServices.js';
import { getToken, userRoles } from '../../services/loginService.js';

const DeploymentRequestsList = () => {
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
    const [requestsList, setRequestsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState(''); 
    const [show, setShow] = useState(false);
    const [modalSelectedEvent, setModalSelectedEvent] = useState(null);
    const [selectedField, setSelectedField] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [requestToDelete, setRequestToDelete] = useState(null);
    const [deploymentTypes, setDeploymentTypes] = useState([]);
    const [supportingTeams, setSupportingTeams] = useState([]);
    const [requestStatus, setRequestStatus] = useState([]);
    const { showModal } = useModal();
    const navigate = useNavigate();
    //Filter Variables
    const [selectedDeploymentType, setSelectedDeploymentType] = useState(null);
    const [searchRequestedTeam, setSearchRequestedTeam] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
    
    const fetchDeploymentTypes = async() => {
        try{
            GetAllDeploymentTypes().then((response) =>{
                if(response.status === 200){
                    setDeploymentTypes(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching Deployment types dropdownlist details:', error);
        }
    };

    const fetchRequestedTeams = async() => {
        try{
            GetAllRequestedTeams().then((response) =>{
                if(response.status === 200){
                    setSupportingTeams(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching requested teams dropdownlist details:', error);
        }
    };

    const fetchRequestStatus = async() => {
        try{
            GetAllRequestStatus().then((response) =>{
                if(response.status === 200){
                    setRequestStatus(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching Deployment types dropdownlist details:', error);
        }
    };

    const handleEditClick = (id, deploymentType) => {
        if(deploymentType === 'ESFM'){
            navigate(`/esfmdeploymentrequest/edit/${id}`);
        }else{
            navigate(`/sbpdeploymentrequest/edit/${id}`);
        }        
    };

    const handleDelectClick = (deploymentRequestId) => {
        setRequestToDelete(deploymentRequestId);
        setShowDeletePopup(true);
    };

    const handleCloseDeletePopup = () => {
        setShowDeletePopup(false);
        setRequestToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            DeleteDeploymentRequest(requestToDelete).then((response) =>{
                if(response.status === 200){
                    showModal('success', response.data.message);
                    fetchRequestsList();
                } else {
                    showModal('failure', response.data.message);
                }
            });
        } catch (error) {
            showModal('failure',"Error deleting the request:", error);
        } finally {
            handleCloseDeletePopup();
        }
    };
    
    const handleViewClick = (id, deploymentType) => {
        if(deploymentType === 'ESFM'){
            navigate(`/viewDeploymentRequest/${id}`);
        }else{
            navigate(`/viewSbpDeploymentRequest/${id}`);
        }        
    }
    const fetchRequestsList = async() => {
        try{
            GetAllRequestsList().then((response) =>{
                if(response.status === 200){
                    setRequestsList(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching requests details:', error);
        }
    };

    useEffect(() => {
        fetchRequestsList();
        fetchRequestedTeams();
        fetchDeploymentTypes();
        fetchRequestStatus();
    },[]);

    const filterRequestsByFormFields = (req) => {
        return (
            (!selectedDeploymentType || req.deploymentTypeId === parseInt(selectedDeploymentType, 10)) &&
            (!searchRequestedTeam || req.requestedTeamId === parseInt(searchRequestedTeam, 10)) &&
            (!searchStatus || req.statusId === parseInt(searchStatus, 10))
        );
    };

    const filteredRequestsList = requestsList.filter((req) =>(
        (req.requestCode && req.requestCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (req.responseEvent && req.responseEvent.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (req.deploymentTypeName && req.deploymentTypeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (req.requestedTeam && req.requestedTeam.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (req.positions && req.positions.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (req.status && req.status.toLowerCase().includes(searchQuery.toLowerCase()))
        ) && filterRequestsByFormFields(req)
    );

    const handleSearchCancelClick = () => {
        setIsSearchFormVisible(false);
        setSelectedDeploymentType(null);
        setSearchRequestedTeam(null);
        setSearchStatus(null);
    };

    const handleSeacrhRequestClick = () => {
        setIsSearchFormVisible(true);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRequestsList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredRequestsList.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredRequestsList.length);

    //Modal popup
    const handleClose = () => {
        setShow(false);
        setSelectedField('');
    };

    const handleShow = (event, field) => {
        setModalSelectedEvent(event);
        setSelectedField(field);
        setShow(true);
    };

    const getFieldTitle = (field) => {
        switch (field) {
            case 'positions':
                return 'Positions';
            default:
                return '';
        }
    };

    const userHasDraftRequestRole = hasAccess([process.env.REACT_APP_ROLENAME_DRAFTREQUEST]);
    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5"> 
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Deployment Requests</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className='btn btn-primary me-2 mr-2' onClick={handleSeacrhRequestClick}>Filter Request</button>
                                        </div>
                                    </div>
                                </div>                              
                                {isSearchFormVisible && (
                                    <div className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Search Request</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="searchDeploymentType" className="form-label me-2 mb-0">Deployment Type </label>       
                                                <select id="searchDeploymentType" className="form-select" value={selectedDeploymentType} onChange={(e) => setSelectedDeploymentType(e.target.value)}>
                                                        <option value="">--Select--</option>
                                                        {deploymentTypes.map((dep) => (
                                                            <option key={dep.supportTypeId} value={dep.supportTypeId}>{dep.supportTypeName}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className='form-group mb-3 col-md-4'>
                                                <label htmlFor="searchRequestedTeam" className='form-label'>Requested Team</label> 
                                                <select id="searchRequestedTeam" className="form-select" value={searchRequestedTeam} onChange={(e) => setSearchRequestedTeam(e.target.value)}>
                                                        <option value="">--Select--</option>
                                                        {supportingTeams.map((dep) => (
                                                            <option key={dep.teamId} value={dep.teamId}>{dep.teamName}</option>
                                                        ))}
                                                </select>  
                                            </div>
                                            <div className='form-group mb-3 col-md-4'>
                                                <label htmlFor="searchStatus" className='form-label'>Status</label>   
                                                <select id="searchStatus" className="form-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                        <option value="">--Select--</option>
                                                        {requestStatus.map((dep) => (
                                                            <option key={dep.statusId} value={dep.statusId}>{dep.statusDescription}</option>
                                                        ))}
                                                </select>
                                            </div>                                           
                                        </div>
                                        <div className='row' style={{float:'right'}}>
                                            <div className='form-group'>
                                                <button type="button" className="btn btn-danger" onClick={handleSearchCancelClick}>Remove Filters</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                                
                                <div id="deploymentsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>List of Deployment Requests</h6>                                        
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Request Code</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Response Event</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Deployment Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Requested Team</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Postions</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Current Status</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((res) => (
                                                    <tr key={res.deploymentRequestId}>
                                                    <td>{res.requestCode}</td>
                                                    <td>{res.responseEvent}</td>
                                                    <td>{res.deploymentTypeName}</td>
                                                    <td>{res.requestedTeam}</td>
                                                    <td>
                                                        {res.positions ? res.positions.substring(0, 20) : ''}
                                                        {res.positions ? res.positions.length > 20 && (
                                                            <a href="#" onClick={() => handleShow(res, 'positions')}>...View More</a>
                                                        ) : ''}
                                                    </td>
                                                    <td>{res.status}</td>
                                                    {res.status === process.env.REACT_APP_DRAFTDEPLOYMENT ? (
                                                    <>
                                                        <td style={{textAlign: 'center'}}>
                                                            <button className="btn btn-default btn-sm" onClick={() => handleEditClick(res.deploymentRequestId, res.deploymentTypeName)}>
                                                            <i className="bi bi-pencil-fill" style={{color:'#ffc107'}}></i>
                                                            </button>
                                                            {(userInfo.isAdmin || userHasDraftRequestRole) && (<button className="btn btn-sm" onClick={() => handleDelectClick(res.deploymentRequestId)}>
                                                            <i className="bi bi-trash" style={{color:'red'}}></i>
                                                            </button>)}
                                                        </td>
                                                    </>
                                                    ) : (
                                                    <td style={{textAlign: 'center'}}>
                                                        <button className="btn btn-default btn-sm" onClick={() => handleViewClick(res.deploymentRequestId, res.deploymentTypeName)}>
                                                        <i className="bi bi-eye-fill"></i>
                                                        </button>
                                                    </td>
                                                    )}
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {requestsList.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <Modal show={show} onHide={handleClose} centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title className="text-center w-100">{getFieldTitle(selectedField)}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal-body-scrollable">
                                                {modalSelectedEvent && (
                                                    <>
                                                        {selectedField === 'positions' && modalSelectedEvent.positions.split('|').map((item, index) => (
                                                        <p key={index}>{item.trim()}</p>
                                                        ))}
                                                    </>
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                        <Modal show={showDeletePopup} onHide={handleCloseDeletePopup} centered>
                                            <Modal.Header>
                                                <Modal.Title className="text-center w-100">Confirm Deletion</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal-body-scrollable">
                                            <p>Are you sure you want to delete this deployment request?</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <button type='button' className="btn btn-secondary me-2"  onClick={handleCloseDeletePopup}>
                                                Cancel
                                            </button>
                                                <button type='button' className="btn btn-danger" onClick={handleConfirmDelete}>
                                                    Delete
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DeploymentRequestsList;