import axios from 'axios';
import { getToken, refreshToken } from '../services/loginService';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

let sessionExpiredCallback = null;

// Allow setting a session expired handler
export const setSessionExpiredHandler = (callback) => {
  sessionExpiredCallback = callback;
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
  const userInfo = getToken();
  const token = userInfo?.token;

  if (token) {
    const decodedToken = jwtDecode(token);
    const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['UserId'] = userInfo?.userId;
      return config;
    } else {
      try {
        const payload = { token, refreshToken: userInfo?.refreshToken };
        const result = await refreshToken(payload);

        if (result?.data?.success) {
          const newTokenInfo = result.data;
          localStorage.setItem('isAuthenticated', 'true');
          localStorage.setItem('userInfo', JSON.stringify(newTokenInfo));

          config.headers['Authorization'] = `Bearer ${newTokenInfo.token}`;
          config.headers['UserId'] = newTokenInfo.userId;
          return config;
        } else {
          if (sessionExpiredCallback) {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('userInfo');
            sessionExpiredCallback();
          }
        }
      } catch (error) {
        if (sessionExpiredCallback) {
          sessionExpiredCallback();
        }
      }
    }
  }

  return config;
});

export default axiosInstance;
