import React, { useState, useEffect, useCallback  } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { useModal } from '../modalPopup/modelcontext.js';
import DatePicker from 'react-datepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import './deploymentrequest.css';
import { GetAllDeploymentTypes, GetAllPositionLevel, GetAllPositions, GetAllPositionTypes, GetAllRequestedTeams, GetAllResponseEvents, GetAllResponsibilities } from '../../services/dropdownServices.js';
import { GetAllRequestsList, SaveDraftESFMRequest,  SubmitESFMRequest, DownloadUploadedDocument } from '../../services/deploymentrequestServices.js';
import { Modal } from 'react-bootstrap';
import { getToken, userRoles } from '../../services/loginService.js';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const ESFMDeploymentRequest = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();
    const [hasFetched, setHasFetched] = useState(false);
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
    const [selectedDeploymentType, setSelectedDeploymentType] = useState(null);
    const [tempDeploymentType, setTempDeploymentType] = useState(null);
    const [requestCode, setRequestCode] = useState('');
    const [requestStatus, setRequestStatus] = useState(null);
    const [resubmitRemarks, setResubmitRemarks] = useState('');
    const [deploymentRequestId, setDeploymentRequestId] = useState(0);
    const [esfmEstimatedCost, setEsfmEstimatedCost] = useState(0);
    const [selectedResponseEvent, setSelectedResponseEvent] = useState('');
    const [ccUsersMail, setCcUsersMail] = useState('');
    const [responseEvents, setResponseEvents] = useState([]);
    const [deploymentTypes, setDeploymentTypes] = useState([]);
    const [selectedSupportingTeam, setSelectedSupportingTeam] = useState('');
    const [supportingTeams, setSupportingTeams] = useState([]);
    const [filteredSupportingTeams, setFilteredSupportingTeams] = useState([]);
    const [allResponsibilities, setAllResponsibilities] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [positionLevels, setPositionLevels] = useState([]);
    const [positionTypes, setPositionTypes] = useState([]);
    const { showModal } = useModal();
    const [show, setShow] = useState(false);
    const [showDropdownChange, setShowDropdownChange] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [isESFM, setIsESFM] = useState(false);
    const [sections, setSections] = useState([createEmptySection()]);
    const [errors, setErrors] = useState({
        selectedResponseEvent: '',
        selectedDeploymentType: '',
        selectedSupportingTeam: '',
        // Initialize errors for dynamic sections
        0: {
            selectedPosition: '',
            dutyStation: '',
            selectedPositionLevel: '',
            fromDate: '',
            toDate: '',
            selectedPositionType:'',
            supervisorName: '',
            supervisorPosition: '',
            supervisorEmail: '',
            termsofReference: '',
            torDocuments: '',
            requestJustification: '',
            requestedResponsibilities: '',
            estimatedCost: '',
            supportIdentifyProfile: ''
        }
    });

    const fetchResponseEvents = async() => {
        try{
            GetAllResponseEvents().then((response) =>{
                if(response.status === 200){
                    setResponseEvents(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching response event dropdownlist details:', error);
        }
    };

    const fetchDeploymentTypes = async() => {
        try{
            GetAllDeploymentTypes().then((response) =>{
                if(response.status === 200){
                    setDeploymentTypes(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching Deployment types dropdownlist details:', error);
        }
    };

    const fetchRequestedTeams = async() => {
        try{
            GetAllRequestedTeams().then((response) =>{
                if(response.status === 200){
                    setSupportingTeams(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching requested teams dropdownlist details:', error);
        }
    };

    const fetchPositions = useCallback(async() => {
        try{
            GetAllPositions(userInfo.isAdmin).then((response) =>{
                if(response.status === 200){
                    setAllPositions(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positions dropdownlist details:', error);
        }
    }, [userInfo.isAdmin]);

    const fetchPositionLevel = async() => {
        try{
            GetAllPositionLevel().then((response) =>{
                if(response.status === 200){
                    setPositionLevels(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positions Level dropdownlist details:', error);
        }
    };

    const fetchPositionTypes = async() => {
        try{
            GetAllPositionTypes().then((response) =>{
                if(response.status === 200){
                    setPositionTypes(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positions Level dropdownlist details:', error);
        }
    };

    const fetchResponsibilities = async() => {
        try{
            GetAllResponsibilities().then((response) =>{
                if(response.status === 200){
                    setAllResponsibilities(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching responsibilities details:', error);
        }
    };

    const fetchRequestsList = async () => {
        try {
            const response = await GetAllRequestsList(); 
            if (response.status === 200) {
                return response.data;
            } else {
                console.warn('Unexpected response status:', response.status);
                return [];
            }
        } catch (error) {
            console.error('Error while fetching requests details:', error);
            return [];
        }
    };

    const fetchAllTeams = async () => {
        try {
            const response = await GetAllRequestedTeams(); 
            if (response.status === 200) {
                return response.data;
            } 
        } catch (error) {
            console.error('Error while fetching requests details:', error);
            return [];
        }
    };

    useEffect(() => { 
        fetchResponseEvents();
        fetchDeploymentTypes();
        fetchRequestedTeams();
        fetchPositions();
        fetchPositionLevel();
        fetchPositionTypes();
        fetchResponsibilities();
        if (id !== undefined && !hasFetched) {
            fetchDeploymentRequestData(parseInt(id));
            setHasFetched(true);
        }
        if(id===undefined ){
            resetControls();
            setIsESFM(false);
            setRequestCode('');
        }
        const handleFocus = () => {
            fetchPositions(); 
        };
 
        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [fetchPositions, id, hasFetched]); 

    // Handle query string logic after initial data fetch
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const deploymentType = queryParams.get('deploymentType');
        const responseEventType = queryParams.get('responseeventtype');
    
        if (responseEventType || deploymentType) {
            if (responseEventType) {
                setSelectedResponseEvent(parseInt(responseEventType));
            }
            if (deploymentType) {
                setSelectedDeploymentType(parseInt(deploymentType));
                setIsESFM(true);
                setSections([createEmptySection()]);
                if (supportingTeams && supportingTeams.length > 0) {
                    const newTeams = supportingTeams.filter(pos => pos.supportTypeId === parseInt(deploymentType));
                    setFilteredSupportingTeams(newTeams);
                }
            }
        }
    }, [location.search, supportingTeams]);

    const fetchDeploymentRequestData = async (id) => {
        try {
              const requests = await fetchRequestsList();
              const allTeams = await fetchAllTeams();
                const data = requests.find(item=> item.deploymentRequestId === id);
                const updatedRequestedTeams = allTeams.filter(pos => pos.supportTypeId === parseInt(data.deploymentTypeId));
                setFilteredSupportingTeams(updatedRequestedTeams);
                setDeploymentRequestId(data.deploymentRequestId);
                setRequestCode(data.requestCode);
                setRequestStatus(data.status);
                setSelectedDeploymentType(data.deploymentTypeId);
                setSelectedSupportingTeam(data.requestedTeamId);
                setSelectedResponseEvent(data.responseId);
                setEsfmEstimatedCost(data.estimatedCost);
                setCcUsersMail(data.ccUserMailIds);
                const mappedSections = mapSectionsFromData(data.requestedPositions);
                setSections(mappedSections);
                setIsESFM(data.deploymentTypeName==='ESFM' ? true : false);
        } catch (error) {
            console.error('Error while fetching deployment request data:', error);
        }
    };

    const mapSectionsFromData = (sectionsData) => {
        return sectionsData.map(section => ({
            selectedPosition: section.capacityMappingId,
            dutyStation: section.dutyStation,
            postionHeader : section.positionHeader,
            selectedPositionLevel: section.groupId,
            fromDate: section.targetFromDate,
            toDate: section.targetToDate,
            selectedPositionType: section.opportunityTypeId,
            supervisorName: section.supervisorName,
            supervisorPosition: section.supervisorPositionTitle,
            supervisorEmail: section.supervisorEmail,
            termsofReference: section.torText,
            torDocumentPath: section.torDocumentPath, 
            requestJustification: section.requestJustification,
            requestedResponsibilities: section.responsibilities, 
            supportIdentifyProfile: section.supportIdentifyProfile,
            uploadedDocumentName : section.uploadedDocumentName,
            torDocumentName: section.torDocumentName
        }));
    };

    function createEmptySection() {
        return {
              selectedPosition: '',
              postionHeader : '',
              dutyStation: '',
              selectedPositionLevel: '',
              fromDate: null,
              toDate: null,
              selectedPositionType:'',
              supervisorName: '',
              supervisorPosition: '',
              supervisorEmail: '',
              termsofReference: '',
              torDocuments: '',
              requestJustification: '',
              requestedResponsibilities: [],
              supportIdentifyProfile: '',
              uploadedDocumentName: '',
              torDocumentPath: '',
              torDocumentName: '',
              isDeleted: false, 
              isDocumentDeleted: false,
              deletedDocumentName: '' 
        };
    }
  
    function createEmptyErrors() {
        return {
            selectedPosition: '',
            dutyStation: '',
            selectedPositionLevel: '',
            fromDate: '',
            toDate: '',
            selectedPositionType:'',
            supervisorName: '',
            supervisorPosition: '',
            supervisorEmail: '',
            termsofReference: '',
            torDocuments: '',
            requestJustification: '',
            requestedResponsibilities: '',
            supportIdentifyProfile: ''
        };
    }

    const addSection = () => {
        setSections(sections => [...sections, createEmptySection()]);
        setErrors(prevErrors => ({
            ...prevErrors,
            [sections.length]: createEmptyErrors() // Add errors for the new section
        }));
    };

    const removeSection = (index) => {
        const newSections = sections.filter((_, i) => i !== index);

        const newErrors = Object.keys(errors)
            .filter(key => parseInt(key) !== index) // Filter out the errors for the removed section
            .reduce((acc, key) => {
                acc[key] = errors[key];
                return acc;
            }, {});
    
        Object.keys(newErrors).forEach(key => {
            if (parseInt(key) > index) {
                const newKey = parseInt(key) - 1;
                newErrors[newKey] = newErrors[key];
                delete newErrors[key];
            }
        });

        setSections(newSections);
        setErrors(newErrors);
    };

    const handleMultiSelectCheckboxChange = (e, index) => {
        const { value, checked } = e.target;
        const newSections = [...sections];
        if (checked) {
            newSections[index].requestedResponsibilities = [
                ...newSections[index].requestedResponsibilities,
                parseInt(value)
            ];
        } else {
            newSections[index].requestedResponsibilities = newSections[index].requestedResponsibilities.filter(id => id !== parseInt(value));
        }
        setSections(newSections);
    };

    const handleInputChange = (e, setter, field, displayText) => {
        if (field === 'selectedDeploymentType') {
            const { value } = e.target;
            setTempDeploymentType(value);
            if (value === '') {
                setIsESFM(false);
                setSelectedSupportingTeam('');
                setSections([]);
                setErrors({});
                setFilteredSupportingTeams([]);
            } else {
                const newTeams = supportingTeams.filter(pos => pos.supportTypeId === parseInt(value));
                setFilteredSupportingTeams(newTeams);
                const selectedDeploymentTypeText = deploymentTypes.find(item => item.supportTypeId === parseInt(value)).supportTypeName;
                if (selectedDeploymentTypeText === 'ESFM') {
                    setter(value);
                    setIsESFM(true);
                    setSections((prevSections) => {
                        if (prevSections.length === 0) {
                            addSection();
                        }
                        return prevSections;
                    });
                } else {
                    const isPositionSelected = sections.some(section => section.selectedPosition !== '');
                    if(isPositionSelected){
                        setShowDropdownChange(true);
                    }
                    else{
                        const queryParams = new URLSearchParams({
                            deploymentType: value,
                            responseeventtype: selectedResponseEvent,
                        });               
                        navigate(`/sbpdeploymentrequest?${queryParams.toString()}`);
                    }
                }
            }
        }else{
            const { value } = e.target;
            setter(value);
        }
    };
    
    const handleChange = (index, field, value, displayText) => {
        const newSections = [...sections];
        newSections[index] = { ...newSections[index], [field]: value };
        setSections(newSections);
    
        // Copy errors as an object to ensure immutability
        const updatedErrors = { ...errors };
        if (!updatedErrors[index]) {
            updatedErrors[index] = {}; // Initialize as an empty object for each index if it doesn’t exist
        }
    
        // Date range validation for `fromDate` and `toDate`
        if (field === 'fromDate' || field === 'toDate') {
            const fromDate = newSections[index].fromDate;
            const toDate = newSections[index].toDate;
    
            if (fromDate && toDate) {
                if (new Date(toDate) < new Date(fromDate)) {
                    updatedErrors[index].toDate = `${displayText} should not be earlier than Target From Date.`;
                } else {
                    updatedErrors[index].toDate = '';
                }
            }
        }
    
        // Check for duplicate positions if the field is `selectedPosition`
        if (field === 'selectedPosition') {
            const selectedPosition = allPositions.find(pos => pos.positionId === parseInt(value));
            if (selectedPosition) {
                newSections[index].dutyStation = selectedPosition.officeDescription;
                newSections[index].postionHeader = selectedPosition.positionDescription;
            } else {
                newSections[index].dutyStation = '';
                newSections[index].postionHeader = '';
            }
    
            // Validate for duplicate positions
            const duplicateErrors = validateDuplicatePositions(newSections);
            Object.assign(updatedErrors[index], duplicateErrors[index] || {});
        }
        setErrors(updatedErrors);
    };

    function validateDuplicatePositions(sections) {
        const positions = sections.map(section => section.selectedPosition);
        const duplicateErrors = sections.map((section, idx) => {
            const isDuplicate = positions.indexOf(section.selectedPosition) !== idx && section.selectedPosition !== '';
            return {
                ...errors[idx],
                selectedPosition: isDuplicate ? 'This position is duplicated. Please select a different one.' : ''
            };
        });
        return duplicateErrors;
    }

    const validateField = (field, value) => {
        switch (field) {
            case 'selectedDeploymentType':
                return value ? '' : 'Deployment Type is required';
            case 'selectedSupportingTeam':
                return value ? '' : 'Requested Team is required';
            case 'selectedPosition':
                return value ? '' : 'Position is required';
            case 'selectedPositionLevel':
                return value ? '' : 'Position Level is required';
            case 'fromDate':
                return value ? '' : 'From Date is required';
            case 'toDate':
                return value ? '' : 'To Date is required';
            case 'selectedPositionType':
                return value ? '' : 'Position Type is required';
            case 'supervisorName':
                return value ? '' : 'Supervisor Name is required';
            case 'supervisorPosition':
                return value ? '' : 'Supervisor Position Title is required';
            case 'supervisorEmail':
                if (!value) return 'Supervisor Email is required';
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(value) ? '' : 'Invalid email format';
            case 'requestJustification':
                return value ? '' : 'Request Justification is required';
            case 'requestedResponsibilities':
                return value && value.length > 0 ? '' : 'At least one Responsibility is required';
            case 'estimatedCost':
                if (!value) return 'Estimated Cost is required';
                return isNaN(value) || parseFloat(value) <= 0 ? 'Estimated Cost must be a positive number' : '';
            case 'supportIdentifyProfile':
                return value ? '' : 'Support Identify Profile is required';
            case 'esfmEstimatedCost':
                return value ? '' : 'Cost is required';
            case 'resubmitRemarks':
                return value ? '' : 'Remark is required while re-submit the request';
            case 'ccUsersMail':
                if (!value) return 'CC Users EmailId is required';
                const validationError = validateEmails(value);
                return validationError ? validationError : "";
            default:
                return '';
        }
    };
    
    const validateEmails = (emails) => {
        const emailArray = emails.split(",").map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        for (let email of emailArray) {
          if (!emailRegex.test(email)) {
            return `Invalid CC User emailId: ${email}`;
          }
        }
        return null;
    };

    const handleValidation = () => {
        let valid = true;
        let newErrors = {};
    
        // Validate top-level fields
        const fields = {
            selectedDeploymentType,
            selectedSupportingTeam,
            esfmEstimatedCost
        };

        if (requestStatus === 'Rejected' || requestStatus === 'Reverted') {
            fields.resubmitRemarks = resubmitRemarks;
        }

        Object.keys(fields).forEach(field => {
            const error = validateField(field, fields[field]);
            if (error) {
                valid = false;
                newErrors[field] = error;
            }
        });
    
        if(isESFM){
            // Validate sections
            sections.forEach((section, index) => {
                const sectionErrors = {};
                const hasTorTextOrDocument = section.termsofReference || section.torDocuments;
                if (!hasTorTextOrDocument) {
                    valid = false;
                    if (!newErrors[index]) newErrors[index] = {};
                    newErrors[index]["termsofReference"] = "Either ToR Text or ToR Document must be provided.";
                }
                Object.keys(section).forEach(field => {
                    if(field !== "termsofReference" && field !== "torDocuments"){
                        const error = validateField(field, section[field]);
                        if (error) {
                            valid = false;
                            if (!newErrors[index]) newErrors[index] = {};
                            newErrors[index][field] = error;
                        }
                    }                        
                });
                
                if (Object.keys(sectionErrors).length > 0) {
                    newErrors[index] = sectionErrors;
                }
            });
        }        
        setErrors(newErrors);
        return valid;
    };

    const handleSaveDraft = async () => {
        try {
            if(isESFM){
                const errors = validateDuplicatePositions(sections);
                const hasErrors = errors.some(error => error.selectedPosition);
                if (hasErrors) {
                    setErrors(errors);
                    showModal('failure',"Please remove duplicate positions before save draft the request.");
                    return;
                }
                const formData = createFormDataRequest();
                SaveDraftESFMRequest(formData).then((res) => {
                    if (res.data.success) {
                        showModal('success', res.data.message);
                        setDeploymentRequestId(res.data.deploymentRequestId);
                        setRequestCode(res.data.deploymentRequestCode);
                    } else {
                        showModal('failure', res.data.message);
                    }
                });
            }            
        }
        catch(error) {
                showModal('failure', 'Save Draft Request failed with error ' + error);
        }
    };

    const handleSubmitForm = async () => {
            try {
                if(isESFM){
                    if (!handleValidation()) {
                        return;
                    }
                    const errors = validateDuplicatePositions(sections);
                    const hasErrors = errors.some(error => error.selectedPosition);
                    if (hasErrors) {
                        setErrors(errors);
                        showModal('failure',"Please remove duplicate positions before save draft the request.");
                        return;
                    }
                    const formData = createFormDataRequest();
                    SubmitESFMRequest(formData).then((res) => {
                        if (res.data.success) {
                            showModal('success', res.data.message);
                            navigate('/deploymentRequestsList');
                        } else {
                            showModal('failure', res.data.message);
                        }
                    });
                }
            }
            catch(error) {
                    showModal('failure', 'Submit Request failed with error ' + error);
            }
    };

    function createFormDataRequest() {
            const selectedDeploymentTypeText = deploymentTypes.find(item => item.supportTypeId === parseInt(selectedDeploymentType))?.supportTypeName || '';
            const selectedTeamNameText = filteredSupportingTeams.find(item => item.teamId === parseInt(selectedSupportingTeam))?.teamName || '';
            const positionType = positionTypes.find(item => item.opportunityTypeName === 'TDY')?.opportunityTypeId;
            const formData = new FormData();
            formData.append('deploymentRequestId', deploymentRequestId);
            formData.append('responseId', parseInt(selectedResponseEvent, 10));
            formData.append('deploymentTypeId', parseInt(selectedDeploymentType, 10));
            formData.append('deploymentTypeText', selectedDeploymentTypeText);
            formData.append('requestedTeamId', parseInt(selectedSupportingTeam, 10));
            formData.append('requestedTeamName',selectedTeamNameText);
            formData.append('resubmitRemarks',resubmitRemarks);
            formData.append('estimatedCost', parseFloat(esfmEstimatedCost ?? 0));
            formData.append('ccUserMailIds', ccUsersMail);

            sections.forEach((section, index) => {
                formData.append(`requestedPositions[${index}].capacityMappingId`, parseInt(section?.selectedPosition ?? 0));
                formData.append(`requestedPositions[${index}].postionHeader`, section?.postionHeader);
                formData.append(`requestedPositions[${index}].dutyStation`, section?.dutyStation);
                formData.append(`requestedPositions[${index}].groupId`, parseInt(section?.selectedPositionLevel ?? 0));
                formData.append(`requestedPositions[${index}].targetFromDate`, section?.fromDate ? new Date(section.fromDate).toISOString() : null);
                formData.append(`requestedPositions[${index}].targetToDate`, section?.toDate ? new Date(section.toDate).toISOString() : null);
                formData.append(`requestedPositions[${index}].opportunityTypeId`, parseInt(positionType ?? 0));
                formData.append(`requestedPositions[${index}].supervisorName`, section?.supervisorName ?? '');
                formData.append(`requestedPositions[${index}].supervisorPositionTitle`, section?.supervisorPosition ?? '');
                formData.append(`requestedPositions[${index}].supervisorEmail`, section?.supervisorEmail ?? '');
                formData.append(`requestedPositions[${index}].torText`, section?.termsofReference ?? '');
                formData.append(`requestedPositions[${index}].requestJustification`, section?.requestJustification ?? '');
                formData.append(`requestedPositions[${index}].supportIdentifyProfile`, section?.supportIdentifyProfile ?? '');

                if (section.isDocumentDeleted) {
                    formData.append(`requestedPositions[${index}].documentDeleted`, true);
                    formData.append(`requestedPositions[${index}].deletedDocumentName`, section?.deletedDocumentName ?? '');
                }else{
                    formData.append(`requestedPositions[${index}].documentDeleted`, false);
                    formData.append(`requestedPositions[${index}].deletedDocumentName`, '');
                } 
                if (section?.torDocuments) {
                    formData.append(`requestedPositions[${index}].torDocument`, section.torDocuments);
                }
                section?.requestedResponsibilities.forEach((resp, respIndex) => {
                    formData.append(`requestedPositions[${index}].responsibilities[${respIndex}]`, resp);
                });
            });
            return formData;
    }
     
    const resetControls = () => {
        // Reset static form values
        setSelectedResponseEvent('');
        setSelectedDeploymentType('');
        setSelectedSupportingTeam('');
        setSections([]);
        setErrors({
            selectedResponseEvent: '',
            selectedDeploymentType: '',
            selectedSupportingTeam: '',
            ...sections.reduce((acc, _, index) => ({
                ...acc,
                [`section_${index}`]: '' 
            }), {})
        });
        setIsESFM(false);
        setRequestCode('');
    };

    const toggleIcon = (index) => {
        setSections(prevSections =>
            prevSections.map((section, i) =>
                i === index ? { ...section, isCollapsed: !section.isCollapsed } : section
            )
        );
    };

    ///Delete the section confirm box
    const handleDeleteClick = (index) => {
        setDeleteIndex(index);
        setDeleteType('section');
        setShow(true);
    };
    
    const handleClose = () =>{
        setShow(false);
        setDeleteType(null);
        setDeleteIndex(null);
    } 
    
    const handleConfirmDelete = () => {
        if (deleteType === 'section') {
            removeSection(deleteIndex);
        } else if (deleteType === 'document') {
            const updatedSections = [...sections];
            if (deleteIndex >= 0 && deleteIndex < updatedSections.length) {
                updatedSections[deleteIndex].isDocumentDeleted = true;
                updatedSections[deleteIndex].deletedDocumentName = updatedSections[deleteIndex].torDocumentName;
                updatedSections[deleteIndex].torDocumentPath = '';
                updatedSections[deleteIndex].uploadedDocumentName = '';
                updatedSections[deleteIndex].torDocumentName = '';
                setSections(updatedSections);
            }
        }
        handleClose();
    };

    const handleDeleteDocument = (index) => {
        setDeleteIndex(index);
        setDeleteType('document');
        setShow(true);  
    };

    const handleDownloadDocument = async (index) => {
        const selectedSection = sections[index];
        const documentName = selectedSection?.uploadedDocumentName;
        const documentPath = selectedSection?.torDocumentPath;
    
        if (!documentName || !documentPath) {
            showModal('failure', "Document details are missing.");
            return;
        }
    
        try {
            const response = await DownloadUploadedDocument(documentName, documentPath);
    
            if (response.success !== false) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
    
                // Extract filename from the Content-Disposition header
                const contentDisposition = response.headers['content-disposition'];
                let fileName = documentName; // Fallback to documentName if filename is not provided in header
                if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
                    fileName = contentDisposition.split('filename=')[1].replace(/"/g, ''); // Extract the filename and remove quotes
                }
    
                // Create an object URL for the file and trigger the download
                const url = window.URL.createObjectURL(blob);
                
                // Create a temporary <a> tag to trigger download
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;  // Set the filename for the download
                link.click();  // Trigger the download
    
                // Revoke the object URL after the download
                window.URL.revokeObjectURL(url);
            } else {
                showModal('failure', response.message || "Failed to download the document.");
            }
        } catch (error) {
            showModal('failure', "An unexpected error occurred while downloading the file.");
        }
    };        
    
    const handleCloseDropdown = () =>{
        setShowDropdownChange(false);
    };

    const handleConfirmChange = () =>{
        setIsESFM(false);
        setShowDropdownChange(false);
        resetControls();
        const queryParams = new URLSearchParams({
            deploymentType: tempDeploymentType,
            responseeventtype: selectedResponseEvent,
        });                
        navigate(`/sbpdeploymentrequest?${queryParams.toString()}`);
    };

    const userHasDraftRequestRole = hasAccess([process.env.REACT_APP_ROLENAME_DRAFTREQUEST]);
    const userHasSubmitRequestRole = hasAccess([process.env.REACT_APP_ROLENAME_SUBMITREQUEST]);
    const draftButtonVisibilityStatus = [null,'Draft'];
    const resubmitButtonVisibilityStatus = ['Rejected','Reverted'];
    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="card mb-3">
                                    <div className='card-header'>
                                        <h5>New Deployment Request {requestCode && <span>: {requestCode}</span>}</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                            <div className='section-header'>
                                                <span>
                                                     Response & Deployment Type
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="responseEvent" className="form-label">Response/Event</label>
                                                <select id="responseEvent" className="form-select" value={selectedResponseEvent} onChange={(e) => handleInputChange(e, setSelectedResponseEvent, 'selectedResponseEvent', 'Response/Event')}>
                                                        <option value="">--Select--</option>
                                                        {responseEvents.map((res) => (
                                                            <option key={res.responseId} value={res.responseId}>{res.responseDescription}</option>
                                                        ))}
                                                    </select>
                                                {errors.selectedResponseEvent && <p style={{color: 'red'}}>{errors.selectedResponseEvent}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="deploymentType" className="form-label">Deployment Type <span style={{color:"red", font:"bold"}}>*</span></label>
                                                    <select id="deploymentType" className="form-select" value={selectedDeploymentType} onChange={(e) => handleInputChange(e, setSelectedDeploymentType, 'selectedDeploymentType', 'Deployment Type')}>
                                                        <option value="">--Select--</option>
                                                        {deploymentTypes.map((dep) => (
                                                            <option key={dep.supportTypeId} value={dep.supportTypeId}>{dep.supportTypeName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.selectedDeploymentType && <p style={{color: 'red'}}>{errors.selectedDeploymentType}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="supportingTeam" className="form-label">Requested Team <span style={{color:"red", font:"bold"}}>*</span></label>
                                                    <select id="supportingTeam" className="form-select" value={selectedSupportingTeam} onChange={(e) => handleInputChange(e, setSelectedSupportingTeam, 'selectedSupportingTeam', 'Requested Team')}>
                                                        <option value="">--Select--</option>
                                                        {filteredSupportingTeams.map((req) => (
                                                            <option key={req.teamId} value={req.teamId}>{req.teamName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.selectedSupportingTeam && <p style={{color: 'red'}}>{errors.selectedSupportingTeam}</p>}
                                            </div>                                            
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-12">
                                                <label htmlFor="ccUsersMail" className="form-label" title="Provide the Cc Emails in comma separated format">Cc Emails</label>
                                                    <input type="text" className="form-control" id="ccUsersMail" placeholder="Provide the Cc Emails in comma separated format" value={ccUsersMail} onChange={(e) => handleInputChange(e, setCcUsersMail, 'ccUsersMail', 'CC Users')}/>
                                                    {errors.ccUsersMail && <p style={{color: 'red'}}>{errors.ccUsersMail}</p>}
                                            </div>
                                        </div>
                                        {isESFM && (<>
                                            {sections.map((section, index) => (
                                                <div key={index} className='responseevents'>
                                                    <div className='section-header'>
                                                        <span>
                                                            Position Specific Information - {section.postionHeader} {errors[index]?.selectedPosition && (errors[index]?.selectedPosition === "This position is duplicated. Please select a different one.") && <span style={{ color: 'orangered', fontWeight: 'bold', fontSize: '1em' }}>{errors[index].selectedPosition}</span>}
                                                        </span>
                                                        <button className="btn btn-link collapse-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsePosition_${index}`} aria-expanded="false" aria-controls={`collapsePosition_${index}`} onClick={() => toggleIcon(index)}>
                                                            <FontAwesomeIcon icon={section.isCollapsed ? faPlus : faMinus} />
                                                        </button>
                                                        {sections.length > 1 && (
                                                            <button type="button" onClick={() => handleDeleteClick(index)}  className="btn btn-danger" style={{background:"none", border:"none", color:"red"}}>
                                                            <i className="bi bi-x-circle" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="delete"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div id={`collapsePosition_${index}`} className={`collapse ${section.isCollapsed ? '' : 'show'}`}>
                                                        <div className='form-outline'>
                                                        <fieldset>
                                                            <h6>Position Information</h6>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`position_${index}`} className='form-label'>
                                                                        Position <span style={{ color: "red", font: "bold" }}>*</span> <span style={{fontStyle:'italic', fontSize:'13px'}}>Not Listed! <a href='/capacitymappings' target='_blank'>Add Position</a></span>
                                                                    </label>
                                                                    <select
                                                                        id={`position_${index}`}
                                                                        className="form-select"
                                                                        value={section.selectedPosition}
                                                                        onChange={(e) => handleChange(index, 'selectedPosition', e.target.value, 'Position')}
                                                                    >
                                                                        <option value={''}>--Select--</option>
                                                                        {allPositions.map((type) => (
                                                                            <option key={type.positionId} value={type.positionId}>
                                                                                {type.positionDescription}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errors[index]?.selectedPosition && <p style={{ color: 'red' }}>{errors[index].selectedPosition}</p>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`dutyStation_${index}`} className='form-label'>
                                                                        Duty Station <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`dutyStation_${index}`}
                                                                        placeholder="Duty Station"
                                                                        value={section.dutyStation}
                                                                        disabled
                                                                    />
                                                                    {errors[index]?.dutyStation && <p style={{ color: 'red' }}>{errors[index].dutyStation}</p>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`positionLevel_${index}`} className='form-label'>
                                                                        Position Level <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        id={`positionLevel_${index}`}
                                                                        className="form-select"
                                                                        value={section.selectedPositionLevel}
                                                                        onChange={(e) => handleChange(index, 'selectedPositionLevel', e.target.value, 'Position Level')}
                                                                    >
                                                                        <option value={''}>--Select--</option>
                                                                        {positionLevels.map((pl) => (
                                                                            <option key={pl.gradeId} value={pl.gradeId}>
                                                                                {pl.gradeName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errors[index]?.selectedPositionLevel && <p style={{ color: 'red' }}>{errors[index].selectedPositionLevel}</p>}
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`dateFrom_${index}`} className='form-label me-2'>
                                                                       Target From Date <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <DatePicker
                                                                        selected={section.fromDate}
                                                                        onChange={(date) => handleChange(index, 'fromDate', date, 'Target From Date')}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="Select from date"
                                                                        className='form-control'
                                                                    />
                                                                    {errors[index]?.fromDate && <p style={{ color: 'red' }}>{errors[index].fromDate}</p>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`toDate_${index}`} className='form-label me-2'>
                                                                    Target To Date <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <DatePicker
                                                                        selected={section.toDate}
                                                                        onChange={(date) => handleChange(index, 'toDate', date, 'Target To Date')}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="Select To date"
                                                                        className='form-control'
                                                                    />
                                                                    {errors[index]?.toDate && <p style={{ color: 'red' }}>{errors[index].toDate}</p>}
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <fieldset>
                                                            <h6>Supervisor Information</h6>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`supervisorName_${index}`} className='form-label'>
                                                                        Name <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`supervisorName_${index}`}
                                                                        placeholder="Full Name"
                                                                        value={section.supervisorName}
                                                                        onChange={(e) => handleChange(index, 'supervisorName', e.target.value, 'Supervisor Name')}
                                                                    />
                                                                    {errors[index]?.supervisorName && <p style={{ color: 'red' }}>{errors[index].supervisorName}</p>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`supervisorPosition_${index}`} className='form-label'>
                                                                        Position Title <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`supervisorPosition_${index}`}
                                                                        placeholder="Position Title"
                                                                        value={section.supervisorPosition}
                                                                        onChange={(e) => handleChange(index, 'supervisorPosition', e.target.value, 'Position Title')}
                                                                    />
                                                                    {errors[index]?.supervisorPosition && <p style={{ color: 'red' }}>{errors[index].supervisorPosition}</p>}
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`supervisorEmail_${index}`} className='form-label'>
                                                                        Email <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id={`supervisorEmail_${index}`}
                                                                        placeholder="Email Account"
                                                                        value={section.supervisorEmail}
                                                                        onChange={(e) => handleChange(index, 'supervisorEmail', e.target.value, 'Supervisor Email')}
                                                                    />
                                                                    {errors[index]?.supervisorEmail && <p style={{ color: 'red' }}>{errors[index].supervisorEmail}</p>}
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <fieldset>
                                                        <h6>Terms of Reference, provide anyone from both either ToR or ToR Document <span style={{ color: "red", font: "bold" }}>*</span></h6> 
                                                            <hr />
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label htmlFor={`termsofReference_${index}`} className='form-label'>
                                                                        ToR
                                                                    </label>
                                                                    <textarea
                                                                        className='form-control mb-3 col-md-12'
                                                                        id={`termsofReference_${index}`}
                                                                        value={section.termsofReference}
                                                                        onChange={(e) => handleChange(index, 'termsofReference', e.target.value, 'ToR')}
                                                                        rows="4"
                                                                        cols="50"
                                                                        placeholder="Enter your justification here..."
                                                                    />                                                                    
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label htmlFor={`torDocuments_${index}`} className='form-label'>
                                                                        ToR Documents
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id={`torDocuments_${index}`}
                                                                        onChange={(e) => handleChange(index, 'torDocuments', e.target.files[0], 'ToR Documents')}
                                                                    />
                                                                </div>
                                                                {section.torDocumentPath && section.uploadedDocumentName && section.torDocumentName && ( <div className="form-group mb-3 col-md-8">
                                                                    <label htmlFor={`torDocumentPath_${index}`} className='form-label'>
                                                                        Uploaded Documents
                                                                    </label>
                                                                    <a style={{textDecoration:'auto', fontWeight:'bold'}} className='form-control'>
                                                                        {section.uploadedDocumentName}
                                                                    </a>
                                                                    <button className='btn btn-sm me-4' style={{float:'right', marginTop: '-35px'}} onClick={() => handleDownloadDocument(index)}><i class="bi bi-download" style={{color:'#0000ff'}}></i></button>
                                                                    <button className='btn btn-sm' style={{float:'right', marginTop: '-35px'}} onClick={() => handleDeleteDocument(index)}><i class="bi bi-trash" style={{color:'red'}}></i></button>
                                                                </div>)}
                                                                {errors[index]?.termsofReference && <p style={{ color: 'red' }}>{errors[index].termsofReference}</p>}
                                                            </div>
                                                        </fieldset>
                                                        <fieldset>
                                                            <h6>Request Justification</h6>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label htmlFor={`requestJustification_${index}`} className='form-label'>
                                                                        <strong>Please explain why this surge deployment is urgent, why current IOM Mission capacity can not meet this need and what are the long term plans of the mission (beyond surge deployment period)?</strong> <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <textarea
                                                                        className='form-control mb-3 col-md-12'
                                                                        id={`requestJustification_${index}`}
                                                                        value={section.requestJustification}
                                                                        onChange={(e) => handleChange(index, 'requestJustification', e.target.value, 'Request Justification')}
                                                                        rows="4"
                                                                        cols="50"
                                                                    ></textarea>
                                                                    {errors[index]?.requestJustification && <p style={{ color: 'red' }}>{errors[index].requestJustification}</p>}
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <fieldset>
                                                            <h6>Requested Responsibilities</h6>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='form-group mb-3 col-md-12'>
                                                                    <label htmlFor={`requestedResponsibilities_${index}`} className='form-label'>
                                                                        Area of Responsibility Requested <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <div className="checkbox-list">
                                                                        {allResponsibilities.map((responsibility) => (
                                                                            <div className="checkbox-item" key={responsibility.thematicAreaId}>
                                                                                <input
                                                                                    className="me-2 md-2"
                                                                                    type="checkbox"
                                                                                    id={`responsibility_${responsibility.thematicAreaId}`}
                                                                                    value={responsibility.thematicAreaId}
                                                                                    checked={sections[index]?.requestedResponsibilities.includes(responsibility.thematicAreaId)}
                                                                                    onChange={(e) => handleMultiSelectCheckboxChange(e, index)}
                                                                                />
                                                                                <label htmlFor={`responsibility_${responsibility.thematicAreaId}`}>{responsibility.thematicAreaDescription}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    {errors[index]?.requestedResponsibilities && <p style={{ color: 'red' }}>{errors[index].requestedResponsibilities}</p>}
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <fieldset>
                                                            <h6>Concent</h6>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-8">
                                                                    <label htmlFor={`supportIdentifyProfile_${index}`} className='form-label'>
                                                                        Do you need support with identifying surge deployment profile? <span style={{ color: "red", font: "bold" }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        id={`supportIdentifyProfile_${index}`}
                                                                        className="form-select"
                                                                        value={section.supportIdentifyProfile}
                                                                        onChange={(e) => handleChange(index, 'supportIdentifyProfile', e.target.value, 'Support Identify Profile')}
                                                                    >
                                                                        <option value={''}>--Select--</option>
                                                                        <option value={'Yes'}>Yes</option>
                                                                        <option value={'No'}>No</option>
                                                                    </select>
                                                                    {errors[index]?.supportIdentifyProfile && <p style={{ color: 'red' }}>{errors[index].supportIdentifyProfile}</p>}
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        </div>
                                                    </div>                                                                                                    
                                                </div>                                                
                                            ))}
                                            <div style={{textAlign: "right"}}>
                                                <button type="button" onClick={addSection} className="btn btn-primary mb-2 me-2">
                                                Add New Position
                                                </button>                                                    
                                            </div>  
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>
                                                    Estimated Cost For Request
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label htmlFor="esfmEstimatedCost" className="form-label">Cost <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id='esfmEstimatedCost' placeholder="Cost" value={esfmEstimatedCost} onChange={(e) => handleInputChange(e, setEsfmEstimatedCost, 'esfmEstimatedCost', 'Cost')}/>
                                                {errors.esfmEstimatedCost && <p style={{color: 'red'}}>{errors.esfmEstimatedCost}</p>}
                                            </div> 
                                            {(requestStatus === 'Rejected' || requestStatus === 'Reverted') && (
                                               <>
                                                    <div className='responseevents'>
                                                        <div className='section-header'>
                                                            <span>
                                                            Remark for Re Submit Request
                                                            </span>
                                                        </div>
                                                    </div>                                                    
                                                    <div className='row'>
                                                        <div className="form-group mb-3 col-md-12">
                                                            <label htmlFor='resubmitRemarks' className='form-label'>Remark <span style={{color:"red", font:"bold"}}>*</span></label>
                                                            <textarea
                                                            className="form-control"
                                                            rows="3"
                                                            value={resubmitRemarks}
                                                            onChange={(e) => handleInputChange(e, setResubmitRemarks, 'resubmitRemarks', 'Remarks')}
                                                            placeholder="Enter your remarks here..."
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </>    
                                            )}                                            
                                        </>)}                                                                     
                                        <div className='row' style={{float:'right'}}>
                                            <div className="form-group">
                                                {(userInfo.isAdmin || userHasDraftRequestRole) && draftButtonVisibilityStatus.includes(requestStatus) && (
                                                    <button type="submit" id="btnSaveDraft" className="btn btn-primary me-2 mb-2" onClick={handleSaveDraft}>Save Draft</button> 
                                                )}
                                                {(userInfo.isAdmin || userHasSubmitRequestRole) && (
                                                    <button type="submit" id="btnSubmit" className="btn btn-primary me-2 mb-2" onClick={handleSubmitForm}>{resubmitButtonVisibilityStatus.includes(requestStatus) ? 'Re Submit' : 'Submit'}</button>
                                                )} 
                                                {(deploymentRequestId === 0 ) && (
                                                <>
                                                    <button type="button" className="btn btn-danger mb-2" onClick={resetControls}>Cancel</button>
                                                </>
                                                )}                                                  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header>
                                    <Modal.Title className="text-center w-100">Confirm Deletion</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                {deleteType === 'section' && <p>Are you sure you want to delete this section?</p>}
                                {deleteType === 'document' && <p>Are you sure you want to delete this document?</p>}
                                </Modal.Body>
                                <Modal.Footer>
                                <button type='button' className="btn btn-secondary me-2"  onClick={handleClose}>
                                    Cancel
                                </button>
                                    <button type='button' className="btn btn-danger" onClick={handleConfirmDelete}>
                                        Delete
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showDropdownChange} onHide={handleCloseDropdown} centered>
                                <Modal.Header>
                                    <Modal.Title className="text-center w-100">Confirm Change</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <p>Changing the request type will result in the loss of any unsaved data in the form. Do you want to proceed?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-secondary me-2"  onClick={handleCloseDropdown}>
                                        Cancel
                                    </button>
                                    <button type='button' className="btn btn-primary" onClick={handleConfirmChange}>
                                        OK
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ESFMDeploymentRequest;
