import React, { useState, useEffect, useCallback  } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import { useModal } from '../modalPopup/modelcontext.js';
import DatePicker from 'react-datepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './deploymentrequest.css';
import { GetAllDeploymentTypes, GetAllPositionLevel, GetAllPositions, GetAllPositionTypes, GetAllRequestedTeams, GetAllResponseEvents, GetAllDeploymentRequests } from '../../services/dropdownServices.js';
import { GetSbpRequestDetailsById, SaveDraftSBPRequest,  SubmitSBPRequest, DownloadUploadedDocument } from '../../services/deploymentrequestServices.js';
import { Modal } from 'react-bootstrap';
import { getToken, userRoles } from '../../services/loginService.js';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import SearchableDropdown from '../login_signup/searchableDropdown.js'
import { faL } from '@fortawesome/free-solid-svg-icons';

const SBPRequest = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();
    const [hasFetched, setHasFetched] = useState(false);
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
    const [selectedDeploymentType, setSelectedDeploymentType] = useState(null);
    const [tempDeploymentType, setTempDeploymentType] = useState(null);
    const [requestCode, setRequestCode] = useState('');
    const [requestStatus, setRequestStatus] = useState(null);
    const [resubmitRemarks, setResubmitRemarks] = useState('');
    const [deploymentRequestId, setDeploymentRequestId] = useState(0);
    const [deploymentRequestPositionId, setDeploymentRequestPositionId] = useState(0);
    const [selectedResponseEvent, setSelectedResponseEvent] = useState('');
    const [ccUsersMail, setCcUsersMail] = useState('');
    const [responseEvents, setResponseEvents] = useState([]);
    const [deploymentTypes, setDeploymentTypes] = useState([]);
    const [selectedSupportingTeam, setSelectedSupportingTeam] = useState('');
    const [selectedExistingRequestId, setSelectedExistingRequestId] = useState('');
    const [supportingTeams, setSupportingTeams] = useState([]);
    const [filteredSupportingTeams, setFilteredSupportingTeams] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [positionLevels, setPositionLevels] = useState([]);
    const [positionTypes, setPositionTypes] = useState([]);
    const [allRequests, setAllRequests] = useState([]);
    const { showModal } = useModal();
    const [show, setShow] = useState(false);
    const [showDropdownChange, setShowDropdownChange] = useState(false);
    const [isSBP, setIsSBP] = useState(true);
    const [postionHeader, setPostionHeader] = useState('');
    const [selectedPosition, setSelectedPosition] = useState('');
    const [dutyStation, setDutyStation] = useState('');
    const [selectedPositionLevel, setSelectedPositionLevel] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [torDocumentPath, setTorDocumentPath] = useState('');
    const [uploadedDocumentName, setUploadedDocumentName] = useState('');
    const [isDocumentDeleted, setIsDocumentDeleted] = useState('');
    const [deletedDocumentName, setDeletedDocumentName] = useState('');
    const [supervisorName, setSupervisorName] = useState('');
    const [supervisorPosition, setSupervisorPosition] = useState('');
    const [supervisorEmail, setSupervisorEmail] = useState('');
    const [practicalArrangementSupervisorName, setPracticalArrangementSupervisorName] = useState('');
    const [practicalArrangementSupervisorPosition, setPracticalArrangementSupervisorPosition] = useState('');
    const [practicalArrangementSupervisorEmail, setPracticalArrangementSupervisorEmail] = useState('');
    const [performanceEvaluationSupervisorName, setPerformanceEvaluationSupervisorName] = useState('');
    const [performanceEvaluationSupervisorPosition, setPerformanceEvaluationSupervisorPosition] = useState('');
    const [performanceEvaluationSupervisorEmail, setPerformanceEvaluationSupervisorEmail] = useState('');
    const [torDocuments, setTorDocuments] = useState(null);
    const [urgentRequestJustification, setUrgentRequestJustification] = useState('');
    const [criticalRequestJustification, setCriticalRequestJustification] = useState('');
    const [longerTermRequestJustification, setLongerTermRequestJustification] = useState('');
    const [isRequestExtension, setIsRequestExtension] = useState(false);
    const [isOfficeSpaceProvided, setIsOfficeSpaceProvided] = useState(null);
    const [unSecurityPhaseDutyStation, setUnSecurityPhaseDutyStation] = useState('');
    const [specialMedicalRequirements, setSpecialMedicalRequirements] = useState('');
    const [visaRequirements, setVisaRequirements] = useState('');
    const [rrCycle, setRrCycle] = useState('');
    const [errors, setErrors] = useState({});
    const [draftButtonDisabled, setDraftButtonDisabled] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [proofOfApproval, setProofOfApproval] = useState(null);
    const [proofOfApprovalDocumentPath, setProofOfApprovalDocumentPath] = useState('');
    const [uploadedProofOfApprovalDocumentName, setUploadedProofOfApprovalDocumentName] = useState('');
    const [isProofOfApprovalDocumentDeleted, setisProofOfApprovalDocumentDeleted] = useState('');
    const [deletedProofOfApprovalDocumentName, setDeletedProofOfApprovalDocumentName] = useState('');
    const [deletedDocumentType, setDeletedDocumentType] = useState('');
    const [copyPracticalArrangements, setCopyPracticalArrangements] = useState(false);
    const [copyPerformanceEvaluation, setCopyPerformanceEvaluation] = useState(false);
    const [hasAnyError, setHasAnyError] = useState(false);

    const fetchResponseEvents = async() => {
        try{
            GetAllResponseEvents().then((response) =>{
                if(response.status === 200){
                    setResponseEvents(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching response event dropdownlist details:', error);
        }
    };

    const fetchDeploymentTypes = async() => {
        try{
            GetAllDeploymentTypes().then((response) =>{
                if(response.status === 200){
                    setDeploymentTypes(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching Deployment types dropdownlist details:', error);
        }
    };

    const fetchRequestedTeams = async() => {
        try{
            GetAllRequestedTeams().then((response) =>{
                if(response.status === 200){
                    setSupportingTeams(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching requested teams dropdownlist details:', error);
        }
    };

    const fetchPositions = useCallback(async() => {
        try{
            GetAllPositions(userInfo.isAdmin).then((response) =>{
                if(response.status === 200){
                    setAllPositions(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positions dropdownlist details:', error);
        }
    }, [userInfo.isAdmin]);

    const fetchPositionLevel = async() => {
        try{
            GetAllPositionLevel().then((response) =>{
                if(response.status === 200){
                    setPositionLevels(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positions Level dropdownlist details:', error);
        }
    };

    const fetchPositionTypes = async() => {
        try{
            GetAllPositionTypes().then((response) =>{
                if(response.status === 200){
                    setPositionTypes(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching positions Level dropdownlist details:', error);
        }
    };

    const fetchAllTeams = async () => {
        try {
            const response = await GetAllRequestedTeams(); 
            if (response.status === 200) {
                return response.data;
            } 
        } catch (error) {
            console.error('Error while fetching requests details:', error);
            return [];
        }
    };

    const fetchAllRequests = async () =>{
        try {
            const response = await GetAllDeploymentRequests(); 
            if (response.status === 200) {
                setAllRequests(response.data);
            } 
        } catch (error) {
            console.error('Error while fetching requests details:', error);
            return [];
        }
    };

    useEffect(() => { 
        fetchResponseEvents();
        fetchDeploymentTypes();
        fetchRequestedTeams();
        fetchPositions();
        fetchPositionLevel();
        fetchPositionTypes();
        fetchAllRequests();
        if (id !== undefined && !hasFetched) {
            fetchDeploymentRequestData(parseInt(id));
            setHasFetched(true);
        }
        if(id===undefined){
            resetControls();
            setIsSBP(false);
            setRequestCode('');
        }
        const handleFocus = () => {
            fetchPositions(); 
        };
        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [fetchPositions, id, hasFetched]); 

    // Handle query string logic after initial data fetch
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const deploymentType = queryParams.get('deploymentType');
        const responseEventType = queryParams.get('responseeventtype');
    
        if (responseEventType || deploymentType) {
            if (responseEventType) {
                setSelectedResponseEvent(parseInt(responseEventType));
            }
            if (deploymentType) {
                setSelectedDeploymentType(parseInt(deploymentType));
                setIsSBP(true);
                if (supportingTeams && supportingTeams.length > 0) {
                    const newTeams = supportingTeams.filter(pos => pos.supportTypeId === parseInt(deploymentType));
                    setFilteredSupportingTeams(newTeams);
                    setSelectedSupportingTeam(supportingTeams.find(team=> team.supportTypeId === parseInt(deploymentType) && team.teamName === 'SBP HQ Team').teamId);                 
                }
            }
        }
    }, [location.search, supportingTeams]);

    const fetchRequestDetailsById = async (id) => {
        const response = await GetSbpRequestDetailsById(id); 
        if (response.status === 200) {
            return response.data;
        }
    };

    const fetchDeploymentRequestData = async (id) => {
        try {
              const allTeams = await fetchAllTeams();
                const data = await fetchRequestDetailsById(id);
                const updatedRequestedTeams = allTeams.filter(pos => pos.supportTypeId === parseInt(data.deploymentTypeId));
                setFilteredSupportingTeams(updatedRequestedTeams);
                setDeploymentRequestId(data.deploymentRequestId);
                setDeploymentRequestPositionId(data.deploymentRequestPositionId);
                setRequestCode(data.requestCode);
                setRequestStatus(data.status);
                setSelectedDeploymentType(parseInt(data.deploymentTypeId));
                setSelectedSupportingTeam(parseInt(data.requestedTeamId));
                setSelectedResponseEvent(data.responseId);
                setCcUsersMail(data.ccUserMailIds);
                setIsRequestExtension(data.isExtensionRequest);
                setSelectedExistingRequestId(data.existingRequestId);
                setSelectedPosition(data.capacityMappingId);
                setDutyStation(data.dutyStation);
                setSelectedPositionLevel(data.groupId);
                setFromDate(data.targetFromDate);
                setToDate(data.targetToDate);
                setUploadedDocumentName(data.uploadedDocumentName);
                setTorDocumentPath(data.torDocumentPath);
                setUrgentRequestJustification(data.urgentRequestJustification);
                setCriticalRequestJustification(data.criticalRequestJustification);
                setLongerTermRequestJustification(data.longerTermRequestJustification);
                setSupervisorName(data.supervisorName);
                setSupervisorPosition(data.supervisorPositionTitle);
                setSupervisorEmail(data.supervisorEmail);
                setPracticalArrangementSupervisorName(data.paSupervisorName);
                setPracticalArrangementSupervisorPosition(data.paSupervisorPositionTitle);
                setPracticalArrangementSupervisorEmail(data.paSupervisorEmail);
                setPerformanceEvaluationSupervisorName(data.peSupervisorName);
                setPerformanceEvaluationSupervisorPosition(data.peSupervisorPositionTitle);
                setPerformanceEvaluationSupervisorEmail(data.peSupervisorEmail);
                setUnSecurityPhaseDutyStation(data.unSecurityPhase);
                setSpecialMedicalRequirements(data.specialMedicalRequirements);
                setVisaRequirements(data.visaRequirement);
                setRrCycle(data.rnRCycle);
                setIsOfficeSpaceProvided(data.isOfficeSpaceProvide);
                setIsSBP(data.deploymentTypeName==='SBP' ? true : false);
                setUploadedProofOfApprovalDocumentName(data.uploadedProofOfApprovalDocumentName);
                setProofOfApprovalDocumentPath(data.proofOfApprovalDocumentPath);
        } catch (error) {
        }
    };

    const handleInputChange = (e, setter, field) => {
        if(field === 'fromDate' || field === 'toDate'){
            setter(e);
        }else if(field === 'torDocuments'){
            setUploadedDocumentName('');
            setter(e.target.files[0]);
        }else if(field === 'proofOfApproval'){
            setUploadedProofOfApprovalDocumentName('');
            setter(e.target.files[0]);
        }else if (field === 'selectedDeploymentType') {
            const { value } = e.target;
            setTempDeploymentType(value);
            if (value === '') {
                setIsSBP(false);
                setSelectedSupportingTeam('');
                setFilteredSupportingTeams([]);
            } else {               
                const newTeams = supportingTeams.filter(pos => pos.supportTypeId === parseInt(value));
                setFilteredSupportingTeams(newTeams);
                const selectedDeploymentTypeText = deploymentTypes.find(item => item.supportTypeId === parseInt(value)).supportTypeName;
                if (selectedDeploymentTypeText === 'SBP') {
                    setIsSBP(true);    
                    setter(value);
                } else {
                    const isFormFilled = selectedPosition || dutyStation || postionHeader || fromDate || toDate || torDocuments || urgentRequestJustification || criticalRequestJustification ||longerTermRequestJustification
                    || unSecurityPhaseDutyStation || specialMedicalRequirements || visaRequirements || rrCycle || supervisorName || supervisorPosition || supervisorEmail || practicalArrangementSupervisorName || practicalArrangementSupervisorPosition
                    || practicalArrangementSupervisorEmail || performanceEvaluationSupervisorName || performanceEvaluationSupervisorPosition || performanceEvaluationSupervisorEmail || proofOfApproval ;
                    if(isFormFilled){
                        setShowDropdownChange(true);
                    }else{
                        const queryParams = new URLSearchParams({
                            deploymentType: value,
                            responseeventtype: selectedResponseEvent,
                        });               
                        navigate(`/esfmdeploymentrequest?${queryParams.toString()}`);
                    }                    
                }
            }
        }else{
            const { value } = e.target;
            setter(value);
            if (field === 'selectedPosition') {
                const selectedPosition = allPositions.find(pos => pos.positionId === parseInt(value));
                if (selectedPosition) {
                    setDutyStation(selectedPosition.officeDescription);
                    setPostionHeader(selectedPosition.positionDescription);
                } else {
                    setDutyStation('');
                    setPostionHeader('');
                }
            }
            if(field === 'selectedExistingRequestId'){
                resetExistingDeploymentChange();
                fetchExistingDeploymentRequestData(parseInt(value));
            }
        }       
    };

    const fetchExistingDeploymentRequestData = async (id) => {
        try {
                const data = await fetchRequestDetailsById(id);
                setSelectedPosition(data.capacityMappingId);
                setDutyStation(data.dutyStation);
                setSelectedPositionLevel(data.groupId);
                setUrgentRequestJustification(data.urgentRequestJustification); 
                setSupervisorName(data.supervisorName);
                setSupervisorPosition(data.supervisorPositionTitle);
                setSupervisorEmail(data.supervisorEmail);
                setPracticalArrangementSupervisorName(data.paSupervisorName);
                setPracticalArrangementSupervisorPosition(data.paSupervisorPositionTitle);
                setPracticalArrangementSupervisorEmail(data.paSupervisorEmail);
                setPerformanceEvaluationSupervisorName(data.peSupervisorName);
                setPerformanceEvaluationSupervisorPosition(data.peSupervisorPositionTitle);
                setPerformanceEvaluationSupervisorEmail(data.peSupervisorEmail);
                setUnSecurityPhaseDutyStation(data.unSecurityPhase);
                setSpecialMedicalRequirements(data.specialMedicalRequirements);
                setVisaRequirements(data.visaRequirement);
                setRrCycle(data.rnRCycle);
                setIsOfficeSpaceProvided(data.isOfficeSpaceProvide);
        } catch (error) {
        }
    };

    const resetExistingDeploymentChange = () =>{
        setSelectedPosition('');
        setDutyStation('');
        setSelectedPositionLevel('');
        setFromDate(null);
        setToDate(null);
        setTorDocuments(null);
        setUrgentRequestJustification('');
        setCriticalRequestJustification('');
        setLongerTermRequestJustification('');
        setUnSecurityPhaseDutyStation('');
        setSpecialMedicalRequirements('');
        setVisaRequirements('');
        setRrCycle('');
        setIsOfficeSpaceProvided(null);
        setSupervisorName('');
        setSupervisorPosition('');
        setSupervisorEmail('');
        setPracticalArrangementSupervisorName('');
        setPracticalArrangementSupervisorPosition('');
        setPracticalArrangementSupervisorEmail('');
        setPerformanceEvaluationSupervisorName('');
        setPerformanceEvaluationSupervisorPosition('');
        setPerformanceEvaluationSupervisorEmail('');
        setProofOfApproval(null);
    }

    const handleCheckBoxChange = (e, setState, checkboxType) => {
        const isChecked = e.target.checked;
        setState(isChecked);
        if(checkboxType === 'isRequestExtension' && !isChecked){
            setSelectedExistingRequestId('');
        }
    };

    const validate = () => {        
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newErrors = {};
        if (!selectedDeploymentType) newErrors.selectedDeploymentType = 'Deployment Type is required';
        if (!selectedSupportingTeam) newErrors.selectedSupportingTeam = 'Requested Team is required';
        if(ccUsersMail){
            const validationError = validateEmails(ccUsersMail);
            if (validationError) {
                newErrors.ccUserMailIds = validationError;
            }
        }
        if(isRequestExtension){
            if(!selectedExistingRequestId) newErrors.selectedExistingRequestId = 'Previous Deployment Request is required';
        }
        if (!selectedPosition) newErrors.selectedPosition = 'Position is required';
        if (!selectedPositionLevel) newErrors.selectedPositionLevel = 'Position Level is required';
        if (!fromDate) newErrors.fromDate = 'From Date is required';
        if (!toDate) newErrors.toDate = 'To Date is required';
        if (fromDate && toDate) {
            if (new Date(toDate) < new Date(fromDate)) {
                newErrors.toDate = 'Target To Date should not be earlier than Target From Date.';
            }
        }
        if(!torDocuments && !uploadedDocumentName && !torDocumentPath) newErrors.torDocuments = 'TOR document required';
        if(!urgentRequestJustification) newErrors.urgentRequestJustification = 'Why the post is urgent and Why current IOM capacity cannot meet this need? request justification is required';
        if(!criticalRequestJustification) newErrors.criticalRequestJustification = 'Critical Deliverables of this post request justification is required';
        if(!longerTermRequestJustification) newErrors.longerTermRequestJustification = 'Longer Term staffing plan after SBP temporary support period request justification is required';
        if(!unSecurityPhaseDutyStation) newErrors.unSecurityPhaseDutyStation = 'UN Security Phase in-country/duty station is required';
        if(!specialMedicalRequirements) newErrors.specialMedicalRequirements = 'Special Medical Requirements is required';
        if(!visaRequirements) newErrors.visaRequirements = 'Visa Requirement is required';
        if(!rrCycle) newErrors.rrCycle = 'R & R cycle at duty station is required';
        if(!supervisorName) newErrors.supervisorName = 'Direct Supervisor of Deployee Name is required';
        if(!supervisorPosition) newErrors.supervisorPosition = 'Direct Supervisor of Deployee Position Title is required';
        if(!supervisorEmail) newErrors.supervisorEmail = 'Direct Supervisor of Deployee Email is required';
        if(supervisorEmail && !emailPattern.test(supervisorEmail)){
            newErrors.supervisorEmail = 'Invalid email format for Direct Supervisor of Deployee';
        }
        if(!practicalArrangementSupervisorName) newErrors.practicalArrangementSupervisorName = 'Practical Arrangements related to deployment Supervisor Name is required';
        if(!practicalArrangementSupervisorPosition) newErrors.practicalArrangementSupervisorPosition = 'Practical Arrangements related to deployment Supervisor Position Title is required';
        if(!practicalArrangementSupervisorEmail) newErrors.practicalArrangementSupervisorEmail = 'Practical Arrangements related to deployment Supervisor Email is required';
        if(practicalArrangementSupervisorEmail && !emailPattern.test(practicalArrangementSupervisorEmail)){
            newErrors.practicalArrangementSupervisorEmail = 'Invalid email format for Practical Arrangements related to deployment Supervisor';
        }
        if(!performanceEvaluationSupervisorName) newErrors.performanceEvaluationSupervisorName = 'Performance Evaluation Supervisor Name is required';
        if(!performanceEvaluationSupervisorPosition) newErrors.performanceEvaluationSupervisorPosition = 'Performance Evaluation Supervisor Position Title is required';
        if(!performanceEvaluationSupervisorEmail) newErrors.performanceEvaluationSupervisorEmail = 'Performance Evaluation Supervisor Email is required';
        if(performanceEvaluationSupervisorEmail && !emailPattern.test(performanceEvaluationSupervisorEmail)){
            newErrors.performanceEvaluationSupervisorEmail = 'Invalid email format for Performance Evaluation Supervisor';
        }
        if(!proofOfApproval && !uploadedProofOfApprovalDocumentName && !proofOfApprovalDocumentPath) newErrors.proofOfApproval = 'Proof of Approval document required';
        if((requestStatus === 'Rejected' || requestStatus === 'Reverted') && !resubmitRemarks) newErrors.resubmitRemarks = 'Remark for Re Submit Request is required';
        if (Object.keys(newErrors).length > 0) {
            setHasAnyError(true);
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    const validateEmails = (emails) => {
        const emailArray = emails.split(",").map((email) => email.trim());
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        for (let email of emailArray) {
          if (!emailRegex.test(email)) {
            return `Invalid Cc User emailId: ${email}`;
          }
        }
        return null;
    };

    const handleSaveDraft = async () => {
        try {
            if(isSBP){
                setDraftButtonDisabled(true);
                const formData = createFormDataRequest();
                SaveDraftSBPRequest(formData).then((res) => {
                    if (res.data.success) {
                        showModal('success', res.data.message);
                        setDeploymentRequestId(res.data.deploymentRequestId);
                        setRequestCode(res.data.deploymentRequestCode);
                    } else {
                        showModal('failure', res.data.message);
                    }
                    setDraftButtonDisabled(false);
                });
            }            
        }
        catch(error) {
            showModal('failure', 'Save Draft Request failed with error ' + error);
            setDraftButtonDisabled(false);
        }
    };

    const handleSubmitForm = async () => {
        try {
            if(isSBP){
                setSubmitButtonDisabled(true);
                if (!validate()) {
                    setSubmitButtonDisabled(false);
                    return;
                }
                const formData = createFormDataRequest();
                SubmitSBPRequest(formData).then((res) => {
                    if (res.data.success) {
                        showModal('success', res.data.message);
                        navigate('/deploymentRequestsList');
                    } else {
                        showModal('failure', res.data.message);
                    }
                    setSubmitButtonDisabled(false);
                });
            }
        }catch(error) {
            showModal('failure', 'Submit Request failed with error ' + error);
            setSubmitButtonDisabled(false);
        }
    };

    function createFormDataRequest() {
        const selectedDeploymentTypeText = deploymentTypes.find(item => item.supportTypeId === parseInt(selectedDeploymentType))?.supportTypeName || '';
        const selectedTeamNameText = filteredSupportingTeams.find(item => item.teamId === parseInt(selectedSupportingTeam))?.teamName || '';
        const positionType = positionTypes.find(item => item.opportunityTypeName === 'SBP')?.opportunityTypeId;
        const formData = new FormData();
        formData.append('deploymentRequestId', deploymentRequestId);
        formData.append('deploymentRequestPositionId', deploymentRequestPositionId);
        formData.append('responseId', parseInt(selectedResponseEvent, 10));
        formData.append('deploymentTypeId', parseInt(selectedDeploymentType, 10));
        formData.append('deploymentTypeText', selectedDeploymentTypeText);
        formData.append('requestedTeamId', parseInt(selectedSupportingTeam, 10));
        formData.append('requestedTeamName',selectedTeamNameText);
        formData.append('resubmitRemarks',resubmitRemarks);        
        formData.append('ccUserMailIds', ccUsersMail);
        formData.append('isRequestExtension',isRequestExtension ?? false); 
        formData.append('existingRequestId', parseInt(selectedExistingRequestId ?? 0)); 
        formData.append('capacityMappingId', parseInt(selectedPosition ?? 0));
        formData.append('postionHeader', postionHeader);
        formData.append('dutyStation', dutyStation);
        formData.append('groupId', parseInt(selectedPositionLevel ?? 0));
        formData.append('targetFromDate', fromDate ? new Date(fromDate).toISOString() : null);
        formData.append('targetToDate', toDate ? new Date(toDate).toISOString() : null);
        formData.append('opportunityTypeId', parseInt(positionType ?? 0));
        formData.append('supervisorName', supervisorName ?? '');
        formData.append('supervisorPositionTitle', supervisorPosition ?? '');
        formData.append('supervisorEmail', supervisorEmail ?? '');
        formData.append('practicalArrangementSupervisorName', practicalArrangementSupervisorName ?? '');
        formData.append('practicalArrangementSupervisorPosition', practicalArrangementSupervisorPosition ?? '');
        formData.append('practicalArrangementSupervisorEmail', practicalArrangementSupervisorEmail ?? '');
        formData.append('performanceEvaluationSupervisorName', performanceEvaluationSupervisorName ?? '');
        formData.append('performanceEvaluationSupervisorPosition', performanceEvaluationSupervisorPosition ?? '');
        formData.append('performanceEvaluationSupervisorEmail', performanceEvaluationSupervisorEmail ?? ''); 
        formData.append('urgentRequestJustification', urgentRequestJustification ?? '');
        formData.append('criticalRequestJustification', criticalRequestJustification ?? '');
        formData.append('longerTermRequestJustification', longerTermRequestJustification ?? '');
        formData.append('unSecurityPhaseDutyStation', unSecurityPhaseDutyStation ?? '');
        formData.append('specialMedicalRequirements', specialMedicalRequirements ?? '');
        formData.append('visaRequirements', visaRequirements ?? '');
        formData.append('rrCycle',rrCycle ?? '');
        formData.append('isOfficeSpaceProvided',isOfficeSpaceProvided ?? false);   
        if (torDocuments) {
            formData.append('torDocument', torDocuments);
        }
        if (isDocumentDeleted) {
            formData.append('documentDeleted', true);
            formData.append('deletedDocumentName', deletedDocumentName ?? '');
        }else{
            formData.append('documentDeleted', false);
            formData.append('deletedDocumentName', '');
        } 
        if (proofOfApproval) {
            formData.append('proofOfApproval', proofOfApproval);
        }
        if (isProofOfApprovalDocumentDeleted) {
            formData.append('proofOfApprovalDocumentDeleted', true);
            formData.append('deletedProofOfApprovalDocumentName', deletedProofOfApprovalDocumentName ?? '');
        }else{
            formData.append('proofOfApprovalDocumentDeleted', false);
            formData.append('deletedProofOfApprovalDocumentName', '');
        }     
        formData.append('uploadedDocumentName', uploadedDocumentName); 
        formData.append('uploadedProofOfApprovalDocumentName', uploadedProofOfApprovalDocumentName); 
        return formData;
    }
     
    const resetControls = () => {
        setSelectedResponseEvent('');
        setSelectedDeploymentType('');
        setSelectedSupportingTeam('');
        setIsSBP(false);
        setRequestCode('');
        setCcUsersMail('');
        setIsRequestExtension(null);
        setSelectedExistingRequestId('');
        setSelectedPosition('');
        setDutyStation('');
        setSelectedPositionLevel('');
        setFromDate(null);
        setToDate(null);
        setTorDocuments(null);
        setUrgentRequestJustification('');
        setCriticalRequestJustification('');
        setLongerTermRequestJustification('');
        setUnSecurityPhaseDutyStation('');
        setSpecialMedicalRequirements('');
        setVisaRequirements('');
        setRrCycle('');
        setIsOfficeSpaceProvided(null);
        setSupervisorName('');
        setSupervisorPosition('');
        setSupervisorEmail('');
        setPracticalArrangementSupervisorName('');
        setPracticalArrangementSupervisorPosition('');
        setPracticalArrangementSupervisorEmail('');
        setPerformanceEvaluationSupervisorName('');
        setPerformanceEvaluationSupervisorPosition('');
        setPerformanceEvaluationSupervisorEmail('');
        setProofOfApproval(null);
    };

    const handleDownloadDocument = async (documentName, documentPath) => {
        if (!documentName || !documentPath) {
            showModal('failure', "Document details are missing.");
            return;
        }
        try {
            const response = await DownloadUploadedDocument(documentName, documentPath);
            if (response.success !== false) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const contentDisposition = response.headers['content-disposition'];
                let fileName = documentName; 
                if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
                    fileName = contentDisposition.split('filename=')[1].replace(/"/g, ''); 
                }
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
                } else {
                    showModal('failure', response.message || "Failed to download the document.");
                }
        } catch (error) {
            showModal('failure', "An unexpected error occurred while downloading the file.");
        }
    };

    const handleClose = () =>{
        setShow(false);
    } 
    
    const handleConfirmDelete = () => {
        if(deletedDocumentType === 'torDocuments'){
            setIsDocumentDeleted(true);
            setDeletedDocumentName(uploadedDocumentName);
            setTorDocumentPath('');
            setUploadedDocumentName('');
        }
        if(deletedDocumentType === 'proofOfApproval'){
            setisProofOfApprovalDocumentDeleted(true);
            setDeletedProofOfApprovalDocumentName(uploadedProofOfApprovalDocumentName);
            setProofOfApprovalDocumentPath('');
            setUploadedProofOfApprovalDocumentName('');
        }                    
        handleClose();
    };

    const handleDeleteDocument = (documentType) => {
        setShow(true);
        setDeletedDocumentType(documentType);
    };

    const handleCloseDropdown = () =>{
        setShowDropdownChange(false);
    };

    const handleConfirmChange = () =>{
        setIsSBP(false);
        resetControls();
        const queryParams = new URLSearchParams({
            deploymentType: tempDeploymentType,
            responseeventtype: selectedResponseEvent,
        });  
        setShowDropdownChange(false);              
        navigate(`/esfmdeploymentrequest?${queryParams.toString()}`);
    };

    const userHasDraftRequestRole = hasAccess([process.env.REACT_APP_ROLENAME_DRAFTREQUEST]);
    const userHasSubmitRequestRole = hasAccess([process.env.REACT_APP_ROLENAME_SUBMITREQUEST]);
    const draftButtonVisibilityStatus = [null,'Draft'];
    const resubmitButtonVisibilityStatus = ['Rejected','Reverted'];
    
    const handleCopyDetails = (e, section) => {
        if (e.target.checked) {
            if (section === 'practical') {
                setCopyPracticalArrangements(true);
                setPracticalArrangementSupervisorName(supervisorName);
                setPracticalArrangementSupervisorPosition(supervisorPosition);
                setPracticalArrangementSupervisorEmail(supervisorEmail);
            } else if (section === 'performance') {
                setCopyPerformanceEvaluation(true);
                setPerformanceEvaluationSupervisorName(supervisorName);
                setPerformanceEvaluationSupervisorPosition(supervisorPosition);
                setPerformanceEvaluationSupervisorEmail(supervisorEmail);
            }
        } else {
            if (section === 'practical') {
                setCopyPracticalArrangements(false);
                setPracticalArrangementSupervisorName('');
                setPracticalArrangementSupervisorPosition('');
                setPracticalArrangementSupervisorEmail('');
            } else if (section === 'performance') {
                setCopyPerformanceEvaluation(false);
                setPerformanceEvaluationSupervisorName('');
                setPerformanceEvaluationSupervisorPosition('');
                setPerformanceEvaluationSupervisorEmail('');
            }
        }
    };    
    
    const handleSupervisorKeyUp = (field, value) => {
        if (copyPracticalArrangements) {
            if (field === 'name') setPracticalArrangementSupervisorName(value);
            else if (field === 'position') setPracticalArrangementSupervisorPosition(value);
            else if (field === 'email') setPracticalArrangementSupervisorEmail(value);
        }
    
        if (copyPerformanceEvaluation) {
            if (field === 'name') setPerformanceEvaluationSupervisorName(value);
            else if (field === 'position') setPerformanceEvaluationSupervisorPosition(value);
            else if (field === 'email') setPerformanceEvaluationSupervisorEmail(value);
        }
    };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="card mb-3">
                                    <div className='card-header'>
                                        <h5>New Deployment Request {requestCode && <span>: {requestCode}</span>}</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='responseevents'>
                                            <div className='section-header'>
                                                <span>Response & Deployment Type</span>
                                            </div>
                                            <div className='form-outline'>
                                                <fieldset>
                                                    <div className='row'>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label htmlFor="responseEvent" className="form-label">Response/Event</label>
                                                                <select id="responseEvent" className="form-select" value={selectedResponseEvent} onChange={(e) => handleInputChange(e, setSelectedResponseEvent, 'selectedResponseEvent')}>
                                                                    <option value="">--Select--</option>
                                                                    {responseEvents.map((res) => (
                                                                        <option key={res.responseId} value={res.responseId}>{res.responseDescription}</option>
                                                                    ))}
                                                                </select>
                                                            {errors.selectedResponseEvent && <p style={{color: 'red'}}>{errors.selectedResponseEvent}</p>}
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label htmlFor="deploymentType" className="form-label">Deployment Type <span style={{color:"red", font:"bold"}}>*</span></label>
                                                                <select id="deploymentType" className="form-select" value={selectedDeploymentType} onChange={(e) => handleInputChange(e, setSelectedDeploymentType, 'selectedDeploymentType')}>
                                                                    <option value="">--Select--</option>
                                                                    {deploymentTypes.map((dep) => (
                                                                        <option key={dep.supportTypeId} value={parseInt(dep.supportTypeId)}>{dep.supportTypeName}</option>
                                                                    ))}
                                                                </select>
                                                                {errors.selectedDeploymentType && <p style={{color: 'red'}}>{errors.selectedDeploymentType}</p>}
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label htmlFor="supportingTeam" className="form-label">Requested Team <span style={{color:"red", font:"bold"}}>*</span></label>
                                                                <select id="supportingTeam" className="form-select" value={selectedSupportingTeam} onChange={(e) => handleInputChange(e, setSelectedSupportingTeam, 'selectedSupportingTeam')} disabled>
                                                                    <option value="">--Select--</option>
                                                                    {filteredSupportingTeams.map((req) => (
                                                                        <option key={req.teamId} value={req.teamId}>{req.teamName}</option>
                                                                    ))}
                                                                </select>
                                                                {errors.selectedSupportingTeam && <p style={{color: 'red'}}>{errors.selectedSupportingTeam}</p>}
                                                        </div>                                            
                                                    </div>
                                                    <div className='row'>
                                                        <div className="form-group mb-3 col-md-12">
                                                            <label htmlFor="ccUsersMail" className="form-label" title="Provide the Cc Emails in comma separated format">Cc Emails</label>
                                                                <input type="text" className="form-control" id="ccUsersMail" placeholder="Provide the Cc Emails in comma separated format" value={ccUsersMail} onChange={(e) => handleInputChange(e, setCcUsersMail, 'ccUsersMail')}/>
                                                                {errors.ccUsersMail && <p style={{color: 'red'}}>{errors.ccUsersMail}</p>}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='form-group col-md-6'>
                                                            <input type='checkbox' id="isRequestExtension" className='me-2 mb-2' checked={isRequestExtension} onChange={(e) => handleCheckBoxChange(e, setIsRequestExtension, 'isRequestExtension')}/>
                                                            <label htmlFor="isRequestExtension" className='form-label'>Is it Extension of a previous request? </label>
                                                        </div>
                                                        {isRequestExtension &&(
                                                        <div className='form-group col-md-6'>
                                                            <label htmlFor='existingRequestId' className='form-label'>Previous Deployment Request <span style={{color:"red", font:"bold"}}>*</span></label>
                                                            <SearchableDropdown options={allRequests} selectedValue={selectedExistingRequestId} onChange={(value) => handleInputChange({ target: { value } }, setSelectedExistingRequestId, 'selectedExistingRequestId')} displayKey="requestCode" valueKey="requestId" className="form-select" disabled={false}/>
                                                            {errors.selectedExistingRequestId && <p style={{color: 'red'}}>{errors.selectedExistingRequestId}</p>}
                                                        </div>
                                                        )}                                                        
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        {isSBP && (<>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>Position Specific Information</span>
                                                </div>
                                                <div className='form-outline'>
                                                    <fieldset>
                                                        <h6>Position Information</h6>
                                                        <hr />
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='position' className='form-label'>
                                                                    Position <span style={{ color: "red", font: "bold" }}>*</span> <span style={{fontStyle:'italic', fontSize:'13px'}}>Not Listed! <a href='/capacitymappings' target='_blank'>Add Position</a></span>
                                                                </label>
                                                                <select id='position' className="form-select" value={selectedPosition} onChange={(e) => handleInputChange(e, setSelectedPosition, 'selectedPosition')}>
                                                                    <option value={''}>--Select--</option>
                                                                    {allPositions.map((type) => (
                                                                    <option key={type.positionId} value={type.positionId}>
                                                                        {type.positionDescription}
                                                                    </option>
                                                                    ))}
                                                                </select>
                                                                {errors.selectedPosition && <p style={{ color: 'red' }}>{errors.selectedPosition}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='dutyStation' className='form-label'>
                                                                Duty Station <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='dutyStation' placeholder="Duty Station" value={dutyStation} disabled/>
                                                                {errors.dutyStation && <p style={{ color: 'red' }}>{errors.dutyStation}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='positionLevel' className='form-label'>
                                                                    Position Level <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <select id='positionLevel' className="form-select" value={selectedPositionLevel} onChange={(e) => handleInputChange(e, setSelectedPositionLevel, 'selectedPositionLevel')}>
                                                                    <option value={''}>--Select--</option>
                                                                    {positionLevels.map((pl) => (
                                                                        <option key={pl.gradeId} value={pl.gradeId}>
                                                                            {pl.gradeName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.selectedPositionLevel && <p style={{ color: 'red' }}>{errors.selectedPositionLevel}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='dateFrom' className='form-label me-2'>
                                                                    Target From Date <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <DatePicker selected={fromDate} onChange={(date) => handleInputChange(date, setFromDate, 'fromDate')} dateFormat="dd/MM/yyyy" placeholderText="Select from date" className='form-control'/>
                                                                {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='toDate' className='form-label me-2'>
                                                                Target To Date <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <DatePicker selected={toDate} onChange={(date) => handleInputChange(date, setToDate, 'toDate')} dateFormat="dd/MM/yyyy" placeholderText="Select To date" className='form-control'/>
                                                                {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset> 
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='torDocuments' className='form-label'>
                                                                    Terms of Reference <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="file" className="form-control" id='torDocuments' onChange={(e) => handleInputChange(e, setTorDocuments, 'torDocuments')}/>
                                                            </div>
                                                            {torDocumentPath && uploadedDocumentName && ( 
                                                            <div className="form-group mb-3 col-md-8">
                                                                <label htmlFor='torDocumentPath' className='form-label'>Uploaded Documents</label>
                                                                <a style={{textDecoration:'auto', fontWeight:'bold'}} className='form-control'>{uploadedDocumentName}</a>
                                                                <button className='btn btn-sm me-4' style={{float:'right', marginTop: '-35px'}} onClick={() => handleDownloadDocument(uploadedDocumentName, torDocumentPath)}><i class="bi bi-download" style={{color:'#0000ff'}}></i></button>
                                                                <button className='btn btn-sm' style={{float:'right', marginTop: '-35px'}} onClick={() => handleDeleteDocument('torDocuments')}><i class="bi bi-trash" style={{color:'red'}}></i></button>
                                                            </div>)}
                                                            {errors.torDocuments && <p style={{ color: 'red' }}>{errors.torDocuments}</p>}
                                                        </div>
                                                    </fieldset>
                                                </div>                                                                                                
                                            </div>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>Request Justification</span>
                                                </div>
                                                <div className='form-outline'>
                                                    <fieldset>
                                                        <div className='row'>
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor='urgentRequestJustification' className='form-label'>
                                                                    Please explain why the post is urgent and why current IOM capacity cannot meet this need (or if requesting an extension, why one is needed)? <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <textarea className='form-control mb-3 col-md-12' id='urgentRequestJustification' value={urgentRequestJustification} onChange={(e) => handleInputChange(e, setUrgentRequestJustification, 'urgentRequestJustification')} rows="4" cols="50">
                                                                </textarea>
                                                                {errors.urgentRequestJustification && <p style={{ color: 'red' }}>{errors.urgentRequestJustification}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor='criticalRequestJustification' className='form-label'>
                                                                    What are 3-4 critical deliverables of this post? <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <textarea className='form-control mb-3 col-md-12' id='criticalRequestJustification' value={criticalRequestJustification} onChange={(e) => handleInputChange(e, setCriticalRequestJustification, 'criticalRequestJustification')} rows="4" cols="50">
                                                                </textarea>
                                                                {errors.criticalRequestJustification && <p style={{ color: 'red' }}>{errors.criticalRequestJustification}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor='longerTermRequestJustification' className='form-label'>
                                                                    What is the longer-term staffing plan after SBP temporary support period/handover strategy (where relevant)? <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <textarea className='form-control mb-3 col-md-12' id='longerTermRequestJustification' value={longerTermRequestJustification} onChange={(e) => handleInputChange(e, setLongerTermRequestJustification, 'longerTermRequestJustification')} rows="4" cols="50">
                                                                </textarea>
                                                                {errors.longerTermRequestJustification && <p style={{ color: 'red' }}>{errors.longerTermRequestJustification}</p>}
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>    
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>Security & Administrative</span>
                                                </div>
                                                <div className='form-outline'>
                                                    <fieldset>
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label htmlFor='unSecurityPhaseDutyStation' className='form-label'>
                                                                    UN Security Phase in-country/duty station <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='unSecurityPhaseDutyStation' placeholder="UN Security Phase" value={unSecurityPhaseDutyStation} onChange={(e) => handleInputChange(e, setUnSecurityPhaseDutyStation, 'unSecurityPhaseDutyStation')} />
                                                                {errors.unSecurityPhaseDutyStation && <p style={{ color: 'red' }}>{errors.unSecurityPhaseDutyStation}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label htmlFor='specialMedicalRequirements' className='form-label'>
                                                                    Special Medical Requirements (vaccinations etc) <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='specialMedicalRequirements' placeholder="Special Medical Requirements" value={specialMedicalRequirements} onChange={(e) => handleInputChange(e, setSpecialMedicalRequirements, 'specialMedicalRequirements')} />
                                                                    {errors.specialMedicalRequirements && <p style={{ color: 'red' }}>{errors.specialMedicalRequirements}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label htmlFor='visaRequirements' className='form-label'>
                                                                    Visa Requirement (e.g., visa on arrival, invitation letter, etc) <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="email" className="form-control" id='visaRequirements' placeholder="Visa Requirement" value={visaRequirements} onChange={(e) => handleInputChange(e, setVisaRequirements, 'visaRequirements')} />
                                                                {errors.visaRequirements && <p style={{ color: 'red' }}>{errors.visaRequirements}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label htmlFor='rrCycle' className='form-label'>
                                                                    R & R cycle at duty station <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="email" className="form-control" id='rrCycle' placeholder="R & R cycle at duty station" value={rrCycle} onChange={(e) => handleInputChange(e, setRrCycle, 'rrCycle')} />
                                                                {errors.rrCycle && <p style={{ color: 'red' }}>{errors.rrCycle}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='form-group mb-3 col-md-6'>
                                                                <input type='checkbox' id="isOfficeSpaceProvided" className='me-2 mb-2' checked={isOfficeSpaceProvided} onChange={(e) => handleCheckBoxChange(e, setIsOfficeSpaceProvided, 'isOfficeSpaceProvided')}/>
                                                                <label htmlFor="isOfficeSpaceProvided" className='form-label'>Will office space and working tools be provided? </label>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>Supervisor & Focal Points Information</span>
                                                </div>
                                                <div className='form-outline'>
                                                    <fieldset>
                                                        <h6>Direct Supervisor of Deployee</h6>
                                                        <hr />
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='supervisorName' className='form-label'>
                                                                    Name <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='supervisorName' placeholder="Full Name" value={supervisorName} onChange={(e) => handleInputChange(e, setSupervisorName, 'supervisorName')} onKeyUp={() => handleSupervisorKeyUp('name', supervisorName)}/>
                                                                {errors.supervisorName && <p style={{ color: 'red' }}>{errors.supervisorName}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='supervisorPosition' className='form-label'>
                                                                    Position Title <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='supervisorPosition' placeholder="Position Title" value={supervisorPosition} onChange={(e) => handleInputChange(e, setSupervisorPosition, 'supervisorPosition')} onKeyUp={() => handleSupervisorKeyUp('position', supervisorPosition)}/>
                                                                    {errors.supervisorPosition && <p style={{ color: 'red' }}>{errors.supervisorPosition}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='supervisorEmail' className='form-label'>
                                                                    Email <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="email" className="form-control" id='supervisorEmail' placeholder="Email Account" value={supervisorEmail} onChange={(e) => handleInputChange(e, setSupervisorEmail, 'supervisorEmail')} onKeyUp={() => handleSupervisorKeyUp('email', supervisorEmail)}/>
                                                                {errors.supervisorEmail && <p style={{ color: 'red' }}>{errors.supervisorEmail}</p>}
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <h6>Focal Point for Practical Arrangements (Security Clearance, Pick-up at airport, Booking of hotel, Briefing, etc) 
                                                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', float:'right',fontWeight:'normal'}}>
                                                            <input type="checkbox" className='me-2' id="copyPracticalArrangements" onChange={(e) => handleCopyDetails(e, 'practical')} />
                                                            Same as Direct Supervisor
                                                        </span>
                                                        </h6>
                                                        <hr />
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='practicalArrangementSupervisorName' className='form-label'>
                                                                    Name <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='practicalArrangementSupervisorName' placeholder="Full Name" value={practicalArrangementSupervisorName} onChange={(e) => handleInputChange(e, setPracticalArrangementSupervisorName, 'practicalArrangementSupervisorName')} disabled = {copyPracticalArrangements} />
                                                                {errors.practicalArrangementSupervisorName && <p style={{ color: 'red' }}>{errors.practicalArrangementSupervisorName}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='practicalArrangementSupervisorPosition' className='form-label'>
                                                                    Position Title <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='practicalArrangementSupervisorPosition' placeholder="Position Title" value={practicalArrangementSupervisorPosition} onChange={(e) => handleInputChange(e, setPracticalArrangementSupervisorPosition, 'practicalArrangementSupervisorPosition')} disabled = {copyPracticalArrangements} />
                                                                {errors.practicalArrangementSupervisorPosition && <p style={{ color: 'red' }}>{errors.practicalArrangementSupervisorPosition}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='practicalArrangementSupervisorEmail' className='form-label'>
                                                                    Email <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="email" className="form-control" id='practicalArrangementSupervisorEmail' placeholder="Email Account" value={practicalArrangementSupervisorEmail} onChange={(e) => handleInputChange(e, setPracticalArrangementSupervisorEmail, 'practicalArrangementSupervisorEmail')} disabled = {copyPracticalArrangements} />
                                                                {errors.practicalArrangementSupervisorEmail && <p style={{ color: 'red' }}>{errors.practicalArrangementSupervisorEmail}</p>}
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <h6>Focal point for the Performance Evaluation
                                                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', float:'right',fontWeight:'normal'}}>
                                                            <input type="checkbox" className='me-2' id="copyPerformanceEvaluation" onChange={(e) => handleCopyDetails(e, 'performance')} />
                                                            Same as Direct Supervisor                                                        </span>
                                                        </h6>
                                                        <hr />
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='performanceEvaluationSupervisorName' className='form-label'>
                                                                    Name <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='performanceEvaluationSupervisorName' placeholder="Full Name" value={performanceEvaluationSupervisorName} onChange={(e) => handleInputChange(e, setPerformanceEvaluationSupervisorName, 'performanceEvaluationSupervisorName')} disabled = {copyPerformanceEvaluation} />
                                                                {errors.performanceEvaluationSupervisorName && <p style={{ color: 'red' }}>{errors.performanceEvaluationSupervisorName}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='performanceEvaluationSupervisorPosition' className='form-label'>
                                                                    Position Title <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="text" className="form-control" id='performanceEvaluationSupervisorPosition' placeholder="Position Title" value={performanceEvaluationSupervisorPosition} onChange={(e) => handleInputChange(e, setPerformanceEvaluationSupervisorPosition, 'performanceEvaluationSupervisorPosition')} disabled = {copyPerformanceEvaluation} />
                                                                {errors.performanceEvaluationSupervisorPosition && <p style={{ color: 'red' }}>{errors.performanceEvaluationSupervisorPosition}</p>}
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='performanceEvaluationSupervisorEmail' className='form-label'>
                                                                    Email <span style={{ color: "red", font: "bold" }}>*</span>
                                                                </label>
                                                                <input type="email" className="form-control" id='performanceEvaluationSupervisorEmail' placeholder="Email Account" value={performanceEvaluationSupervisorEmail} onChange={(e) => handleInputChange(e, setPerformanceEvaluationSupervisorEmail, 'performanceEvaluationSupervisorEmail')} disabled = {copyPerformanceEvaluation} />
                                                                {errors.performanceEvaluationSupervisorEmail && <p style={{ color: 'red' }}>{errors.performanceEvaluationSupervisorEmail}</p>}
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className='responseevents'>
                                                <div className='section-header'>
                                                    <span>Proof of Approval from COM/HOD</span>
                                                </div>
                                                <div className='form-outline'>
                                                    <fieldset> 
                                                        <div className='row'>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label htmlFor='proofOfApproval' className='form-label'>
                                                                    Proof of Approval <span style={{ color: "red", font: "bold" }}>*</span> <span style={{fontSize:'13px'}}>(Signed document, PDF or Email etc.)</span>
                                                                </label>
                                                                <input type="file" className="form-control" id='proofOfApproval' onChange={(e) => handleInputChange(e, setProofOfApproval, 'proofOfApproval')}/>
                                                            </div>
                                                            {proofOfApprovalDocumentPath && uploadedProofOfApprovalDocumentName && ( 
                                                            <div className="form-group mb-3 col-md-8">
                                                                <label htmlFor='torDocumentPath' className='form-label'>Uploaded Documents</label>
                                                                <a style={{textDecoration:'auto', fontWeight:'bold'}} className='form-control'>{uploadedProofOfApprovalDocumentName}</a>
                                                                <button className='btn btn-sm me-4' style={{float:'right', marginTop: '-35px'}} onClick={() => handleDownloadDocument(uploadedProofOfApprovalDocumentName, proofOfApprovalDocumentPath)}><i class="bi bi-download" style={{color:'#0000ff'}}></i></button>
                                                                <button className='btn btn-sm' style={{float:'right', marginTop: '-35px'}} onClick={() => handleDeleteDocument('proofOfApproval')}><i class="bi bi-trash" style={{color:'red'}}></i></button>
                                                            </div>)}
                                                            {errors.proofOfApproval && <p style={{ color: 'red' }}>{errors.proofOfApproval}</p>}
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            {(requestStatus === 'Rejected' || requestStatus === 'Reverted') && (
                                               <>
                                                    <div className='responseevents'>
                                                        <div className='section-header'>
                                                            <span> Remark for Re Submit Request</span>
                                                        </div>
                                                        <div className='form-outline'>
                                                            <fieldset>
                                                            <div className='row'>
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label htmlFor='resubmitRemarks' className='form-label'>Remark <span style={{color:"red", font:"bold"}}>*</span></label>
                                                                    <textarea className="form-control" rows="3" value={resubmitRemarks} onChange={(e) => handleInputChange(e, setResubmitRemarks, 'resubmitRemarks')} placeholder="Enter your remarks here..."></textarea>
                                                                </div>
                                                            </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </>    
                                            )}                                            
                                        </>)}                                                                     
                                        <div className='row'>                                            
                                            <div className='form-group col-md-6'>
                                            {hasAnyError && (
                                                <span style={{ color: "red", font: "bold" }}>Validation Error, Please fill all required fields before submitting.</span>
                                            )}
                                            </div>
                                            <div className="form-group col-md-6" style={{textAlign:'right'}}>
                                                {(userInfo.isAdmin || userHasDraftRequestRole) && draftButtonVisibilityStatus.includes(requestStatus) && (
                                                    <button type="submit" id="btnSaveDraft" className="btn btn-primary me-2 mb-2" disabled={draftButtonDisabled} onClick={handleSaveDraft}>Save Draft</button> 
                                                )}
                                                {(userInfo.isAdmin || userHasSubmitRequestRole) && (
                                                    <button type="submit" id="btnSubmit" className="btn btn-primary me-2 mb-2" disabled={submitButtonDisabled} onClick={handleSubmitForm}>{resubmitButtonVisibilityStatus.includes(requestStatus) ? 'Re Submit' : 'Submit'}</button>
                                                )} 
                                                {(deploymentRequestId === 0 ) && (
                                                    <button type="button" className="btn btn-danger mb-2" onClick={resetControls}>Cancel</button>
                                                )}                                                  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header>
                                    <Modal.Title className="text-center w-100">Confirm Deletion</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <p>Are you sure you want to delete this document?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-secondary me-2"  onClick={handleClose}>
                                    Cancel
                                    </button>
                                    <button type='button' className="btn btn-danger" onClick={handleConfirmDelete}>
                                        Delete
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showDropdownChange} onHide={handleCloseDropdown} centered>
                                <Modal.Header>
                                    <Modal.Title className="text-center w-100">Confirm Change</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modal-body-scrollable">
                                    <p>Changing the request type will result in the loss of any unsaved data in the form. Do you want to proceed?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className="btn btn-secondary me-2"  onClick={handleCloseDropdown}>
                                    Cancel
                                    </button>
                                    <button type='button' className="btn btn-primary" onClick={handleConfirmChange}>
                                        OK
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SBPRequest;
